<div class="form-group">
  <label for="{{ fieldName }}" class="fw-bold form-label">{{
    field.name
  }}</label>
  <input
    type="number"
    class="form-control"
    title="{{ field.name }}"
    name="{{ fieldName }}"
    [(ngModel)]="field.value"
    (change)="onValueChange()"
    id="{{ fieldName }}"
    step="any"
  />
  <small *ngIf="field.help_text" class="form-text text-muted"
    ><i class="fa fa-info-circle" aria-hidden="true"></i>
    {{ field.help_text }}</small
  >
</div>
