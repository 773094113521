<div class="d-flex gap-2 align-items-center">
  <fa-icon
    class="li-icon flex-grow-0"
    [ngClass]="{ green: document.url }"
    [icon]="document.url ? 'circle-check' : 'file-circle-question'"
  ></fa-icon>
  <fa-icon
    *ngIf="document.name === null"
    class="li-icon flex-grow-0"
    [icon]="getFileIcon(document.original_filename)"
  ></fa-icon>
  {{ document.name || document.original_filename }}
  <small class="text-muted">
    {{ document.is_required ? " (Required)" : "" }}
  </small>
</div>
<div class="actions mr-1">
  <fa-icon
    *ngIf="!document.url"
    (click)="upload.emit(document)"
    container="body"
    ngbTooltip="Upload document"
    class="li-icon action primary"
    icon="upload"
  ></fa-icon>
  <fa-icon
    *ngIf="document.url"
    (click)="download.emit(document)"
    container="body"
    ngbTooltip="Download '{{ document.original_filename }}'"
    class="li-icon action primary"
    icon="download"
  ></fa-icon>
  <fa-icon
    *ngIf="document.url"
    (click)="delete.emit(document)"
    container="body"
    ngbTooltip="Delete document"
    class="li-icon action delete"
    icon="trash"
  ></fa-icon>
</div>
