import {
  CustomField,
  Document,
  FileAPI,
  Note,
  HistoryItemAPI,
  SurveyRef,
  DocumentDefinitionPost,
  DocumentDefinitionPut,
} from "./index";
import {
  CustomFieldPost as CustomFieldPost,
  CustomFieldPut as CustomFieldPut,
} from "../_models/customField";
import { DropdownOptionItemComponent } from "survey-angular-ui";
type UserRef = {
  color: string;
  email: string;
  id: number;
  name: string;
  username: string;
};

export interface ActivityAPI {
  id: number;
  key: string;
  name: string;
  description: string;
  activity_definition: { id: number; name: string };
  case: {
    id: number;
    key: string;
    name: string;
    definition: {
      id: number;
      key: string;
      name: string;
    };
  };
  completed_at: string;
  completed_by: UserRef;
  is_complete: boolean;
  created_at: string;
  created_by: UserRef;
  created_location: {
    altitude: number;
    altitude_accuracy: number;
    heading: number;
    latitude: number;
    location_recorded_dt: string;
    longitude: number;
    position_accuracy: number;
    speed: number;
  };
  custom_fields?: CustomField[];
  documents?: Document[];
  files?: FileAPI[];
  notes?: Note[];
  surveys: SurveyRef[];
  updated_at: string;
  updated_by: UserRef;
  updated_location: {
    altitude: number;
    altitude_accuracy: number;
    heading: number;
    latitude: number;
    location_recorded_dt: string;
    longitude: number;
    position_accuracy: number;
    speed: number;
  };
  history: HistoryItemAPI[];
  assigned_to: {
    id: number;
    name: string;
    username: string;
    email: string;
    color: string;
    assigned_at: string;
  };
}

export interface ActivityDefinitionDocumentResponse {
  id: number;
  name: string;
  description: string;
  is_required: boolean;
}

export interface AddNoteResponse {
  created_at: string;
  created_by: UserRef;
  id: number;
  model_id: number;
  model_name: string;
  note: string;
  updated_at: string;
  updated_by: UserRef;
}

export interface ActivityDefinition {
  id: number;
  name: string;
  description: string;
}

export type ActivityDefinitionPost = ActivityDefinitionPut;
export type ActivityDefinitionPut = Omit<
  ActivityDefinitionResponse,
  | "case_definition"
  | "created_at"
  | "created_by"
  | "updated_at"
  | "updated_by"
  | "surveys"
> & {
  surveys: number[];
  documents: Array<DocumentDefinitionPost | DocumentDefinitionPut>;
  custom_fields: Array<CustomFieldPost | CustomFieldPut>;
  case_definition_id: number;
};

export interface ActivityDefinitionResponse {
  id: number;
  name: string;
  description: string;
  surveys: {
    id: number;
    name: string;
  }[];
  documents: ActivityDefinitionDocumentResponse[];
  custom_fields: CustomField[];
  // Server returned fields
  case_definition?: {
    id: number;
    key: string;
    name: string;
  };
  created_at?: string;
  created_by?: UserRef;
  updated_at?: string;
  updated_by?: UserRef;
}

export interface ActivitySummaryAPI {
  id: number;
  name: string;
  is_complete: boolean;
  completed_at: string;
  completed_by: UserRef;
  created_at: string;
  updated_at: string;
}

export class PostActivity {
  constructor(
    private activity_definition_id: number,
    private case_id: number,
    private name: string,
    private key: string,
    private description: string,
    private notes: string[],
    private latitude: number,
    private longitude: number
  ) {}
}
