import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Campaign } from "../../_models";

@Component({
  selector: "app-create-campaign-success-modal",
  templateUrl: "./create-campaign-success-modal.component.html",
  styleUrls: ["./create-campaign-success-modal.component.scss"],
})
export class CreateCampaignSuccessModalComponent implements OnInit {
  @Input() campaign: Campaign;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
