<div class="editable">
  <div
    *ngIf="!amEditing"
    (click)="changeEditing(true)"
    class="editable-view-mode"
    ngbTooltip="Click to edit activity name"
  >
    <strong>{{ activityName }}</strong>
    <div class="edit-icon float-end">
      <fa-icon icon="pencil"></fa-icon>
    </div>
  </div>
  <div *ngIf="amEditing" class="editable-edit-mode">
    <form
      [formGroup]="nameForm"
      (ngSubmit)="onSaveName()"
      class="animated fadeIn form-wrapper mt4"
    >
      <div class="form-label-group">
        <input
          type="text"
          id="inputName"
          class="form-control capitalize-input"
          formControlName="name"
          [ngClass]="{
            'is-invalid': submitted && form['name'].errors
          }"
          placeholder="Activity Name"
          (change)="
            nameForm.patchValue({
              name: capitalizeInput($event)
            })
          "
          maxlength="35"
          required
          autofocus
        />
        <label for="inputName">Name</label>
        <div *ngIf="submitted && form['name'].errors" class="invalid-feedback">
          <div *ngIf="form['name'].errors['required']">
            Activity Name is required
          </div>
          <div *ngIf="form['name'].errors['minlength']">
            Activity Name must contain 6 characters.
          </div>
        </div>
        <div class="btn-tools-wrapper float-end">
          <button
            [disabled]="isSavingName || !nameForm.dirty"
            class="btn btn-primary"
            [ngClass]="{ disabled: isSavingName }"
          >
            <fa-icon *ngIf="!isSavingName" icon="check"></fa-icon>
            <fa-icon
              *ngIf="isSavingName"
              class="empty-data-icon"
              icon="spinner"
              [spin]="true"
            ></fa-icon>
          </button>
          <button
            class="btn btn-light"
            [disabled]="isSavingName || this.nameForm.invalid"
            (click)="changeEditing(false)"
          >
            <fa-icon icon="times"></fa-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
