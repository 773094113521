<app-header></app-header>
<div class="animated fadeIn container-fluid eps-container mb-2">
  <nav>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb my-2">
        <li class="breadcrumb-item active" aria-current="page">
          Data Collection Forms
        </li>
      </ol>
    </nav>
  </nav>
  <div *ngIf="isSurveysLoaded; else skeleton" class="card p-3">
    <div class="d-flex align-items-center mb-3">
      <h1 class="list-survey-header">{{ title }}</h1>

      <div class="btn-group ms-auto" role="group" aria-label="Basic example">
        <button
          *ngIf="!viewArchivedSurveysMode"
          (click)="loadSurveyData('archived')"
          class="btn btn-light"
        >
          <fa-icon class="empty-data-icon mr-1" icon="archive"> </fa-icon>View
          Archived Forms
        </button>
        <button
          *ngIf="viewArchivedSurveysMode"
          (click)="loadSurveyData('all')"
          class="btn btn-light"
        >
          All Forms
        </button>
        <button
          *hasPerm="'CREATE_SURVEY'"
          type="button"
          class="btn btn-primary btn-sm"
          routerLink="/create-form"
        >
          <fa-icon class="empty-data-icon" icon="plus"></fa-icon>
          Add New Form
        </button>
      </div>
    </div>
    <table
      *ngIf="isSurveysDataEmpty()"
      class="animated fadeIn table table-hover"
    >
      <thead>
        <th></th>
        <th>Name</th>
        <th *ngIf="!viewArchivedSurveysMode">Created</th>
        <th *ngIf="viewArchivedSurveysMode">Archived</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr *ngFor="let survey of sortedSurveys">
          <td class="align-middle text-center text-muted">
            <fa-icon icon="clipboard"></fa-icon>
          </td>
          <td class="view-survey align-middle">
            {{ survey.name }}
          </td>
          <td *ngIf="!viewArchivedSurveysMode" class="align-middle">
            {{ formatDateFromNow(survey.created_at) }}
          </td>
          <td *ngIf="viewArchivedSurveysMode" class="align-middle">
            {{ formatDateFromNow(survey.updated_at) }}
          </td>
          <td class="align-middle text-center">
            <div title="Actions" ngbDropdown container="body">
              <button
                class="btn btn-light no-arrow"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <fa-icon icon="ellipsis-h"></fa-icon>
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="animate-dropdown"
              >
                <button
                  *hasPerm="'READ_SURVEY'"
                  (click)="loadView(survey.id, 'Run')"
                  ngbDropdownItem
                >
                  View Form
                </button>
                <button
                  *hasPerm="'UPDATE_SURVEY'"
                  (click)="loadView(survey.id, 'Edit')"
                  ngbDropdownItem
                >
                  Edit Form
                </button>
                <button
                  (click)="loadView(survey.id, 'Results')"
                  *hasPerm="'READ_SURVEY'"
                  ngbDropdownItem
                >
                  View Responses
                </button>
                <div *hasPerm="'DELETE_SURVEY'">
                  <div class="dropdown-divider"></div>
                  <h6 class="dropdown-header">More Actions</h6>
                </div>
                <button
                  *hasPerm="'DELETE_SURVEY'"
                  (click)="showDeleteDialog(survey)"
                  ngbDropdownItem
                >
                  <fa-icon class="empty-data-icon" icon="trash"></fa-icon>
                  Delete Form
                </button>
                <div *hasPerm="'ARCHIVE_SURVEY'">
                  <button
                    *ngIf="!viewArchivedSurveysMode"
                    (click)="handleArchive(survey)"
                    ngbDropdownItem
                  >
                    <fa-icon class="empty-data-icon" icon="archive"></fa-icon>
                    Archive Form
                  </button>
                </div>
                <div *hasPerm="'ARCHIVE_SURVEY'">
                  <button
                    *ngIf="viewArchivedSurveysMode"
                    (click)="handleArchive(survey)"
                    ngbDropdownItem
                  >
                    <fa-icon class="empty-data-icon" icon="archive"></fa-icon>
                    Unarchive Form
                  </button>
                </div>
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Campaigns</h6>
                <button
                  *hasPerm="'CREATE_CAMPAIGN'"
                  (click)="createCampaign(survey.id)"
                  ngbDropdownItem
                >
                  Create Campaign
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!isSurveysDataEmpty()">
      <div class="alert alert-warning" role="alert">
        Currently no Forms to display.
      </div>
    </div>
  </div>
</div>
<ng-template #skeleton>
  <!-- TODO re-design skeleton loading
      <list-surveys-skeleton></list-surveys-skeleton>
    -->
  <app-animated-loader></app-animated-loader>
</ng-template>
