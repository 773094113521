<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Create Campaign</h4>
  <button
    type="button"
    class="btn btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<form [formGroup]="newCampaignForm" (ngSubmit)="submit()">
  <div class="modal-body">
    <div class="form-group">
      <label for="campaignName">*Name</label>
      <input
        type="text"
        class="form-control"
        id="campaignName"
        formControlName="campaignName"
        [ngClass]="{ 'is-invalid': isSubmitted && form['campaignName'].errors }"
        placeholder="Campaign Name"
      />
      <div
        *ngIf="isSubmitted && form['campaignName'].errors"
        class="invalid-feedback"
      >
        <div *ngIf="form['campaignName'].errors['required']">
          Please enter a name for your campaign.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="campaignDescription">Description</label>
      <textarea
        class="form-control"
        id="campaignDescription"
        formControlName="campaignDescription"
        [ngClass]="{
          'is-invalid': isSubmitted && form['campaignDescription'].errors
        }"
        rows="3"
      ></textarea>
      <div
        *ngIf="isSubmitted && form['campaignDescription'].errors"
        class="invalid-feedback"
      >
        <div *ngIf="form['campaignDescription'].errors['required']">
          Please enter a description for your campaign.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="campaignStartDate">*Start Date</label>
      <div class="input-group">
        <input
          id="campaignStartDate"
          formControlName="campaignStartDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dp"
          ngbDatepicker
          #dp="ngbDatepicker"
          required
        />
        <button
          class="btn btn-outline-secondary fa fa-calendar"
          (click)="dp.toggle()"
          type="button"
        ></button>
        <div
          *ngIf="isSubmitted && form['campaignStartDate'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="form['campaignStartDate'].errors['required']">
            Please select a start date for your campaign.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="campaignEndDate">*End Date</label>
      <div class="input-group">
        <input
          id="campaignEndDate"
          formControlName="campaignEndDate"
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dp2"
          ngbDatepicker
          #dp2="ngbDatepicker"
        />
        <button
          class="btn btn-outline-secondary fa fa-calendar"
          (click)="dp2.toggle()"
          type="button"
        ></button>
        <div
          *ngIf="isSubmitted && form['campaignEndDate'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="form['campaignEndDate'].errors['required']">
            Please select an end date for your campaign.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      (click)="activeModal.dismiss('Cross click')"
      class="btn btn-link btn-sm"
      [ngClass]="{ disabled: isSavingData }"
      type="button"
    >
      Cancel
    </button>
    <button
      class="btn btn-lg btn-primary btn-sm"
      [ngClass]="{ disabled: isSavingData }"
    >
      Save
    </button>
  </div>
</form>
