<app-header></app-header>
<div class="eps-container container-fluid">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Admin Dashboard</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Users</li>
    </ol>
  </nav>

  <div
    class="users-container overflow-auto mb-2 gap-3"
    *ngIf="isUserListLoaded; else skeleton"
  >
    <div class="overflow-auto card">
      <span class="d-flex align-items-center p-3 pb-1">
        <h1 class="fw-light">Users</h1>
        <button
          type="button"
          class="btn btn-outline-primary ms-auto"
          (click)="openModal()"
        >
          <fa-icon class="empty-data-icon" icon="plus"></fa-icon>
          Add User
        </button>
      </span>

      <ul class="overflow-auto ps-2 m-1">
        <li
          (click)="displayUserData(selection, user.id)"
          [class.selected]="currSelectedUser === selection"
          *ngFor="let user of users; let selection = index"
          class="user-list-item d-flex align-items-center p-2 me-1"
        >
          <span
            class="w-40 user-inital-avatar circle me-3"
            data-container="body"
            placement="top"
            ngbTooltip="{{ generateUserStatusClass(user.is_active) }}"
            [ngClass]="user.avatarBgColor"
            >{{ user.firstInitial }}
            <i
              [ngClass]="generateUserStatusClass(user.is_active)"
              class="b-white avatar-right"
            ></i>
          </span>
          <div>
            <div class="users-user-username-data lh-base">
              {{ user.name }}
            </div>
            <div class="text-muted small">
              {{ user.email }}
            </div>
            <div class="text-muted small">
              Last Update
              <strong>{{ formatLastUpdateDate(user.updated_at) }}</strong>
            </div>
          </div>
          <div class="align-self-center ms-auto flex-shrink-1">
            <span title="role" class="badge bg-light text-dark h-100">{{
              user.role.name
            }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="pe-0">
      <div class="card card-body h-100 animated fadeIn">
        <div class="no-data-loaded" *ngIf="!isUserDataLoaded">
          <object
            type="image/svg+xml"
            data="../../../assets/profile_illustration.svg"
          >
            Your browser does not support SVG
          </object>
        </div>
        <form
          *ngIf="isUserDataLoaded"
          [formGroup]="userForm"
          (ngSubmit)="save()"
          class="position-relative h-100"
        >
          <h1 class="users-dash-header">{{ name }}</h1>
          <div class="form-group mb-3">
            <div class="form-check form-switch">
              <input
                type="checkbox"
                (change)="checkValue()"
                formControlName="is_active"
                class="form-check-input"
                id="is_active"
              />
              <label for="is_active" class="form-check-label p-1"
                ><strong>{{ accActionTxt }}</strong> Account</label
              >
            </div>
          </div>
          <div class="form-label-group">
            <input
              type="email"
              formControlName="email"
              class="form-control"
              placeholder="Email address"
              required
              autofocus
            />
            <label for="inputEmail">Email address</label>
          </div>
          <div class="form-label-group">
            <input
              type="text"
              formControlName="username"
              class="form-control"
              placeholder="username"
            />
            <label for="inputEmail">Username</label>
          </div>
          <div class="form-label-group">
            <input
              type="text"
              formControlName="name"
              class="form-control"
              placeholder="name"
            />
            <label for="inputEmail">Name</label>
          </div>
          <div class="form-group mb-3">
            <select
              class="form-control dropdown form-select"
              formControlName="role"
              name="roles"
              required
            >
              <option *ngFor="let role of roles" [ngValue]="role.name">
                {{ role.name }}
              </option>
            </select>
          </div>
          <div class="form-label-group">
            <input
              type="text"
              formControlName="location"
              class="form-control"
              placeholder="name"
            />
            <label for="inputLocation">Location</label>
          </div>
          <div class="form-label-group">
            <div class="input-group mb-3">
              <div class="input-group-append">
                <button
                  (click)="openChangePasswordDialog()"
                  class="btn btn-link"
                  type="button"
                  id="button-addon1"
                >
                  Change Password
                </button>
              </div>
              <input
                type="text"
                value="********"
                formControlName="password"
                class="form-control"
              />
            </div>
            <small id="emailHelp" class="pl-3 form-text text-muted"
              ><fa-icon class="empty-data-icon" icon="info-circle"></fa-icon>
              Click the link above to change the user's password.</small
            >
          </div>
          <div class="form-label-group">
            <div class="input-group mb-3">
              <button
                type="button"
                (click)="resendWelcomeEmail()"
                class="btn btn-outline-primary d-block w-100"
              >
                <fa-icon class="empty-data-icon" icon="envelope"></fa-icon>
                Resend Welcome Email
              </button>
            </div>
          </div>
          <div class="position-absolute bottom-0 end-0 d-flex gap-2">
            <button
              [ngClass]="{ disabled: isSavingUserData }"
              (click)="cancel()"
              class="btn btn-light"
              type="button"
            >
              Cancel
            </button>
            <button
              [ngClass]="{ disabled: isSavingUserData }"
              class="btn btn-primary"
              type="submit"
            >
              <fa-icon
                *ngIf="isSavingUserData"
                class="empty-data-icon"
                icon="spinner"
                [spin]="true"
              ></fa-icon>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <users-skeleton></users-skeleton>
</ng-template>
