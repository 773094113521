<nav class="navbar navbar-dark bg-dark navbar-expand">
    <div class="container-fluid pl-3">
        <a
                class="navbar-brand eps-logo"
                routerLink="/home"
                *ngIf="!isCurrentUserAnonymous"
        >
            {{ appTitle }}
            <span class="eps-logo-name"
            >Data Collection Platform for the ILNA Project</span
            >
        </a>
        <a class="navbar-brand eps-logo" *ngIf="isCurrentUserAnonymous">
            {{ appTitle }}
            <span class="eps-logo-name"
            >Data Collection Platform for the ILNA Project</span
            >
        </a>
        <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample07"
                aria-controls="navbarsExample07"
                aria-expanded="false"
                aria-label="Toggle navigation"
        ></button>
        <div class="collapse navbar-collapse" id="navbarsExample07">
            <ul class="navbar-nav mr-auto">
                <li *ngIf="displayNavLinks && !isCurrentUserAnonymous" class="nav-item">
                    <a
                            class="nav-link"
                            [routerLink]="['/cases']"
                            [queryParams]="{ case_defn_id: 3}"
                    >Data Collection</a>
                </li>
                <li *ngIf="displayNavLinks && !isCurrentUserAnonymous" class="nav-item">
                    <a
                            class="nav-link"
                            [routerLink]="['/ilna-dashboard/2']"
                    >Data Tracking</a>
                </li>
                <li *ngIf="displayNavLinks && !isCurrentUserAnonymous" class="nav-item">
                    <a
                            class="nav-link"
                            [routerLink]="['/data-reporting-dashboards']"
                    >Data Reporting</a>
                </li>
            </ul> <!-- need to leave this line for spacing -->
            <div *ngIf="userDataLoaded && !isCurrentUserAnonymous" class="">
                <ul class="navbar-nav mr-auto">
                    <li>
            <span
                    class="w-40 user-inital-avatar circle"
                    placement="bottom"
                    data-container="body"
                    [ngClass]="currUser.color"
                    ngbTooltip="{{ currUser.username }}"
            >{{ avatarFirstInitial }}
            </span>
                    </li>
                    <li ngbDropdown class="nav-item dropdown" container="body">
                        <button
                                ngbDropdownToggle
                                class="nav-link dropdown-toggle"
                                id="dropdown07"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                        >
                            {{ generateDisplayEmail() }}
                        </button>
                        <div
                                ngbDropdownMenu
                                class="animate-dropdown"
                                aria-labelledby="dropdown07"
                        >
                            <a class="dropdown-item" routerLink="/profile">My Profile</a>
                            <div *ngIf="isUserAdmin">
                                <div class="dropdown-divider"></div>
                                <a ngbDropdownItem routerLink="/admin/dashboard"
                                >Admin Dashboard</a
                                >
                                <div class="dropdown-divider"></div>
                            </div>
                            <a ngbDropdownItem class="dropdown-item">Help</a>
                            <a ngbDropdownItem (click)="logOut()" class="dropdown-item"
                            >Log Out</a
                            >
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
