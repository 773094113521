import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  HostListener,
  Host,
  Input,
} from "@angular/core";

@Directive({
  selector: "[appActiveTab]",
})
export class ActiveTabDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Input("orientation") orientation: "horizontal" | "vertical" = "horizontal";

  ngAfterViewInit() {
    this.setActiveTabBorder();
  }

  @HostListener("click")
  onClick() {
    // Use setTimeout to ensure the active class has been updated before we set the border
    setTimeout(() => this.setActiveTabBorder(), 0);
  }

  setActiveTabBorder() {
    // Get the active tab
    const activeTab = this.el.nativeElement.querySelector(".active");
    const border = this.el.nativeElement.querySelector(".active-tab-border");
    // Set the width of the active tab border
    if (!activeTab || !border) return;
    if (this.orientation === "horizontal") {
      this.renderer.setStyle(
        border,
        "width",
        activeTab.getBoundingClientRect().width + "px"
      );
      this.renderer.setStyle(border, "height", "2px");

      // Set the left position of the active tab border
      this.renderer.setStyle(border, "left", activeTab.offsetLeft + "px");
    } else {
      this.renderer.setStyle(
        border,
        "height",
        activeTab.getBoundingClientRect().height + "px"
      );
      this.renderer.setStyle(border, "width", "2px");

      // Set the top position of the active tab border
      this.renderer.setStyle(border, "top", activeTab.offsetTop + "px");
    }
  }
}
