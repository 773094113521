<app-header></app-header>
<div class="eps-container container-fluid">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Admin Dashboard</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Case Definitions
      </li>
    </ol>
  </nav>

  <app-animated-loader
    *ngIf="isLoading"
    class="animated fadeIn"
  ></app-animated-loader>

  <div class="card animated fadeIn" *ngIf="!isLoading">
    <div *ngIf="isCaseDefinitonsDataEmpty()" class="card-body">
      <div class="alert alert-warning" role="alert">
        <p>No Case Definitions available. Create a New Case Definition.</p>
        <button
          routerLink="/cases-definitions/new"
          type="button"
          class="btn btn-primary"
        >
          <fa-icon icon="plus" class="me-1"></fa-icon> New Case Definition
        </button>
      </div>
    </div>
    <div *ngIf="!isCaseDefinitonsDataEmpty()" class="card-body">
      <button
        routerLink="/cases-definitions/new"
        type="button"
        class="btn btn-primary float-end mb-3"
      >
        + New Case Definition
      </button>
      <table class="animated fadeIn table table-hover mt-3">
        <thead>
          <tr>
            <th>Created By</th>
            <th>Name</th>
            <th>Description</th>
            <th>Date Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            title="Click to Edit"
            *ngFor="let definition of caseDefinitions"
            (click)="loadEditCaseDefiniton(definition.id)"
          >
            <td>
              <app-eps-avatar
                [color]="definition.created_by.color"
                [username]="definition.created_by.username"
                class="size-15 mr-1"
              ></app-eps-avatar>
              <span>{{ definition.created_by.name }}</span>
            </td>
            <td>{{ definition.name }}</td>
            <td title="{{ definition.description }}">
              {{ generateCaseDesc(definition.description) }}
            </td>
            <td>{{ formatDateFromNow(definition.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
