import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { ActivitySummaryAPI } from "app/_models";

@Component({
  selector: "app-activity-list-item",
  templateUrl: "./activity-list-item.component.html",
  styleUrls: ["../list-item/list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: "list-group-item p-1" },
})
export class ActivityListItemComponent implements OnChanges {
  @Input() activity: ActivitySummaryAPI;
  @Output() mainChange = new EventEmitter<ActivitySummaryAPI>();
  @Output() remove = new EventEmitter<ActivitySummaryAPI>();
  pending: boolean = false;

  ngOnChanges() {
    this.pending = false;
  }

  changeMainCitation() {
    this.pending = true;
    this.mainChange.emit(this.activity);
  }

  onRemove() {
    this.pending = true;
    this.remove.emit(this.activity);
  }
}
