<h6>Description</h6>
<div class="editable">
  <div
    *ngIf="!amEditing"
    (click)="changeEditing(true)"
    class="editable-view-mode"
    ngbTooltip="Click to edit activity description"
  >
    {{ activityDescription || "Add a description..." }}
    <div class="edit-icon float-end">
      <fa-icon icon="pencil"></fa-icon>
    </div>
  </div>
  <div *ngIf="amEditing" class="editable-edit-mode">
    <form
      [formGroup]="descriptionForm"
      (ngSubmit)="onSaveDescription()"
      class="animated fadeIn form-wrapper mt4"
    >
      <div class="form-label-group">
        <textarea
          id="inputDescription"
          class="form-control"
          formControlName="description"
          autofocus
        ></textarea>
        <div class="btn-tools-wrapper float-end">
          <button
            class="btn btn-primary"
            [disabled]="descriptionForm.pristine && descriptionForm.dirty"
            [ngClass]="{ disabled: isSavingDescription }"
          >
            <fa-icon *ngIf="!isSavingDescription" icon="check"></fa-icon>
            <fa-icon
              *ngIf="isSavingDescription"
              class="empty-data-icon"
              icon="spinner"
              [spin]="true"
            ></fa-icon>
          </button>
          <button
            class="btn btn-light"
            [disabled]="isSavingDescription || descriptionForm.invalid"
            (click)="changeEditing(false)"
          >
            <fa-icon icon="times"></fa-icon>
          </button>
        </div>
        <small class="pl-2 form-text text-muted"> Optional</small>
      </div>
    </form>
  </div>
</div>
