import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Utils } from "app/_helpers";
import { Subscription } from "rxjs";

@Component({
  selector: "app-edit-textarea",
  templateUrl: "./edit-textarea.component.html",
  styleUrls: ["./edit-textarea.component.scss"],
})
export class EditTextComponent implements OnInit, OnChanges {
  value: string;
  @Output() save = new EventEmitter<string>();
  @ViewChild("editText", { static: false }) editText: ElementRef;
  @Input() label: string = "";
  @Input() placeholder: string = "Enter a value...";
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() inputClass: string = "";
  @Input() tooltip: string = "Click to edit";
  @Input() control: FormControl<string>;
  @Input() type = "textarea";
  editing: boolean = false;
  pending: boolean = false;
  submitted: boolean = false;

  constructor(private _elementRef: ElementRef, private utils: Utils) {}

  ngOnInit() {
    if (!this.control) {
      this.control = new FormControl();
    }
    this.value = this.control.value;
  }

  ngOnChanges() {
    if (this.control) {
      this.value = this.control.value;
    }
    this.pending = false;
    this.editing = false;
  }

  public capitalizeInput(_str: string): string {
    return this.utils.generateCapitalizeString(_str);
  }

  getErrorMessage(errors: { [key: string]: boolean }) {
    for (const errorKey in errors) {
      if (this.errorMessages[errorKey]) {
        return this.errorMessages[errorKey];
      }
    }
    return "";
  }

  adjustHeight() {
    if (this.editing) {
      setTimeout(() => {
        const nativeElement = this.editText
          .nativeElement as HTMLTextAreaElement;
        if (this.type === "textarea") {
          nativeElement.style.height = "auto";
          nativeElement.style.height = nativeElement.scrollHeight + 10 + "px";
        }
        nativeElement.focus();
      });
    }
  }

  async onSave() {
    this.pending = true;
    this.value = this.control.value;
    this.save.emit(this.control.value);
  }

  protected cancel(): void {
    this.editing = false;
    this.control.setValue(this.value);
  }

  @HostListener("document:mousedown", ["$event.target"])
  public onClick(targetElement) {
    const clickedInside =
      this._elementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      this.editing = true;

      this.adjustHeight();
    } else if (!clickedInside) {
      this.cancel();
    }
  }
}
