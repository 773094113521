<form
  [formGroup]="activityDefinitonForm"
  (ngSubmit)="onSaveActivityDefinition()"
  class="card animated eps-container fadeIn h-100"
>
  <div class="d-flex align-items-center card-body sticky-top bg-white">
    <h5 class="card-title m-0">
      {{ headerLabel }}
    </h5>
    <div *ngIf="mode === 'edit'" ngbDropdown container="body" class="ms-auto">
      <button type="button" class="btn btn-light no-arrow" ngbDropdownToggle>
        <fa-icon icon="ellipsis-h"></fa-icon>
      </button>
      <div ngbDropdownMenu class="animate-dropdown">
        <div>
          <button (click)="openDeleteDialogPrompt()" ngbDropdownItem>
            <fa-icon class="empty-data-icon" icon="trash"></fa-icon>
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body overflow-auto py-1 me-1">
    <div class="form-label-group">
      <input
        type="text"
        id="inputName"
        class="form-control capitalize-input"
        formControlName="name"
        [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"
        placeholder="Activity Definition Name"
        (change)="patchName($event)"
        maxlength="35"
        required
        autofocus
      />
      <label for="inputName">Name</label>
      <div *ngIf="submitted && form['name'].errors" class="invalid-feedback">
        <div *ngIf="form['name'].errors['required']">
          Activity Definition Name is required
        </div>
        <div *ngIf="form['name'].errors['minlength']">
          Activity Definition Name must contain 6 characters.
        </div>
      </div>
    </div>

    <div class="form-label-group">
      <input
        type="text"
        id="inputDescription"
        class="form-control"
        formControlName="description"
        placeholder="Activity Definition Description"
      />
      <label for="inputDescription">Description</label>
      <small class="pl-2 form-text text-muted"> Optional</small>
    </div>
    <!-- CUSTOM FIELDS START -->

    <custom-fields-creator
      [customFieldsData]="activityDefinition.custom_fields"
      (customFieldListChanged)="onCustomFieldsChanged($event)"
    ></custom-fields-creator>

    <!-- CUSTOM FIELDS END -->

    <!-- SURVEYS START -->
    <div class="d-flex align-items-center mb-2">
      <h5 class="m-0">Forms</h5>
      <!-- <button type="button" class="btn btn-primary float-end">+ Create New Survey</button> -->
    </div>
    <p>
      <fa-icon icon="info-circle"></fa-icon> Select one or more pre-created
      forms to add to your Activity Definition.
    </p>
    <div
      class="form-error-msg"
      *ngIf="submitted && !form['surveyOptionsCheckBoxes'].valid"
    >
      Must Select at least one form.
    </div>
    <div class="list-group list-group-flush">
      <label
        class="list-group-item mb-0"
        formArrayName="surveyOptionsCheckBoxes"
        *ngFor="let survey of surveyOptionCheckBoxControls(); let i = index"
      >
        <input type="checkbox" [formControlName]="i" class="me-1" />
        {{ surveyOptions[i].name }}
      </label>
    </div>
    <!-- SURVEYS END -->

    <!-- DOCUMENTS START -->
    <div class="d-flex align-items-center mb-2">
      <h5 class="m-0">Documents</h5>
      <button
        (click)="openAddDocumentsModal()"
        type="button"
        class="btn btn-primary ms-auto"
      >
        <fa-icon icon="plus" class="me-1"></fa-icon> New Documents
      </button>
    </div>
    <div class="text-muted">
      <fa-icon icon="info-circle" class="me-1"></fa-icon> Click New Documents to
      add Documents to your Case Type.
    </div>
    <div *ngIf="!documentsAdded()" class="list-group list-group-flush">
      <div
        *ngFor="let doc of activityDefinition.documents; let i = index"
        class="list-group-item p-1"
      >
        <div class="d-flex gap-2 align-items-center">
          <fa-icon class="li-icon flex-grow-0" icon="file"></fa-icon>
          {{ doc.name }}
          <small class="text-muted">
            {{ doc.is_required ? " (Required)" : "" }}
          </small>
        </div>
        <div class="actions mr-1">
          <fa-icon
            (click)="removeDocumentFromDocumentsList(doc)"
            container="body"
            ngbTooltip="Remove document"
            class="li-icon action delete"
            icon="trash"
          ></fa-icon>
        </div>
      </div>
    </div>
    <!-- DOCUMENTS END -->
  </div>
  <div class="card-body sticky-bottom bg-white">
    <div class="float-end d-flex gap-1">
      <button (click)="onCancel()" class="btn btn-light" type="button">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary">Save</button>
    </div>
  </div>
</form>
