import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Document } from "app/_models";

@Component({
  selector: "app-document-list-item",
  templateUrl: "./document-list-item.component.html",
  styleUrls: ["../list-item/list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: "list-group-item p-1" },
})
export class DocumentListItemComponent {
  @Input() document: Document;
  @Output() upload = new EventEmitter<Document>();
  @Output() download = new EventEmitter<Document>();
  @Output() delete = new EventEmitter<Document>();

  getFileIcon(filename: string): IconName {
    const extension = filename.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "file-pdf";
      case "csv":
        return "file-csv";
      case "doc":
      case "docx":
        return "file-word";
      case "xls":
      case "xlsx":
        return "file-excel";
      case "ppt":
      case "pptx":
        return "file-powerpoint";
      case "jpg":
      case "png":
      case "gif":
        return "file-image";
      case "zip":
      case "rar":
      case "7z":
        return "file-archive";
      case "mp3":
      case "wav":
      case "ogg":
        return "file-audio";
      case "mp4":
      case "avi":
      case "mov":
        return "file-video";
      case "txt":
        return "file-alt";
      default:
        return "file";
    }
  }
}
