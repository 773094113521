<app-header></app-header>
<div *ngIf="isLoading" class="animated fadeIn eps-container">
    <app-animated-loader></app-animated-loader>
</div>
<div
        *ngIf="!isLoading"
        class="animated fadeIn eps-container container-fluid mb-2"
>
    <!-- #region Breadcrumb -->

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb my-2">
            <li class="breadcrumb-item active" aria-current="page">
                {{ breadcrumbLabel }}
            </li>
        </ol>
    </nav>

    <!-- endregion -->

    <div class="card h-100">
        <div class="card-body">
            <div class="d-flex mb-2">
                <button
                        *hasPerm="'CREATE_CASE'"
                        routerLink="/cases/new"
                        type="button"
                        class="btn btn-primary ms-auto"
                >
                    <fa-icon icon="plus" class="me-1"></fa-icon>
                    Create New Case
                </button>
            </div>

            <!-- #region CaseDataTable -->
            <table datatable [dtOptions]="dtOptions" class="table row-border hover">
                <thead>
                <tr>
                    <td>ID</td>
                    <th>RCDTS ID</th>
                    <th>School</th>
                    <th>District</th>
                    <th>Area</th>
                    <th>Status</th>
                    <th>Grade Band</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let aCase of caseList" class="">
                    <td>{{ aCase.id }}</td>
                    <td>{{ aCase.key }}</td>
                    <td>{{ aCase.name }}</td>
                    <td>{{ aCase.district }}</td>
                    <td>{{ aCase.area }}</td>
                    <td>{{ aCase.improvementStatus }}</td>
                    <td>{{ aCase.gradeBand }}</td>
                </tr>
                </tbody>
            </table>
            <!-- endregion CaseDataTable -->
        </div>
    </div>
</div>
