<app-header></app-header>
<div *ngIf="isLoading" class="animated fadeIn eps-container">
  <app-animated-loader></app-animated-loader>
</div>
<div
  *ngIf="!isLoading"
  class="animated fadeIn eps-container container-fluid mb-2"
>
  <!-- #region Breadcrumb -->

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item active" aria-current="page">
        {{ breadcrumbLabel }}
      </li>
    </ol>
  </nav>

  <!-- endregion -->

  <div class="card h-100">
    <div class="card-body">
      <div class="d-flex mb-2">
        <button
          *hasPerm="'CREATE_CASE'"
          routerLink="/cases/new"
          type="button"
          class="btn btn-primary ms-auto"
        >
          <fa-icon icon="plus" class="me-1"></fa-icon> Create New Case
        </button>
      </div>

      <!-- #region CaseDataTable -->
      <table datatable [dtOptions]="dtOptions" class="table row-border hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Case ID</th>
            <th>Name</th>
            <th>Case Type</th>
            <th>Assignee</th>
            <th>Last Update Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aCase of caseList" class="">
            <td>{{ aCase.id }}</td>
            <td>
              <app-status-badge [status]="aCase.status"></app-status-badge>
            </td>
            <td>{{ aCase.key }}</td>
            <td>{{ aCase.name }}</td>
            <td>{{ aCase.caseType }}</td>
            <td>
              <app-eps-avatar
                [username]="aCase.assignee ? aCase.assignee.name : 'U'"
                [color]="aCase.assignee ? aCase.assignee.color : 'grey'"
              ></app-eps-avatar>
              {{ aCase.assignee ? aCase.assignee.name : "Unassigned" }}
            </td>
            <td>{{ formatDateFromNow(aCase.updatedAt) }}</td>
          </tr>
        </tbody>
      </table>
      <!-- endregion CaseDataTable -->
    </div>
  </div>
</div>
