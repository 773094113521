import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import * as showdown from "showdown";
import * as Survey from "survey-core";
import { AngularComponentFactory } from "survey-angular-ui";
import * as widgets from "surveyjs-widgets";
// import 'inputmask/dist/inputmask/phone-codes/phone.js';

widgets.icheck(Survey);
widgets.select2(Survey);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey);
widgets.jqueryuidatepicker(Survey);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey);
// widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey);
widgets.bootstrapslider(Survey);
widgets.prettycheckbox(Survey);

Survey.JsonObject.metaData.addProperty("questionbase", "popupdescription:text");
Survey.JsonObject.metaData.addProperty("page", "popupdescription:text");

const converter = new showdown.Converter();
@Component({
  selector: "survey-component",
  template: `<survey class="overflow-auto" [model]="model"></survey>`,
})
export class SurveyComponent implements OnInit {
  @Output() submitSurvey = new EventEmitter<any>();
  @Input() json: any;
  @Input() result: any;
  @Input() response!: {};
  model: Survey.Model | undefined;

  ngOnInit() {
    const survey = new Survey.Model(this.json);
    survey.onAfterRenderQuestion.add(
      (
        survey: Survey.Model,
        options: {
          question: Survey.Question & { popupdescription };
          htmlElement: HTMLElement;
        }
      ) => {
        if (!options.question.popupdescription) {
          return;
        }
        const btn = document.createElement("button");
        btn.className = "btn btn-info btn-xs";
        btn.innerHTML = "More Info";
        const question = options.question;
        btn.onclick = function () {
          // showDescription(question);
          alert(options.question.popupdescription);
        };
        const header = options.htmlElement.querySelector("h5");
        const span = document.createElement("span");
        span.innerHTML = "  ";
        header.appendChild(span);
        header.appendChild(btn);
      }
    );
    survey.onComplete.add((result: { data: any }, options: any) => {
      this.submitSurvey.emit(result.data);
      this.result = result.data;
    });
    survey.onValueChanged.add(function (sender: any, options: any) {
      let changedSurveyResponse = sender;
      localStorage.setItem(
        "surveyResponseData",
        JSON.stringify(changedSurveyResponse.data)
      );
    });
    survey.onTextMarkdown.add((survey: Survey.Model, options) => {
      // Convert Markdown to HTML
      let str = converter.makeHtml(options.text);
      // Remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      // Set HTML markup to render
      options.html = str;
    });
    survey.data = this.response || {};
    survey.showProgressBar = "bottom";
    this.model = survey;
  }
}
