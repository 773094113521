<div class="eps-container">
  <div class="row justify-content-md-center">
    <div class="col-4">
      <div class="card">
        <h5 class="card-header">Reset Password</h5>
        <div class="card-body">
          <h5 class="card-title">
            Please use this form to reset your password.
          </h5>
          <p class="card-text">
            In order to protect your account, make sure your password:
          </p>
          <ul>
            <li>is longer than <b>10 characters</b></li>
            <li>is less than <b>64 characters</b></li>
          </ul>
          <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
            <div class="form-label-group">
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                class="form-control"
                formControlName="newPassword"
                placeholder="New Password"
                [ngClass]="{
                  'is-invalid': submitted && form['newPassword'].errors
                }"
                required
                autofocus
              />

              <label for="newPassword">New Password</label>
              <div
                *ngIf="submitted && form['newPassword'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="form['newPassword'].errors['required']">
                  New Password is required
                </div>
                <div *ngIf="form['newPassword'].errors['minlength']">
                  Password must be more than 10 characters.
                </div>
                <div *ngIf="form['newPassword'].errors['maxlength']">
                  Password must be less than 64 characters.
                </div>
              </div>
              <div (click)="toggleFieldTextType()" class="view-password-btn">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-eye-slash': !fieldTextType,
                    'fa-eye': fieldTextType
                  }"
                ></i>
              </div>
            </div>

            <div class="form-label-group">
              <input
                [type]="repeatFieldTextType ? 'text' : 'password'"
                class="form-control"
                formControlName="confirmPassword"
                placeholder="Confirm Password"
                [ngClass]="{
                  'is-invalid': submitted && form['confirmPassword'].errors
                }"
                required
              />
              <label for="confirmPassword">Confirm Password</label>
              <div
                *ngIf="submitted && form['confirmPassword'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="form['confirmPassword'].errors['required']">
                  Confirm Password is required
                </div>
                <div
                  *ngIf="form['confirmPassword'].errors['pwdMatchValidator']"
                >
                  Passwords must match
                </div>
              </div>
              <div
                (click)="toggleRepeatFieldTextType()"
                class="view-password-btn"
              >
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-eye-slash': !repeatFieldTextType,
                    'fa-eye': repeatFieldTextType
                  }"
                ></i>
              </div>
            </div>

            <button class="btn btn-primary" type="button" type="submit">
              {{ buttonText }}
            </button>
          </form>
          <hr />
          <div class="row">
            <div class="col-12">
              <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                <h4 class="alert-heading">Password Reset Successful</h4>
                <p>
                  Your password reset was successful. Please follow
                  <a href="/login" class="alert-link">this link</a> to login.
                </p>
              </div>
              <div *ngIf="showFailed" class="alert alert-danger" role="alert">
                <h4 class="alert-heading">Password Reset Unsuccessful</h4>
                <p>
                  Your password reset was not successful with the following
                  message.
                </p>
                <p>{{ errorMessage }}</p>
                <p>Please try again.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
