<div class="animated fadeIn container eps-container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="card-title mb-3 ml-3">
            <div class="d-flex justify-content-start">
              <div class="image-container">
                <div class="empty-image"></div>
              </div>
              <div class="userData ml-3">
                <div class="empty-header-2"></div>
                <div class="empty-text"></div>
                <div class="empty-text"></div>
              </div>
              <div class="userData ml-3"></div>
            </div>
          </div>
          <div class="row">
            <hr />
            <div class="col-2">
              <div
                class="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <div class="empty-tab-link"></div>
                <div class="empty-tab-link"></div>
              </div>
            </div>
            <div class="col-10">
              <div class="tab-content" id="v-pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <div>
                    <div class="float-end empty-button"></div>
                    <div class="profile-information-content">
                      <div class="empty"></div>
                      <div class="empty"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
