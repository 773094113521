<app-header pageLocation="data_reporting"></app-header>
<div class="eps-container">
    <div class="container">
        <div class="row text-center pb-lg-5">
            <h1 class="display-4">Data Reporting</h1>
        </div>
        <div class="align-items-stretch d-flex gap-3 justify-content-center row">
            <div class="list-group">
                <button type="button" class="list-group-item list-group-item-action"
                        [routerLink]="['/ilna-dashboard/1']">
                    Principal Survey Response Tool
                </button>
                <button type="button" class="list-group-item list-group-item-action"
                        [routerLink]="['/ilna-dashboard/13']">Staff Survey Summary
                </button>
                <button type="button" class="list-group-item list-group-item-action">AI Results (TBD)</button>
            </div>
        </div>
    </div>
</div>