<div
  *ngIf="!editing"
  class="editable-view-mode p-1  {{ inputClass }}"
  [ngClass]="{ 'd-flex align-items-center': type === 'text' }"
  [ngbTooltip]="tooltip"
>
  {{ value || placeholder }}
  <div class="edit-icon float-end ml-auto">
    <fa-icon
      [icon]="pending ? 'circle-notch' : 'pencil'"
      [spin]="pending"
    ></fa-icon>
  </div>
</div>
<div *ngIf="editing" class="editable-edit-mode">
  <div class="mb-0">
    <input
      *ngIf="type === 'text'"
      type="text"
      class="form-control p-1 {{ inputClass }}"
      #editText
      [formControl]="control"
      [ngClass]="{
        'is-invalid': submitted && control.errors
      }"
      [placeholder]="placeholder"
    />
    <textarea
      *ngIf="type === 'textarea'"
      type="text"
      class="form-control p-1  {{ inputClass }}"
      #editText
      (focus)="adjustHeight()"
      [formControl]="control"
      [ngClass]="{
        'is-invalid': submitted && control.errors
      }"
      [placeholder]="placeholder"
    ></textarea>
    <div *ngIf="control.errors" class="small text-danger p-2">
      {{ getErrorMessage(control.errors) }}
    </div>
    <div class="btn-tools-wrapper animate-once">
      <button
        [disabled]="pending || control.pristine || control.invalid"
        class="btn btn-primary"
        [ngClass]="{ disabled: pending }"
        (click)="onSave()"
      >
        <fa-icon
          [icon]="pending ? 'circle-notch' : 'check'"
          [spin]="pending"
        ></fa-icon>
      </button>
      <button
        class="btn btn-light"
        [disabled]="pending"
        [ngClass]="{ 'disabled opacity-50': pending }"
        (click)="cancel()"
      >
        <fa-icon icon="times"></fa-icon>
      </button>
    </div>
  </div>
</div>
