<app-header></app-header>
<div
        *ngIf="route.children.length === 0 && !isLoading"
        class="animated fadeIn eps-container container-fluid mb-2"
>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb my-2">
            <li class="breadcrumb-item">
                <a
                        [routerLink]="['/cases']"
                        [queryParams]="{ case_defn_id: activity.case.definition.id }"
                >
                    {{ activity.case.definition.name }}
                </a>
            </li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/cases', activity.case.id]">
                    {{ activity.case.name }}</a
                >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{ activity.name }}
            </li>
        </ol>
    </nav>

    <app-animated-loader *ngIf="isLoading"></app-animated-loader>

    <div class="activity-container gap-3 overflow-auto">
        <app-left-pane
                class="overflow-auto"
                [activity]="activity"
                (activityNameChanged)="onActivityNameChanged($event)"
                (activityDescriptionChanged)="onActivityDescriptionChanged($event)"
                (activityNoteAdded)="onActivityNoteAdded($event)"
                (documentUploaded)="onActivityDocumentUpload()"
                (documentDeleted)="onActivityDocumentDeleted()"
        ></app-left-pane>
        <app-right-pane
                class="overflow-auto"
                [activityID]="activity.id"
                [createdBy]="activity.created_by"
                [assignedTo]="activity.assigned_to"
                [customFields]="activity.custom_fields"
                [isComplete]="activity.is_complete"
                (isCompleteChanged)="onActivityIsCompleteChanged($event)"
                (assigneeChanged)="onActivityAssigneeChanged($event)"
        ></app-right-pane>
    </div>
</div>
<router-outlet></router-outlet>
