<div class="d-flex gap-2 p-2 align-items-center" *ngFor="let note of notes">
  <span
    class="w-40 user-inital-avatar circle"
    data-container="body"
    placement="top"
    [ngClass]="note.created_by.color"
    >{{ note.created_by.first_initial }}
  </span>
  <div>
    <div>{{ note.note }}</div>
    <div>
      <small class="text-muted"
        ><strong>{{ note.created_by.name }}</strong> -
        {{ formatNoteCreatedAtDate(note.created_at) }}
      </small>
    </div>
  </div>
</div>

<div class="d-flex gap-2 p-2 align-items-center">
  <span
    class="w-40 user-inital-avatar circle"
    data-container="body"
    placement="top"
    [ngClass]="currentUser.color"
    >{{ currentUser.first_initial }}
  </span>

  <div *ngIf="!inNoteEditMode">
    <div class="form-label-group" class="flex-grow-1">
      <input
        type="text"
        placeholder="Add Note..."
        class="form-control"
        (click)="onChangeMode()"
      />
    </div>
  </div>
  <div *ngIf="inNoteEditMode" class="flex-grow-1">
    <form
      [formGroup]="noteForm"
      (ngSubmit)="onSaveNote()"
      class="animated fadeIn form-wrapper mt4"
    >
      <div class="form-label-group">
        <textarea
          class="form-control"
          formControlName="note"
          name="note"
          id="note"
          rows="6"
          placeholder="Add Note..."
          autofocus
        ></textarea>
        <div class="btn-tools-wrapper float-end">
          <button
            class="btn btn-primary"
            [disabled]="isSavingNote"
            [ngClass]="{ disabled: isSavingNote }"
          >
            <fa-icon *ngIf="!isSavingNote" icon="check"></fa-icon>
            <fa-icon
              *ngIf="isSavingNote"
              icon="spinner"
              [spin]="true"
            ></fa-icon>
          </button>
          <button
            class="btn btn-light"
            [disabled]="isSavingNote"
            (click)="onChangeMode()"
          >
            <fa-icon icon="times"></fa-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
