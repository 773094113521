<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{
      this.modalOptions.customField?.id === undefined
        ? "Add New Custom Field"
        : "Edit Custom Field"
    }}
  </h4>
  <button
    type="button"
    class="btn btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<ng-template #customFieldIcon let-type="type">
  <svg class="sv-svg-icon size-24 opacity-50" [ngSwitch]="type">
    <use *ngSwitchCase="'text'" xlink:href="#icon-text"></use>
    <use *ngSwitchCase="'number'" xlink:href="#icon-text"></use>
    <use *ngSwitchCase="'date'" xlink:href="#icon-datepicker"></use>
    <use *ngSwitchCase="'select'" xlink:href="#icon-dropdown"></use>
    <use *ngSwitchCase="'check_box'" xlink:href="#icon-checkbox"></use>
    <use *ngSwitchCase="'rank_list'" xlink:href="#icon-ranking"></use>
    <use *ngSwitchCase="'radio_button'" xlink:href="#icon-radiogroup"></use>
    <use *ngSwitchCase="'textarea'" xlink:href="#icon-comment"></use>
  </svg>
</ng-template>
<form
  [formGroup]="customFieldForm"
  (ngSubmit)="onSubmit()"
  class="animated fadeIn"
>
  <div class="modal-body custom-field-body">
    <div class="row">
      <div class="col-12 animated fadeIn">
        <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
          {{ errorMsg }}
        </div>
        <div class="form-label-group">
          <input
            type="formName"
            class="form-control"
            formControlName="name"
            [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"
            placeholder="Field Name"
            required
            autofocus
          />
          <label>Field Name</label>
          <div
            *ngIf="submitted && form['name'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="form['name'].errors['required']">
              Field Name is required
            </div>
          </div>
        </div>
        <div class="form-label-group">
          <input
            type="formName"
            class="form-control"
            formControlName="placeholder"
            placeholder="Placeholder"
          />
          <!-- <small class="optional-text ml-2">Optional</small> -->
          <label>Placeholder Text (Optional)</label>
        </div>
        <div class="form-label-group">
          <textarea
            class="form-control"
            formControlName="help_text"
            name="help_text"
            rows="2"
            placeholder="Add help text...(Optional)"
            autofocus
          ></textarea>
          <!-- <small class="optional-text ml-2">Optional</small> -->
        </div>
        <div class="form-label-group">
          <div ngbDropdown container="body" class="form-control form-select">
            <div
              ngbDropdownToggle
              class="no-arrow"
              [ngClass]="{
                'is-invalid': submitted && form['field_type'].errors
              }"
            >
              <ng-container
                *ngTemplateOutlet="
                  customFieldIcon;
                  context: { type: form['field_type'].value }
                "
              ></ng-container>
              {{ getDisplayName(form["field_type"].value) }}
            </div>
            <div ngbDropdownMenu class="mt-1" style="min-width: 350px">
              <button
                ngbDropdownItem
                *ngFor="let fieldType of customFieldTypes"
                (click)="onSelectFieldType(fieldType.value)"
                class="btn d-flex align-items-center gap-2"
              >
                <ng-container
                  *ngTemplateOutlet="
                    customFieldIcon;
                    context: { type: fieldType.value }
                  "
                ></ng-container>
                {{ fieldType.displayName }}
              </button>
            </div>
          </div>
          <div class="mt-2" *ngIf="isSelectType(form['field_type'].value)">
            <!-- [items]="newCustomFieldForm['controls']['selections']['controls']" -->
            <ngx-sortable
              [items]="$any(selections)"
              [listStyle]="{ height: 'auto' }"
              [showHeader]="false"
              (listSorted)="listSorted($event)"
            >
              <ng-template let-item="item">
                <div
                  [formGroup]="item"
                  [ngClass]="{
                    'is-invalid': submitted && item.controls['value'].errors
                  }"
                  class="d-flex gap-2 align-items-center pe-2 ps-0 py-1"
                >
                  <div>
                    <fa-icon icon="grip-vertical" class="opacity-50"></fa-icon>
                  </div>
                  <div class="form-label-group m-0 flex-grow-1">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="value"
                      [ngClass]="{
                        'is-invalid': submitted && item.controls['value'].errors
                      }"
                      placeholder="Add New Option"
                    />
                    <label for="newOption">Option</label>

                    <div class="position-absolute end-0 top-0 remove-option">
                      <button
                        class="btn btn-light text-dark"
                        (click)="removeOption(item)"
                      >
                        <fa-icon icon="close"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="submitted && item.controls['value'].errors"
                  class="invalid-feedback ps-3"
                >
                  <div *ngIf="item.controls['value'].errors['required']">
                    Option name is required
                  </div>
                </div>
              </ng-template>
            </ngx-sortable>
            <button
              type="button"
              class="btn btn-light text-dark m-2"
              (click)="addOptionToCustomField()"
            >
              <fa-icon icon="plus" class="me-1"></fa-icon> Add Option
            </button>
          </div>
          <div
            *ngIf="submitted && form['field_type'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="form['field_type']['field_type'].required">
              Field Type
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="activeModal.dismiss('Cancel')"
      class="btn btn-light text-dark"
      type="button"
    >
      Cancel
    </button>
    <button class="btn btn-primary" type="submit">
      <!-- [disabled]="isSavingNewField"
      [ngClass]="{ disabled: isSavingNewField }" -->
      <!-- <fa-icon *ngIf="isSavingNewField" icon="spinner" [spin]="true"></fa-icon> -->
      {{
        this.modalOptions.customField?.id === undefined
          ? "Add New Field"
          : "Save Changes"
      }}
    </button>
  </div>
</form>
