<app-header></app-header>
<div
        *ngIf="route.children.length === 0"
        class="animated fadeIn eps-container container-fluid mb-2"
>
    <!-- #region Breadcrumb -->
    <nav aria-label="breadcrumb" *ngIf="!isCaseLoading && case">
        <ol class="breadcrumb my-2">
            <li class="breadcrumb-item">
                <a
                        [routerLink]="['/cases']"
                        [queryParams]="{ case_defn_id: case.case_definition.id }"
                >
                    {{ case.case_definition.name }}</a
                >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{ case.name }}
            </li>
        </ol>
    </nav>

    <!-- endregion -->
    <app-animated-loader *ngIf="isCaseLoading"></app-animated-loader>

    <div
            *ngIf="!isCaseLoading && !surveyViewMode"
            class="case-container overflow-auto gap-3"
    >
        <!-- #region Left Column -->

        <div class="card overflow-auto">
            <div title="Case Key" class="case-key card-header">
                <fa-icon icon="list" class="me-1"></fa-icon>
                Case ID: {{ case.key }}
            </div>
            <div class="card-body vstack gap-3 overflow-auto m-1">
                <app-edit-textarea
                        type="text"
                        [control]="name"
                        [errorMessages]="errorMessages.name"
                        (save)="updateField({ name: $event })"
                        label="Case Name"
                        placeholder="Case Name"
                        tooltip="Edit case name"
                        inputClass="capitalize-input fw-bold "
                ></app-edit-textarea>

                <div
                        class="btn-group btn-group-sm d-block"
                        role="group"
                        aria-label="Add Activity and Document Buttons"
                >
                    <button
                            type="button"
                            class="btn btn-secondary px-4"
                            (click)="openNewActivityDialog()"
                    >
                        Add Activity
                    </button>
                    <button
                            type="button"
                            class="btn btn-secondary px-4"
                            (click)="openFileUploadModal(null, 'attachment')"
                    >
                        Add Document
                    </button>
                </div>

                <app-edit-textarea
                        [control]="description"
                        (save)="updateField({ description: $event })"
                        label="Description"
                        tooltip="Edit case description"
                        placeholder="Add a description..."
                ></app-edit-textarea>

                <!-- ACTIVITIES-->
                <div *ngIf="case.activities && case.activities.length > 0">
                    <h5>Activities</h5>
                    <app-activity-list-item
                            *ngFor="let activity of case.activities"
                            [activity]="activity"
                            (remove)="onDeleteActivity($event.id)"
                    ></app-activity-list-item>
                </div>

                <!-- #region Surveys -->

                <div *ngIf="case.surveys && case.surveys.length > 0">
                    <h5>Forms</h5>
                    <ul class="list-group list-group-flush">
                        <app-form-list-item
                                *ngFor="let survey of case.surveys"
                                [survey]="survey"
                        ></app-form-list-item>
                    </ul>
                </div>

                <!-- endregion Surveys -->

                <!-- DOCUMENTS START -->
                <div *ngIf="case.documents && case.documents.length > 0">
                    <h5>Documents</h5>
                    <!--<div *hasPerm="'UPDATE_CASE'" class="col-6">
                            <button
                              *ngIf="!caseHasDocuments()"
                              class="float-end btn btn-primary"
                              (click)="openFileUploadModal(null, 'attachment')"
                            >
                              + Add Document
                            </button>
                          </div>-->

                    <div *ngIf="!caseHasDocuments()" class="case-surveys mt-3">
                        <app-document-list-item
                                *ngFor="let doc of case.documents; let i = index"
                                [document]="doc"
                                (upload)="openFileUploadModal($event.id, 'document')"
                                (download)="downloadCaseDocument($event.id)"
                                (delete)="deleteCaseDocument($event.id)"
                        ></app-document-list-item>
                    </div>
                    <div class="mt-3" *ngIf="caseHasDocuments()">
                        <div *hasPerm="'UPDATE_CASE'" class="empty-attachments">
                            <div class="attachment-text-wrapper">
                                <p>Attach files to case...</p>
                                <button
                                        class="btn btn-primary"
                                        (click)="openFileUploadModal(null, 'attachment')"
                                >
                                    + Add Attachment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- DOCUMENTS END -->

                <!-- #region Notes/History -->
                <div>
                    <ul
                            ngbNav
                            #nav="ngbNav"
                            [(activeId)]="activeTab"
                            class="position-relative"
                            id="notesHistoryTab"
                            role="tablist"
                            appActiveTab
                    >
                        <li ngbNavItem="notes">
                            <a ngbNavLink>Notes</a>
                            <ng-template ngbNavContent class="fade">
                                <app-notes
                                        [notes]="case.notes"
                                        (noteAdded)="onCaseNoteAdded($event)"
                                ></app-notes>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem="history">
                            <a ngbNavLink>History</a>
                            <ng-template ngbNavContent class="fade">
                                <app-history
                                        [history]="case.history"
                                        history-on="Case"
                                ></app-history>
                            </ng-template>
                        </li>
                        <div
                                class="active-tab-border"
                                style="position: absolute; bottom: 0"
                        ></div>
                    </ul>
                </div>

                <div [ngbNavOutlet]="nav"></div>
                <!-- endregion Notes/History -->
            </div>
        </div>

        <!-- endregion Left Column -->

        <!-- #region Right Column -->

        <div class="card h-content">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 float-end">
                        <div
                                *hasPerm="'DELETE_CASE'"
                                title="Actions"
                                ngbDropdown
                                container="body"
                                class="float-end d-inline-block"
                        >
                            <button
                                    class="btn btn-light no-arrow"
                                    id="dropdownBasic1"
                                    ngbDropdownToggle
                            >
                                <fa-icon icon="ellipsis-h"></fa-icon>
                            </button>
                            <div
                                    ngbDropdownMenu
                                    aria-labelledby="dropdownBasic1"
                                    class="animate-dropdown"
                            >
                                <div>
                                    <button (click)="openDeleteDialogPrompt()" ngbDropdownItem>
                                        <fa-icon class="empty-data-icon" icon="trash"></fa-icon>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <!-- #region Status -->
                        <strong *hasPerm="'UPDATE_CASE'">Status</strong>
                        <div
                                *hasPerm="'UPDATE_CASE'"
                                ngbDropdown
                                class="mt-1"
                                container="body"
                        >
                            <div class="btn ps-0" ngbDropdownToggle>
                                <app-status-badge [status]="case.status"></app-status-badge>
                            </div>

                            <div
                                    ngbDropdownMenu
                                    aria-labelledby="dropdownBasic1"
                                    class="animate-dropdown"
                            >
                                <div *ngFor="let status of statuses">
                                    <button (click)="onUpdateStatus(status)" ngbDropdownItem>
                                        <!-- {{ status.name }} -->
                                        <app-status-badge [status]="status"></app-status-badge>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- endregion -->
                    </div>

                    <div class="col-4">
                        <!-- ASSIGNED TO START -->
                        <strong>Assignee</strong>
                        <div
                                class="mt-1"
                                (click)="onEditAssignee()"
                                *ngIf="!editingAssignee"
                        >
                            <app-eps-avatar
                                    [username]="assignedTo.username"
                                    [color]="assignedTo.color"
                            ></app-eps-avatar>
                            {{ assignedTo.name }}
                        </div>
                        <div *ngIf="editingAssignee" class="mt-1">
                            <div
                                    *hasPerm="'UPDATE_CASE'"
                                    ngbDropdown
                                    [open]="true"
                            >
                                <button
                                        class="btn btn-outline-primary"
                                        id="assigneeDropDown"
                                        ngbDropdownToggle
                                >
                                    {{ assignedTo.name }}
                                </button>
                                <div
                                        ngbDropdownMenu
                                        aria-labelledby="assigneeDropDown"
                                        class="animate-dropdown"
                                >
                                    <div *ngFor="let user of assignableUsers">
                                        <button (click)="onChangeAssignee(user)" ngbDropdownItem>
                                            {{ user.name || user.username }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ASSIGNED TO END -->
                    </div>

                    <div class="col-4">
                        <!-- CREATOR START -->
                        <strong>Creator</strong>
                        <div class="mt-1">
                            <app-eps-avatar
                                    [username]="case.created_by.username"
                                    [color]="case.created_by.color"
                            ></app-eps-avatar>
                            {{ case.created_by.name }}
                        </div>
                        <!-- CREATOR END -->
                    </div>
                </div>

                <!-- #region Custom Fields -->
                <div *ngIf="caseHasCustomFields()" class="row mt-3 mb-2">
                    <div class="col-12 case-details-wrapper">
                        <div>
                            <div>
                                <render-custom-fields
                                        [customFieldsData]="case.custom_fields"
                                        [containerId]="caseId"
                                        [containerType]="customFieldContainerType"
                                ></render-custom-fields>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- endregion Custom Fields -->
            </div>
        </div>

        <!-- endregion Right Column -->
    </div>
</div>
<router-outlet></router-outlet>
