import { Component, OnInit } from "@angular/core";
import { Utils } from "../../_helpers";
import * as _ from "lodash";
// import { AddUserModalComponent } from '../../_components/add-user-modal/add-user-modal.component';
// import { UserService, AuthenticationService, AdminService } from '../../_services';
import { AdminService } from "../../_services";
// import { ChangePasswordModalComponent } from '../../_components/change-password-modal/change-password-modal-component';
import {
  NgbModal,
  NgbModalOptions,
  // ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
// import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DialogOptions, User } from "../../_models/index";
import { DialogComponent } from "app/_components";

@Component({
  selector: "dashboard-skeleton",
  templateUrl: "./dashboard-skeleton.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardSkeletonComponent {
  skeletonItems: Array<any>;

  constructor() {
    this.skeletonItems = Array.from(
      { length: Math.floor(Math.random() * 10) },
      () => Math.floor(Math.random() * 40)
    );
  }
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  primaryDialogOptions: NgbModalOptions = {
    backdrop: "static",
  };

  constructor(
    private modalService: NgbModal,
    private adminService: AdminService,
    private utils: Utils
  ) {}

  ngOnInit() {}

  public openReportDialogPrompt(): void {
    const dialogOptions: DialogOptions = {
      headerText: "Rebuild Reporting Database",
      bodyText:
        "Are you sure you want to to rebuild the reporting database and re-synch Metabase?",
      primaryActionText: "Yes, Rebuild",
      cancelBtnText: "Cancel",
      btnClass: "success",
      saveChanges: false,
    };

    const dialog = this.modalService.open(
      DialogComponent,
      this.primaryDialogOptions
    );
    dialog.componentInstance.dialogOptions = dialogOptions;
    dialog.componentInstance.passEntry.subscribe((action: boolean) => {
      if (action) {
        this.resetReporting();
      }
    });
  }

  public resetReporting(): void {
    this.adminService.resetReporting().subscribe(
      () => {
        this.utils.generateSuccessToastrMsg(
          "Successfully rebuilt the reporting database",
          ""
        );
      },
      (error) => {
        console.log(error.message);
      }
    );
  }
}
