<app-header pageLocation="landing"></app-header>
<div class="eps-container">
    <div class="container">
        <div class="row text-center pb-lg-5">
            <h1 class="display-4">Welcome to OASIS</h1>
        </div>
        <div class="align-items-stretch d-flex gap-3 justify-content-center row">
            <div class="col-3">
                <div class="card" [routerLink]="['/cases']"
                     [queryParams]="{ case_defn_id: 3 }">
                    <div class="card-body text-center">
                        <h1 class="display-4">Data Collection</h1>
                        <fa-icon class="dash-icon" icon="magnifying-glass-plus"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" [routerLink]="['/ilna-dashboard/2']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Data Tracking</h1>
                        <fa-icon class="dash-icon" icon="list-check"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" [routerLink]="['/data-reporting-dashboards']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Data Reporting</h1>
                        <fa-icon class="dash-icon" icon="chart-simple"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
