<app-header></app-header>
<div class="eps-container">
    <!-- #region Breadcrumb -->
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb my-2">
                    <li class="breadcrumb-item active" aria-current="page">
                        Admin Dashboard
                    </li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- endregion -->
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" [routerLink]="['/admin/users']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Users</h1>
                        <fa-icon class="dash-icon" icon="users"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" [routerLink]="['/admin/roles']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Roles</h1>
                        <fa-icon class="dash-icon" icon="user-secret"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" [routerLink]="['/cases-definitions']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Cases</h1>
                        <fa-icon class="dash-icon" icon="briefcase"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" [routerLink]="['/admin/project']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Project</h1>
                        <fa-icon class="dash-icon" icon="tasks"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <div class="card" [routerLink]="['/forms']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Forms</h1>
                        <fa-icon class="dash-icon" icon="file-lines"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" [routerLink]="['/admin/default-dashboard']">
                    <div class="card-body text-center">
                        <h1 class="display-4">Default</h1>
                        <fa-icon class="dash-icon" icon="dashboard"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card" (click)="openReportDialogPrompt()">
                    <div class="card-body text-center">
                        <h1 class="display-4">Rebuild</h1>
                        <fa-icon class="dash-icon" icon="refresh"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-3"></div>
        </div>
    </div>
</div>

<!--
    <h6 class="dropdown-header">Administrator</h6>
                <a class="dropdown-item" routerLink="/admin/users">Users</a>
                <a class="dropdown-item" routerLink="/admin/roles">Roles</a>
                <a class="dropdown-item" routerLink="/cases-definitions"
                  >Case Types</a
                >
                <a class="dropdown-item" routerLink="/admin/project"
                  >Project Information</a
                >

                <a class="dropdown-item" routerLink="/admin/default-dashboard"
                  >Set Default Dashboard</a
                >
                <a class="dropdown-item" (click)="openReportDialogPrompt()"
                  >Rebuild Reporting DB</a
                >
                <div class="dropdown-divider"></div>
  -->
