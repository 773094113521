import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import {
  NgbActiveModal,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { PostCampaign } from "app/_models";

@Component({
  selector: "app-new-campaign-modal",
  templateUrl: "./new-campaign-modal.component.html",
  styleUrls: ["./new-campaign-modal.component.scss"],
})
export class NewCampaignModalComponent implements OnInit {
  @Input() survey: { id: number; name: string };
  @Output() passEntry: EventEmitter<PostCampaign> =
    new EventEmitter<PostCampaign>();
  isSavingData: boolean;
  newCampaignForm: FormGroup;
  isSubmitted = false;
  isLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.newCampaignForm = this.formBuilder.group({
      campaignName: ["", [Validators.required]],
      campaignDescription: ["", []],
      campaignStartDate: ["", [Validators.required]],
      campaignEndDate: ["", [Validators.required]],
    });
  }

  ngOnInit() {}

  get form() {
    return this.newCampaignForm.controls;
  }

  public submit(): void {
    this.isSavingData = true;
    this.isSubmitted = true;
    // console.log(
    //     `campaign name: ${JSON.stringify(
    //         this.newCampaignForm.controls['campaignName'].value
    //     )}`
    // );
    // console.log(
    //     `campaign description: ${JSON.stringify(
    //         this.newCampaignForm.controls['campaignDescription'].value
    //     )}`
    // );
    // console.log(
    //     `start date: ${JSON.stringify(
    //         this.newCampaignForm.controls['campaignStartDate'].value
    //     )}`
    //  );
    //  console.log(
    //      `end date: ${JSON.stringify(
    //          this.newCampaignForm.controls['campaignEndDate'].value
    //      )}`
    //  );
    if (this.newCampaignForm.invalid) {
      this.isSavingData = false;
    } else {
      const name: string = this.newCampaignForm.controls["campaignName"].value;
      const description: string =
        this.newCampaignForm.controls["campaignDescription"].value;
      const startDate: { year: number; month: number; day: number } =
        this.newCampaignForm.controls["campaignStartDate"].value;
      const endDate: { year: number; month: number; day: number } =
        this.newCampaignForm.controls["campaignEndDate"].value;
      const startDateObject = new Date(
        startDate.year,
        startDate.month - 1,
        startDate.day
      );
      const endDateObject = new Date(
        endDate.year,
        endDate.month - 1,
        endDate.day
      );

      const newCampaignData: PostCampaign = {
        survey_id: this.survey.id,
        name: name,
        description: description,
        start_date: startDateObject.toISOString(),
        end_date: endDateObject.toISOString(),
      };
      // console.log(`campaign data = ${JSON.stringify(newCampaignData)}`);
      this.passEntry.emit(newCampaignData);
      this.activeModal.close(newCampaignData);
    }
  }
}
