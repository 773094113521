import {Component} from '@angular/core';

@Component({
    selector: 'app-data-reporting-dashboards',
    templateUrl: './data-reporting-dashboards.component.html',
    styleUrls: ['./data-reporting-dashboards.component.scss']
})
export class DataReportingDashboardsComponent {

}
