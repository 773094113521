<div class="d-flex gap-2 align-items-center">
  <fa-icon
    class="li-icon icon-md flex-grow-0"
    [icon]="pending ? 'circle-notch' : 'list-check'"
  ></fa-icon>
  <a
    container="body"
    ngbTooltip="View activity"
    [routerLink]="['/activities', activity.id]"
    class="fw-semibold text-body btn-link"
  >
    {{ activity.name }}</a
  >
  <fa-icon
    icon="check"
    [ngStyle]="{
      color: activity.is_complete ? 'green' : 'gray'
    }"
  ></fa-icon>

  <span *ngIf="activity.is_complete" class="text-muted small"
    >Completed on {{ activity.completed_at | date : "MM/dd/yyyy" }} by
    {{ activity.completed_by.name || activity.completed_by.username }}
  </span>
</div>

<div class="actions mr-1">
  <fa-icon
    *hasPerm="'DELETE_ACTIVITY'"
    (click)="onRemove()"
    container="body"
    ngbTooltip="Delete activity"
    class="li-icon action delete"
    icon="trash"
  ></fa-icon>
</div>
