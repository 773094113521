<div class="d-flex gap-2 align-items-center">
  <fa-icon class="li-icon icon-md flex-grow-0" icon="clipboard"></fa-icon>
  <a
    [routerLink]="['forms', survey.id]"
    class="list-group-item list-group-item-action"
    ngbTooltip="Click to open Form"
    class="fw-semibold text-body btn-link"
    >{{ survey.name }}</a
  >
  <a
    [routerLink]="['form-responses', survey.id]"
    class="ms-auto position-relative"
    container="body"
    placement="top"
    ngbTooltip="Click to view responses"
  >
    <fa-icon class="li-icon action primary" icon="list-alt"></fa-icon>
    <div
      class="badge text-black bg-warning end-0 p-1 position-absolute top-0 border border-light translate-middle-y small"
    >
      {{ survey.responses_count || 0 }}
    </div></a
  >
</div>
