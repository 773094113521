<div class="animated fadeIn eps-container">
    <div class="row">
        <div class="col-12">
            <div class="card cases-card">
                <div class="card-body" style="text-align: center;">
                    <p>
                        You do not have sufficient permissions to perform that action. Please message your
                        system administrator and login again.
                    </p>
                    <a class="btn btn-primary" href="/login" role="button">Login</a>
                </div>
            </div>
        </div>
    </div>
</div>
