<app-header></app-header>
<div class="container-fluid animated fadeIn eps-container">
  <!--  class="card"  class="card-body"-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2" *ngIf="displayBreadcrumb">
      <li
        class="breadcrumb-item"
        *ngIf="
          responseSourceType === ResponseSourceType.Case ||
          responseSourceType === ResponseSourceType.Activity
        "
      >
        <a
          *ngIf="gotActivity"
          [routerLink]="['/cases']"
          [queryParams]="{ case_defn_id: getCaseDefinitionId() }"
        >
          {{
            responseSourceType === ResponseSourceType.Activity
              ? activity.case.definition.name
              : case.case_definition.name
          }}
        </a>
      </li>
      <li
        class="breadcrumb-item"
        *ngIf="
          responseSourceType === ResponseSourceType.Case ||
          responseSourceType === ResponseSourceType.Activity
        "
      >
        <a
          *ngIf="gotActivity"
          [routerLink]="[
            '/cases',
            responseSourceType === ResponseSourceType.Activity
              ? activity.case.definition.id
              : case.id
          ]"
        >
          {{
            responseSourceType === ResponseSourceType.Activity
              ? activity.case.name
              : case.name
          }}
        </a>
      </li>
      <li
        class="breadcrumb-item"
        aria-current="page"
        *ngIf="responseSourceType === ResponseSourceType.Activity"
      >
        Activities
      </li>
      <li
        class="breadcrumb-item"
        *ngIf="responseSourceType === ResponseSourceType.Activity"
      >
        <a *ngIf="gotActivity" [routerLink]="['/activities', activity.id]">
          {{ activity.name }}
        </a>
      </li>
      <li
        class="breadcrumb-item"
        aria-current="page"
        *ngIf="responseSourceType === ResponseSourceType.Unknown"
      >
        Data Collection Forms
      </li>
      <li
        class="breadcrumb-item"
        aria-current="page"
        *ngIf="
          responseSourceType === ResponseSourceType.Case ||
          responseSourceType === ResponseSourceType.Activity
        "
      >
        Forms
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ survey.name }}
      </li>
      <li class="breadcrumb-item active" aria-current="page">Responses</li>
    </ol>
  </nav>
  <div class="card form-response-full-height">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <div>
            <div>
              <div class="mb-4 row">
                <div class="col-6">
                  <h3 class="response-header">Responses</h3>
                </div>
                <div class="col-6">
                  <span class="float-end"
                    ><a
                      [href]="fileUrl"
                      class="btn btn-outline-primary"
                      data-container="body"
                      placement="bottom"
                      ngbTooltip="Download Responses"
                      title="Download Responses"
                      [download]="fileName"
                    >
                      <fa-icon
                        class="empty-data-icon"
                        icon="download"
                      ></fa-icon>
                      Download
                    </a>
                  </span>
                </div>
              </div>

              <table class="animated fadeIn table">
                <thead class="">
                  <th class="align-middle px-2">ID</th>
                  <th class="align-middle px-2">Creation Date</th>
                  <th class="align-middle px-2">Last Updated Date</th>
                  <th class="align-middle px-2">Created By</th>
                  <th class="align-middle px-2">Last Updated By</th>
                  <th class="align-middle"></th>
                </thead>
                <tbody>
                  <tr
                    (click)="showResponse(selection, response.id)"
                    [class.selected]="currSelectedResponse === selection"
                    *ngFor="
                      let response of surveyResponse;
                      let selection = index
                    "
                  >
                    <td class="align-middle">{{ response.id }}</td>
                    <td class="align-middle">
                      {{ response.created_at + "Z" | date: "short" }}
                    </td>
                    <td class="align-middle">
                      {{ response.updated_at + "Z" | date: "short" }}
                    </td>
                    <td class="align-middle">
                      {{
                        response.created_by.name || response.created_by.username
                      }}
                    </td>
                    <td class="align-middle">
                      {{
                        response.updated_by.name || response.updated_by.username
                      }}
                    </td>
                    <td>
                      <div class="actions">
                        <ng-container *ngFor="let action of actions">
                          <button
                            *hasPerm="action.permission"
                            class="action {{ action.class }}"
                            [ngbTooltip]="action.name"
                            [disabled]="action.disabled(response)"
                            container="body"
                            (click)="
                              action.action(response); $event.stopPropagation()
                            "
                          >
                            <fa-icon [icon]="action.icon"></fa-icon>
                          </button>
                        </ng-container>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="animated fadeIn" *ngIf="showSurvey">
            <button
              class="close-btn float-end btn btn-link mb-3"
              (click)="closeResponse()"
            >
              Close
            </button>
            <survey-component
              [response]="response"
              [json]="json"
              (submitSurvey)="updateSurveyResponse($event)"
            ></survey-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
