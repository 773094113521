<app-header></app-header>
<div class="eps-container container-fluid">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Admin Dashboard</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Project</li>
    </ol>
  </nav>

  <div class="card" *ngIf="isProjectLoaded; else skeleton">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <h3>Project Details</h3>
        <button
          (click)="setEditProjectMode()"
          class="btn btn-primary mb-2 ms-auto"
        >
          Edit Project Details
        </button>
      </div>
      <div *ngIf="!editProjectMode">
        <div class="profile-information-content p3">
          <h6 class="d-block">
            <strong>Name:</strong>
            <span class="ml-2 profile-data">{{ project.name }}</span>
          </h6>
          <hr />
          <h6 class="d-block">
            <strong>Title:</strong>
            <span class="ml-2 profile-data">{{ project.title }}</span>
          </h6>
          <hr />
          <h6 class="d-block">
            <strong>Organization:</strong>
            <span class="ml-2 profile-data">{{ project.organization }}</span>
          </h6>
          <hr />
          <h6 class="d-block">
            <strong>Agreement Number:</strong>
            <span class="ml-2 profile-data">{{
              project.agreement_number
            }}</span>
          </h6>
          <hr />
          <h6 class="d-block">
            <strong>Start Date:</strong>
            <span class="ml-2 profile-data">{{
              formatDate(project.start_date)
            }}</span>
          </h6>
          <hr />
          <h6 class="d-block">
            <strong>End Date:</strong>
            <span class="ml-2 profile-data">{{
              formatDate(project.end_date)
            }}</span>
          </h6>
          <hr />
          <h6 class="d-block">
            <strong>Funding Amount:</strong>
            <span class="ml-2 profile-data">{{ project.funding_amount }}</span>
          </h6>
          <hr />
          <h6 class="d-block">
            <strong>Location:</strong>
            <span class="ml-2 profile-data">{{ project.location }}</span>
          </h6>
        </div>
      </div>
      <div *ngIf="editProjectMode">
        <div class="add-user-content">
          <form [formGroup]="projectForm" (submit)="save()">
            <div class="form-label-group">
              <input
                type="text"
                formControlName="name"
                class="form-control"
                placeholder="Name"
                required
                autofocus
                [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"
              />
              <label for="name">Name</label>
              <div
                *ngIf="submitted && form['name'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="form['name'].errors['required']">
                  Name is required
                </div>
              </div>
            </div>
            <div class="form-label-group">
              <input
                type="text"
                formControlName="title"
                class="form-control"
                placeholder="title"
              />
              <label for="title">Title</label>
            </div>
            <div class="form-label-group">
              <input
                type="text"
                formControlName="organization"
                class="form-control"
                placeholder="organization"
              />
              <label for="organization">Organization</label>
            </div>
            <div class="form-label-group">
              <input
                type="text"
                formControlName="agreement_number"
                class="form-control"
                placeholder="organization"
              />
              <label for="agreement_number">Agreement Number</label>
            </div>
            <div class="form-label-group">
              <input
                type="date"
                formControlName="start_date"
                class="form-control"
                placeholder="Start Date"
                [ngClass]="{
                  'is-invalid': submitted && form['start_date'].errors
                }"
              />
              <label for="start_date">Start Date</label>
              <div
                *ngIf="submitted && form['start_date'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="form['start_date'].errors['dateValidator']">
                  Start Date cannot be after End Date
                </div>
              </div>
            </div>
            <div class="form-label-group">
              <input
                type="date"
                formControlName="end_date"
                class="form-control"
                placeholder="End Date"
              />
              <label for="end_date">End Date</label>
            </div>
            <div class="form-label-group">
              <input
                type="text"
                formControlName="funding_amount"
                class="form-control"
                placeholder="Funding Amount"
              />
              <label for="funding_amount">Funding Amount</label>
            </div>
            <div class="form-label-group">
              <input
                type="text"
                formControlName="location"
                class="form-control"
                placeholder="location"
              />
              <label for="location">Location</label>
            </div>
            <div class="mt-4 float-end">
              <button
                (click)="setEditProjectMode()"
                [ngClass]="{ disabled: savingProjectData }"
                class="btn btn-link btn-sm"
                type="button"
              >
                Cancel
              </button>
              <button
                *ngIf="!savingProjectData"
                class="btn btn-lg btn-primary btn-sm"
                type="submit"
              >
                Save
              </button>
              <button
                *ngIf="savingProjectData"
                (click)="save()"
                class="btn btn-lg btn-primary btn-sm disabled"
                type="button"
              >
                <fa-icon
                  class="empty-data-icon"
                  icon="spinner"
                  [spin]="true"
                ></fa-icon>
                Saving...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #skeleton>
  <div class="container">Loading...</div>
</ng-template>
