import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {
    AuthenticationService,
    GeneralService,
    CaseService,
} from "../../_services";
import {Utils} from "../../_helpers";
import {Observable} from "rxjs";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    @Input() pageLocation: string = 'default';
    appTitle = "OASIS";
    currUser = this.authService.currentUserValue;
    isCurrentUserAnonymous = this.currUser.username === "anonymous";
    returnUrl: string;
    analyticsExternalURL: string;
    isLoggedIn$: Observable<boolean>;
    avatarFirstInitial = this.utils.generateFirstInitalFromUserName(
        this.currUser.username
    );
    userDataLoaded = false;
    isUserAdmin = false;
    adminPermissionValue: number = 1;
    displayNavLinks = false

    constructor(
        private router: Router,
        private utils: Utils,
        private authService: AuthenticationService,
        private generalService: GeneralService
    ) {
        this.isLoggedIn$ = this.authService.isLoggedIn;
    }

    ngOnInit() {
        this.getConfigs();
        this.isUserAdmin = (this.currUser.role.permissions & this.adminPermissionValue) === this.currUser.role.permissions;
        if (this.pageLocation === "landing") {
            this.displayNavLinks = false;
        } else {
            this.displayNavLinks = true;
        }
    }

    public generateDisplayEmail(): string {
        return this.currUser.email !== "" ? `${this.currUser.email}` : "My Account";
    }

    private getConfigs(): void {
        this.generalService.getConfigs().subscribe(
            (data) => {
                this.analyticsExternalURL = data.metabase_url;
                this.userDataLoaded = true;
            },
            (error) => {
                console.log(error.message);
            }
        );
    }

    public logOut(): void {
        this.authService.logout();
        this.returnUrl = `login`;
        this.router.navigate([this.returnUrl]);
    }
}
