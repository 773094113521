import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomFieldContainerType } from "app/_components/_custom-fields/render-custom-fields/render-custom-fields.component";
import { Utils } from "app/_helpers";
import { CustomField, User } from "app/_models";
import { UserService } from "app/_services";

@Component({
  selector: "app-right-pane",
  templateUrl: "./right-pane.component.html",
  styleUrls: ["./right-pane.component.scss"],
})
export class RightPaneComponent implements OnInit {
  @Input() activityID: number;
  @Input() createdBy: {
    email: string;
    id: number;
    name: string;
    username: string;
    color: string;
  };
  @Input() assignedTo: {
    email: string;
    id: number;
    name: string;
    username: string;
    color: string;
  };
  @Input() customFields: CustomField[] = [];
  @Input() isComplete: boolean;
  @Output() isCompleteChanged = new EventEmitter<boolean>();
  @Output() assigneeChanged = new EventEmitter<number>();
  assignableUsers: User[];
  assignee: { name: string; username: string; color: string } = {
    name: "Unassigned",
    username: "unassigned",
    color: "grey",
  };
  editingAssignee = false;
  customFieldContainerType: CustomFieldContainerType =
    CustomFieldContainerType.Activity;

  constructor(private userService: UserService, private utils: Utils) {}

  ngOnInit() {
    this.getAssignableUsers();
    if (this.assignedTo) {
      this.assignee = {
        name: this.assignedTo.name,
        username: this.assignedTo.username,
        color: this.assignedTo.color,
      };
    } else {
      this.assignee = {
        name: "Unassigned",
        username: "unassigned",
        color: "grey",
      };
    }
  }

  onValueChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.isComplete = isChecked;
    this.isCompleteChanged.emit(this.isComplete);
  }

  public onEditAssignee(): void {
    this.editingAssignee = true;
  }

  public onChangeAssignee(user: User): void {
    this.assignee = {
      name: user.name,
      username: user.username,
      color: user.color,
    };
    this.editingAssignee = false;

    // need to update assignee in three cases
    // 1. The activity was unassigned and is being assigned
    // 2. The activity was assigned and is not being assigned to a different user
    // 3. The activity was assigned and is being unassigned
    if (
      (!this.assignedTo && user.id !== 0) ||
      (this.assignedTo && user.id !== this.assignedTo.id) ||
      (this.assignedTo && user.id === 0)
    ) {
      console.log("Assignee changed");
      let updateUserId: number = null;
      if (user.id !== 0) {
        updateUserId = user.id;
      }

      this.assigneeChanged.emit(updateUserId);
    }
  }

  public onCancelAllEdits(): void {
    this.editingAssignee = false;
  }

  public getAssignableUsers(): void {
    this.userService.getAllUsers().subscribe(
      (data) => {
        this.assignableUsers = data.filter((user) =>
          this.userService.isUserAssignableToActivity(user)
        );
        this.assignableUsers.sort((userA, userB) => (userA.name > userB.name) ? 1 : ((userB.name > userA.name) ? -1 : 0));
        this.assignableUsers.push({
          id: 0,
          name: "Unassigned",
          username: "unassigned",
          color: "grey",
          email: null,
          role: null,
          location: null,
          last_seen_at: null,
          created_at: null,
          updated_at: null,
          confirmed: null,
          confirmed_at: null,
          firstInitial: "U",
          avatarBgColor: "grey",
        });
      },
      (error) => {
        console.log(error.message);
      }
    );
  }
}
