<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-3">
        <strong>Complete?</strong>
        <div class="form-check form-switch">
          <input
            type="checkbox"
            [checked]="isComplete"
            (change)="onValueChange($event)"
            class="form-check-input"
            id="isComplete"
          />
          <label class="form-check-label" for="isComplete"></label>
        </div>
      </div>

      <div class="col-4">
        <!-- ASSIGNED TO START -->
        <strong>Assignee</strong>
        <div class="mt-1" (click)="onEditAssignee()" *ngIf="!editingAssignee">
          <app-eps-avatar
            [username]="assignee.username"
            [color]="assignee.color"
          ></app-eps-avatar>
          {{ assignee.name }}
        </div>
        <div *ngIf="editingAssignee" class="mt-1">
          <div *hasPerm="'UPDATE_ACTIVITY'" ngbDropdown [open]="true">
            <button
              class="btn btn-outline-primary"
              id="assigneeDropDown"
              ngbDropdownToggle
            >
              {{ assignee.name }}
            </button>
            <div ngbDropdownMenu aria-labelledby="assigneeDropDown">
              <div *ngFor="let user of assignableUsers">
                <button (click)="onChangeAssignee(user)" ngbDropdownItem>
                  {{ user.name || user.username }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ASSIGNED TO END -->
      </div>

      <div class="col-4">
        <!-- CREATOR START -->
        <strong>Creator</strong>
        <div class="mt-1">
          <app-eps-avatar
            [username]="createdBy.username"
            [color]="createdBy.color"
          ></app-eps-avatar>
          {{ createdBy.name }}
        </div>
        <!-- CREATOR END -->
      </div>
    </div>

    <div *ngIf="customFields" class="row">
      <div class="col-12">
        <div>
          <div>
            <render-custom-fields
              [customFieldsData]="customFields"
              [containerId]="activityID"
              [containerType]="customFieldContainerType"
            ></render-custom-fields>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
