<div class="form-group">
  <label for="{{ fieldName }}" class="fw-bold form-label">{{
    field.name
  }}</label>
  <select
    class="form-control form-select"
    name="{{ field.name }}"
    [(ngModel)]="selectedObject"
    (change)="onValueChange()"
    id="{{ fieldName }}"
  >
    <option [ngValue]="opt" *ngFor="let opt of field.selections">
      {{ opt.value }}
    </option>
  </select>
  <small *ngIf="field.help_text" class="form-text text-muted"
    ><i class="fa fa-info-circle" aria-hidden="true"></i>
    {{ field.help_text }}
  </small>
</div>
