<div class="card">
  <div class="card-header case-key">
    <fa-icon icon="list" class="me-1"></fa-icon> Activity ID: {{ activity.key }}
  </div>
  <div class="card-body vstack gap-3 overflow-auto m-1">
    <app-edit-textarea
      type="text"
      [control]="name"
      [errorMessages]="errorMessages.name"
      (save)="onActivityNameChanged($event)"
      label="Name"
      placeholder="Name"
      tooltip="Edit activity name"
      inputClass="capitalize-input fw-bold"
    ></app-edit-textarea>

    <div
      class="btn-group btn-group-sm d-block"
      role="group"
      aria-label="Add Document Button"
    >
      <button
        type="button"
        class="btn btn-secondary px-4"
        (click)="openFileUploadModal(null, 'attachment')"
      >
        Add Document
      </button>
    </div>

    <app-edit-textarea
      [control]="description"
      (save)="onActivityDescriptionChanged($event)"
      label="Description"
      tooltip="Edit activity description"
      placeholder="Add a description..."
    ></app-edit-textarea>
    <!-- #region Surveys -->
    <div *ngIf="activity.surveys && activity.surveys.length > 0">
      <h5>Forms</h5>
      <div class="list-group list-group-flush">
        <app-form-list-item
          *ngFor="let survey of activity.surveys"
          [survey]="survey"
        ></app-form-list-item>
      </div>
    </div>
    <!-- endregion -->

    <!-- #region Documents -->

    <div *ngIf="activity.documents.length > 0">
      <h5>Documents</h5>
      <ul class="list-group list-group-flush">
        <app-document-list-item
          *ngFor="let doc of activity.documents"
          [document]="doc"
          (upload)="openFileUploadModal($event.document_id, 'document')"
          (download)="downloadDocument($event)"
          (delete)="deleteDocument($event.id)"
        ></app-document-list-item>
      </ul>
    </div>

    <!-- end display if the activity has documents -->

    <!-- endregion -->

    <!-- NOTES/HISTORY START -->
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="activeTab"
      class="nav nav-tabs"
      id="notesHistoryTab"
      role="tablist"
    >
      <li class="nav-item" ngbNavItem="notes">
        <a
          ngbNavLink
          class="nav-link"
          id="notes-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          >Notes</a
        >
        <ng-template ngbNavContent class="fade">
          <app-notes
            [notes]="activity.notes"
            (noteAdded)="onActivityNoteAdded($event)"
          ></app-notes>
        </ng-template>
      </li>
      <li class="nav-item" ngbNavItem="history">
        <a
          ngbNavLink
          class="nav-link"
          id="history-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
          >History</a
        >
        <ng-template
          ngbNavContent
          class="tab-pane fade"
          id="history"
          role="tabpanel"
          aria-labelledby="history-tab"
        >
          <div class="row">
            <div class="col-12 mt-3">
              <app-history
                [history]="activity.history"
                history-on="Activity"
              ></app-history>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
    <!-- NOTES/HISTORY END -->
  </div>
  <!-- TODO: Extract parts into components -->
</div>
