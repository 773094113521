<div *ngIf="isLoading" class="animated fadeIn eps-container">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-secondary text-center" role="alert">
                Verifying Campaign
            </div>
        </div>
    </div>
    <app-animated-loader></app-animated-loader>
</div>
<div *ngIf="!isLoading && wasSuccess" class="animated fadeIn eps-container">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-success" role="alert">
                <h4 class="alert-heading">
                Successful campaign verification.
                </h4>
                <p>Please follow the link below to go to your survey.</p>
                <a href="{{ surveyUrl }}">Survey</a>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isLoading && wasFailure" class="animated fadeIn eps-container">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">
                    Campaign verification error.
                </h4>
                <p>The following error occurred while trying to verify your campaign.</p>
                <p>{{ errorMessage }}</p>
            </div>
        </div>
    </div>
</div>
