<app-header></app-header>
<!-- <app-side-bar></app-side-bar> -->
<div class="container-fluid animated fadeIn eps-container mb-2">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Admin Dashboard</a>
      </li>
      <li class="breadcrumb-item">
        <a (click)="back()" [routerLink]="">Case Definitions</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
    </ol>
  </nav>
  <div class="users-container overflow-auto gap-3">
    <div *ngIf="isLoading">
      <app-animated-loader></app-animated-loader>
    </div>

    <form
      *ngIf="!isLoading"
      [formGroup]="newCaseDefinitonForm"
      (ngSubmit)="onSubmit()"
      class="card animated eps-container fadeIn w-100"
    >
      <div class="d-flex align-items-center card-body sticky-top bg-white">
        <h5 class="card-title m-0">
          {{ title }}
        </h5>
        <div
          *ngIf="isEditMode"
          title="Actions"
          ngbDropdown
          container="body"
          class="ms-auto"
        >
          <button
            type="button"
            class="btn btn-light no-arrow"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <fa-icon icon="ellipsis-h"></fa-icon>
          </button>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
            class="animate-dropdown"
          >
            <div>
              <button
                type="button"
                (click)="openDeleteDialogPrompt()"
                ngbDropdownItem
              >
                <fa-icon class="empty-data-icon" icon="trash"></fa-icon>
                Delete Type
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
          {{ errorMsg }}
        </div>
      </div>
      <div class="card-body overflow-auto py-1 me-1">
        <div class="form-label-group">
          <input
            type="text"
            id="inputCaseName"
            class="form-control capitalize-input"
            formControlName="name"
            [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"
            placeholder="Case Definition Name"
            (change)="
              newCaseDefinitonForm.patchValue({
                name: capitalizeInput($event)
              })
            "
            maxlength="35"
            required
            autofocus
          />
          <label for="inputCaseName">Name</label>
          <div
            *ngIf="submitted && form['name'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="form['name'].errors['required']">
              Case Definition Name is required
            </div>
            <div *ngIf="form['name'].errors['minlength']">
              Case Definition Name must contain 6 characters.
            </div>
          </div>
        </div>
        <div class="form-label-group">
          <input
            type="text"
            id="inputCaseKey"
            class="form-control uppercase-input"
            formControlName="caseKey"
            [ngClass]="{
              'is-invalid': submitted && form['caseKey'].errors
            }"
            placeholder="Case Definition Key"
            (change)="
              newCaseDefinitonForm.patchValue({
                caseKey: upperCaseInput($event)
              })
            "
            required
          />
          <label for="inputCaseName">Key</label>
          <div
            *ngIf="submitted && form['caseKey'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="form['caseKey'].errors['required']">
              Case Definition Key is required
            </div>
            <div *ngIf="form['caseKey'].errors['minlength']">
              Case Definition Key must contain 2 characters.
            </div>
          </div>
        </div>
        <div class="form-label-group">
          <input
            type="text"
            id="inputCaseDescription"
            class="form-control"
            formControlName="description"
            placeholder="Case Definition Description"
          />
          <label for="inputCaseDescription">Description</label>
          <small class="pl-2 form-text text-muted"> Optional</small>
        </div>

        <!-- #region Activity Definition -->
        <div class="d-flex align-items-center mb-2">
          <h5 class="m-0">Activity Definitions</h5>
          <button
            (click)="onNewActivityDefinition()"
            [disabled]="isSavingCaseDef"
            [ngClass]="{ disabled: isSavingCaseDef }"
            type="button"
            class="btn btn-primary ms-auto"
          >
            <fa-icon icon="plus" class="me-1"></fa-icon> New Activity Definition
          </button>
        </div>
        <div class="list-group list-group-flush mb-3">
          <div
            class="list-group-item list-group-item-action"
            *ngFor="let activityDefinition of activityDefinitions"
            ngbTooltip="Click to open activity definition"
            (click)="onActivityDefinitionSelected(activityDefinition)"
          >
            {{ activityDefinition.name }}
          </div>
        </div>
        <!-- endregion -->

        <!-- CUSTOM FIELDS START -->

        <custom-fields-creator
          [customFieldsData]="customFields"
          (customFieldListChanged)="onCustomFieldListChanged($event)"
          class="mb-3"
        ></custom-fields-creator>

        <!-- CUSTOM FIELDS END -->

        <!-- #region Surveys -->
        <div>
          <h5>Forms</h5>
        </div>
        <div *ngIf="!isSurveysLoading" class="text-muted">
          <fa-icon icon="info-circle"></fa-icon> Select one or more pre-created
          forms to add to your Case Definition.
        </div>
        <div *ngIf="!isSurveysLoading" class="list-group list-group-flush mb-3">
          <label
            class="list-group-item mb-0"
            formArrayName="surveyOptionsCheckBoxes"
            *ngFor="
              let survey of surveyOptionsCheckBoxes.controls;
              let i = index
            "
          >
            <input type="checkbox" [formControlName]="i" class="me-1" />
            {{ surveyOptions[i].name }}
          </label>
        </div>
        <div *ngIf="isSurveysLoading">
          <fa-icon
            class="empty-data-icon"
            icon="spinner"
            [spin]="true"
          ></fa-icon>
          Loading surveys...
        </div>
        <!-- endregion -->

        <!-- DOCUMENTS START -->
        <div class="d-flex align-items-center mb-2">
          <h5>Documents</h5>
          <button
            (click)="openAddDocumentsModal()"
            [disabled]="isSavingCaseDef"
            [ngClass]="{ disabled: isSavingCaseDef }"
            type="button"
            class="btn btn-primary ms-auto"
          >
            <fa-icon icon="plus" class="me-1"></fa-icon> New Document
          </button>
        </div>

        <div class="text-muted">
          <fa-icon icon="info-circle"></fa-icon> Click New Documents to add
          Documents to your Case Definition.
        </div>
        <div class="list-group list-group-flush mb-3">
          <div
            *ngFor="let doc of documents; let i = index"
            class="list-group-item p-1"
          >
            <div class="d-flex gap-2 align-items-center">
              <fa-icon class="li-icon flex-grow-0" icon="file"></fa-icon>
              {{ doc.name }}
              <small class="text-muted">
                {{ doc.is_required ? " (Required)" : "" }}
              </small>
            </div>
            <div class="actions mr-1">
              <fa-icon
                (click)="removeDocumentFromDocumentsList(documents[i])"
                container="body"
                ngbTooltip="Remove document"
                class="li-icon action delete"
                icon="trash"
              ></fa-icon>
            </div>
          </div>
        </div>

        <!-- DOCUMENTS END -->
      </div>

      <div class="card-body sticky-bottom bg-white">
        <div class="float-end d-flex gap-1">
          <button
            (click)="onCancel()"
            class="btn btn-light text-dark"
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{
              disabled: isSavingCaseDef || newCaseDefinitonForm.invalid
            }"
          >
            <fa-icon
              *ngIf="isSavingCaseDef"
              class="empty-data-icon"
              icon="spinner"
              [spin]="true"
            ></fa-icon>
            {{ generateSaveNewCaseDefinitionBtnTxt() }}
          </button>
        </div>
      </div>
    </form>

    <app-activity-definition-form
      *ngIf="isEditingActivityDefinition && selectedActivityDefinition"
      [activityDefinition]="selectedActivityDefinition"
      [surveyOptions]="surveyOptions"
      [mode]="activityDefinitionFormMode"
      (activityDefinitionChanged)="onActivityDefinitionChanged($event)"
      (changeCancelled)="onCancelActivityDefinitionChange($event)"
      (remove)="onRemoveActivityDefinition($event)"
    ></app-activity-definition-form>
  </div>
</div>
