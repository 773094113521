<div class="d-flex align-items-center mb-2">
  <h5 class="m-0">Custom Fields</h5>
  <button
    type="button"
    *ngIf="!showCustomFieldsForm"
    (click)="openCustomFieldModal(createNewCustomField())"
    class="btn btn-primary ms-auto"
  >
    <fa-icon icon="plus" class="me-1"></fa-icon> New Field
  </button>
</div>
<div *ngIf="!showCustomFieldsForm">
  <div class="text-muted">
    <fa-icon icon="info-circle" class="me-1"></fa-icon> Click New Field to add
    Customs Fields. Drag and Drop to set the sort the order.
  </div>

  <div class="dynamic-forms mt-3">
    <ngx-sortable
      [items]="customFields"
      [listStyle]="listStyle"
      [showHeader]="false"
      (listSorted)="listSorted($event)"
    >
      <div class="accordion">
        <ng-template let-item="item">
          <div class="sortable-list-item">
            <div class="option animated fadeIn">
              <input
                type="checkbox"
                [checked]="showDetails"
                id="{{ item.id || item.name}}"
                class="toggle"
              />
              <label
                class="title d-flex align-items-center gap-2"
                for="{{ item.id || item.name}}"
              >
                <svg
                  class="sv-svg-icon size-24 opacity-50"
                  [ngSwitch]="item.field_type"
                >
                  <use *ngSwitchCase="'text'" xlink:href="#icon-text"></use>
                  <use *ngSwitchCase="'number'" xlink:href="#icon-text"></use>
                  <use
                    *ngSwitchCase="'date'"
                    xlink:href="#icon-datepicker"
                  ></use>
                  <use
                    *ngSwitchCase="'select'"
                    xlink:href="#icon-dropdown"
                  ></use>
                  <use
                    *ngSwitchCase="'check_box'"
                    xlink:href="#icon-checkbox"
                  ></use>
                  <use
                    *ngSwitchCase="'rank_list'"
                    xlink:href="#icon-ranking"
                  ></use>
                  <use
                    *ngSwitchCase="'radio_button'"
                    xlink:href="#icon-radiogroup"
                  ></use>
                  <use
                    *ngSwitchCase="'textarea'"
                    xlink:href="#icon-comment"
                  ></use>
                </svg>
                {{ item.name }}
              </label>
              <div class="content">
                <div class="btn-toolbar">
                  <div class="btn-group" role="group" aria-label="Third group">
                    <button
                      type="button"
                      container="body"
                      placement="bottom"
                      ngbTooltip="Remove Field"
                      (click)="deleteCustomFieldFromList(item)"
                      class="btn btn-light custom-fields-icon"
                    >
                      <fa-icon icon="trash"></fa-icon>
                    </button>
                    <button
                      type="button"
                      container="body"
                      placement="bottom"
                      ngbTooltip="Edit Field"
                      (click)="openCustomFieldModal(item)"
                      class="btn btn-light custom-fields-icon"
                    >
                      <fa-icon icon="pencil"></fa-icon>
                    </button>
                  </div>
                </div>
                <form [formGroup]="customFieldFormGroup">
                  <!-- (ngSubmit)="onSubmit()" -->
                  <div [ngSwitch]="item.field_type">
                    <div *ngSwitchCase="'text'">
                      <div class="form-label-group">
                        <input
                          type="text"
                          class="form-control"
                          formControlName="{{ item.name }}"
                        />
                        <small *ngIf="item.help_text" class="help-text mt-2"
                          ><i class="fa fa-info-circle" aria-hidden="true"></i>
                          {{ item.help_text }}</small
                        >
                        <label for="inputEmail">{{ item.name }}</label>
                      </div>
                    </div>

                    <div *ngSwitchCase="'number'">
                      <div class="form-label-group">
                        <input
                          type="number"
                          class="form-control"
                          formControlName="{{ item.name }}"
                        />
                        <small *ngIf="item.help_text" class="help-text mt-2"
                          ><i class="fa fa-info-circle" aria-hidden="true"></i>
                          {{ item.help_text }}</small
                        >
                        <label for="inputEmail">{{ item.name }}</label>
                      </div>
                    </div>

                    <div *ngSwitchCase="'date'">
                      <div class="form-label-group">
                        <input
                          type="date"
                          class="form-control"
                          formControlName="{{ item.name }}"
                        />
                        <small *ngIf="item.help_text" class="help-text mt-2"
                          ><i class="fa fa-info-circle" aria-hidden="true"></i>
                          {{ item.help_text }}
                        </small>
                        <label for="inputEmail">{{ item.name }}</label>
                      </div>
                    </div>

                    <div *ngSwitchCase="'select'">
                      <div class="form-label-group">
                        <select
                          class="form-control form-select"
                          formControlName="{{ item.name }}"
                        >
                          <option
                            [ngValue]="opt"
                            *ngFor="let opt of item.selections"
                          >
                            {{ opt.value }}
                          </option>
                        </select>
                        <small *ngIf="item.help_text" class="help-text mt-3"
                          ><i class="fa fa-info-circle" aria-hidden="true"></i>
                          {{ item.help_text }}
                        </small>
                      </div>
                    </div>

                    <div *ngSwitchCase="'check_box'">
                      <div class="mb-1">{{ item.name }}</div>
                      <div
                        class="checkbox-group"
                        formArrayName="{{ item.name }}"
                        *ngFor="
                            let option of getCustomFieldFormGroup(item.name).controls;
                            let i = index
                          "
                      >
                        <input type="checkbox" formControlName="{{ [i] }}" />
                        {{ item.selections[i].value }}
                      </div>
                      <small *ngIf="item.help_text" class="help-text mt-3"
                        ><i class="fa fa-info-circle" aria-hidden="true"></i>
                        {{ item.help_text }}
                      </small>
                    </div>
                    <div *ngSwitchCase="'rank_list'">
                      <div class="mb-1">{{ item.name }}</div>
                      <div
                        class="checkbox-group"
                        formArrayName="{{ item.name }}"
                        *ngFor="
                            let option of getCustomFieldFormGroup(item.name).controls;
                            let i = index
                          "
                      >
                        <input type="checkbox" formControlName="{{ [i] }}" />
                        {{ item.selections[i].value }}
                      </div>
                      <small *ngIf="item.help_text" class="help-text mt-3"
                        ><i class="fa fa-info-circle" aria-hidden="true"></i>
                        {{ item.help_text }}
                      </small>
                    </div>
                    <div *ngSwitchCase="'radio_button'">
                      <div class="mb-2">{{ item.name }}</div>
                      <div
                        *ngFor="let opt of item.selections"
                        class="form-group form-check"
                      >
                        <input
                          formControlName="{{ item.name }}"
                          class="form-check-input"
                          type="radio"
                          value="{{ opt.id }}"
                        />
                        <label class="form-check-label ms-1">
                          {{ opt.value }}</label
                        >
                      </div>
                      <small *ngIf="item.help_text" class="help-text mt-3"
                        ><i class="fa fa-info-circle" aria-hidden="true"></i>
                        {{ item.help_text }}
                      </small>
                    </div>
                    <div *ngSwitchCase="'textarea'">
                      <div class="form-label-group">
                        <textarea
                          class="form-control"
                          formControlName="{{ item.name }}"
                          rows="2"
                          placeholder="{{ item.name }}"
                        ></textarea>
                        <small *ngIf="item.help_text" class="help-text mt-2"
                          ><i class="fa fa-info-circle" aria-hidden="true"></i>
                          {{ item.help_text }}</small
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ngx-sortable>
  </div>
</div>
