<router-outlet></router-outlet>
<svg class="d-none" id="sv-icon-holder-global-container">
  <symbol
    id="icon-arrowdown_34x34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 34 34"
    xml:space="preserve"
  >
    <polygon
      class="st0"
      points="12,16 14,14 17,17 20,14 22,16 17,21 "
    ></polygon>
  </symbol>
  <symbol
    id="icon-arrowleft"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8.99999H4.39998L8.69998 13.3L7.29998 14.7L0.599976 7.99999L7.29998 1.29999L8.69998 2.69999L4.39998 6.99999H15V8.99999Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrowright"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6.99999H11.6L7.3 2.69999L8.7 1.29999L15.4 7.99999L8.7 14.7L7.3 13.3L11.6 8.99999H1V6.99999Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrow_downgrey_10x10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 10 10"
    xml:space="preserve"
  >
    <polygon class="st0" points="2,2 0,4 5,9 10,4 8,2 5,5 "></polygon>
  </symbol>
  <symbol
    id="icon-choosefile"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 9V7C22 5.9 21.1 5 20 5H12L10 3H4C2.9 3 2 3.9 2 5V9V10V21H22L24 9H22ZM4 5H9.2L10.6 6.4L11.2 7H12H20V9H4V5ZM20.3 19H4V11H21.6L20.3 19Z"
    ></path>
  </symbol>
  <symbol
    id="icon-clear"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6 8.6L16.4 2.4C16 2 15.5 1.8 15 1.8C14.5 1.8 14 2 13.6 2.4L1.4 14.6C0.600003 15.4 0.600003 16.6 1.4 17.4L6 22H12L22.6 11.4C23.3 10.6 23.3 9.3 22.6 8.6ZM11.1 20H6.8L2.8 16L6.2 12.6L12.4 18.8L11.1 20ZM13.8 17.4L7.6 11.2L15 3.8L21.2 10L13.8 17.4ZM16 20H23V22H14L16 20Z"
    ></path>
  </symbol>
  <symbol
    id="icon-defaultfile"
    viewBox="0 0 56 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9011_41219)">
      <path
        d="M54.83 10.83L45.17 1.17C44.7982 0.798664 44.357 0.504208 43.8714 0.303455C43.3858 0.102703 42.8654 -0.000411943 42.34 1.2368e-06H6C4.4087 1.2368e-06 2.88257 0.632142 1.75735 1.75736C0.632136 2.88258 0 4.4087 0 6V62C0 63.5913 0.632136 65.1174 1.75735 66.2426C2.88257 67.3679 4.4087 68 6 68H50C51.5913 68 53.1174 67.3679 54.2426 66.2426C55.3679 65.1174 56 63.5913 56 62V13.66C56.0004 13.1346 55.8973 12.6142 55.6965 12.1286C55.4958 11.643 55.2013 11.2018 54.83 10.83ZM44 2.83L53.17 12H48C46.9391 12 45.9217 11.5786 45.1716 10.8284C44.4214 10.0783 44 9.06087 44 8V2.83ZM54 62C54 63.0609 53.5786 64.0783 52.8284 64.8284C52.0783 65.5786 51.0609 66 50 66H6C4.93913 66 3.92172 65.5786 3.17157 64.8284C2.42142 64.0783 2 63.0609 2 62V6C2 4.93914 2.42142 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H42V8C42 9.5913 42.6321 11.1174 43.7574 12.2426C44.8826 13.3679 46.4087 14 48 14H54V62ZM14 24H42V26H14V24ZM14 30H42V32H14V30ZM14 36H42V38H14V36ZM14 42H42V44H14V42Z"
        fill="#909090"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_9011_41219">
        <rect width="56" height="68" fill="white"></rect>
      </clipPath>
    </defs>
  </symbol>
  <symbol
    id="icon-delete"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 4H20H16V2C16 0.9 15.1 0 14 0H10C8.9 0 8 0.9 8 2V4H4H2V6H4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V6H22V4ZM10 2H14V4H10V2ZM18 20H6V6H8H16H18V20ZM14 8H16V18H14V8ZM11 8H13V18H11V8ZM8 8H10V18H8V8Z"
    ></path>
  </symbol>
  <symbol
    id="icon-down_34x34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 34 34"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M33,34H0V0h33c0.6,0,1,0.4,1,1v32C34,33.6,33.6,34,33,34z"
      ></path>
      <polygon
        class="st1"
        points="12,16 14,14 17,17 20,14 22,16 17,21 "
      ></polygon>
    </g>
  </symbol>
  <symbol
    id="icon-left"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xml:space="preserve"
  >
    <polygon points="11,12 9,14 3,8 9,2 11,4 7,8 "></polygon>
  </symbol>
  <symbol
    id="icon-modernbooleancheckchecked"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <polygon
      points="19,10 14,10 14,5 10,5 10,10 5,10 5,14 10,14 10,19 14,19 14,14 19,14 "
    ></polygon>
  </symbol>
  <symbol
    id="icon-modernbooleancheckind"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path
      d="M22,0H2C0.9,0,0,0.9,0,2v20c0,1.1,0.9,2,2,2h20c1.1,0,2-0.9,2-2V2C24,0.9,23.1,0,22,0z M21,18L6,3h15V18z M3,6l15,15H3V6z"
    ></path>
  </symbol>
  <symbol
    id="icon-modernbooleancheckunchecked"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <rect x="5" y="10" width="14" height="4"></rect>
  </symbol>
  <symbol id="icon-moderncheck" viewBox="0 0 24 24">
    <path d="M5,13l2-2l3,3l7-7l2,2l-9,9L5,13z"></path>
  </symbol>
  <symbol id="icon-modernradio" viewBox="-12 -12 24 24">
    <circle r="6" cx="0" cy="0"></circle>
  </symbol>
  <symbol id="icon-more" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 12C6 13.1 5.1 14 4 14C2.9 14 2 13.1 2 12C2 10.9 2.9 10 4 10C5.1 10 6 10.9 6 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM20 10C18.9 10 18 10.9 18 12C18 13.1 18.9 14 20 14C21.1 14 22 13.1 22 12C22 10.9 21.1 10 20 10Z"
    ></path>
  </symbol>
  <symbol id="icon-progressbutton" viewBox="0 0 10 10">
    <polygon points="2,2 0,4 5,9 10,4 8,2 5,5 "></polygon>
  </symbol>
  <symbol id="icon-progressbuttonv2" viewBox="0 0 16 16">
    <path
      d="M15 8.99999H4.39998L8.69998 13.3L7.29998 14.7L0.599976 7.99999L7.29998 1.29999L8.69998 2.69999L4.39998 6.99999H15V8.99999Z"
    ></path>
  </symbol>
  <symbol id="icon-removefile" viewBox="0 0 16 16">
    <path
      d="M8,2C4.7,2,2,4.7,2,8s2.7,6,6,6s6-2.7,6-6S11.3,2,8,2z M11,10l-1,1L8,9l-2,2l-1-1l2-2L5,6l1-1l2,2l2-2l1,1L9,8 L11,10z"
    ></path>
  </symbol>
  <symbol
    id="icon-right"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xml:space="preserve"
  >
    <polygon points="5,4 7,2 13,8 7,14 5,12 9,8 "></polygon>
  </symbol>
  <symbol
    id="icon-searchclear"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.35 3.34999L12.65 2.64999L8.05002 7.24999L3.35002 2.64999L2.65002 3.34999L7.25002 8.04999L2.65002 12.65L3.35002 13.35L8.05002 8.74999L12.65 13.35L13.35 12.65L8.75002 8.04999L13.35 3.34999Z"
    ></path>
  </symbol>
  <symbol
    id="icon-timercircle"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 160 160"
  >
    <circle
      cx="80"
      cy="80"
      r="70"
      style="
        stroke: var(--sd-timer-stroke-background-color);
        stroke-width: var(--sd-timer-stroke-background-width);
      "
      stroke-dasharray="none"
      stroke-dashoffset="none"
    ></circle>
    <circle cx="80" cy="80" r="70"></circle>
  </symbol>
  <symbol
    id="icon-v2check"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00001 15.8L2.60001 10.4L4.00001 9L8.00001 13L16 5L17.4 6.4L8.00001 15.8Z"
    ></path>
  </symbol>
  <symbol
    id="icon-v2check_24x24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 20.1L1 12L3.1 9.9L9 15.9L20.9 4L23 6.1L9 20.1Z"></path>
  </symbol>
  <symbol
    id="icon-v2dragelement_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4C14 4.26522 13.8946 4.51957 13.7071 4.70711C13.5196 4.89464 13.2652 5 13 5H3C2.73478 5 2.48043 4.89464 2.29289 4.70711C2.10536 4.51957 2 4.26522 2 4ZM13 7H3C2.73478 7 2.48043 7.10536 2.29289 7.29289C2.10536 7.48043 2 7.73478 2 8C2 8.26522 2.10536 8.51957 2.29289 8.70711C2.48043 8.89464 2.73478 9 3 9H13C13.2652 9 13.5196 8.89464 13.7071 8.70711C13.8946 8.51957 14 8.26522 14 8C14 7.73478 13.8946 7.48043 13.7071 7.29289C13.5196 7.10536 13.2652 7 13 7ZM13 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H13C13.2652 13 13.5196 12.8946 13.7071 12.7071C13.8946 12.5196 14 12.2652 14 12C14 11.7348 13.8946 11.4804 13.7071 11.2929C13.5196 11.1054 13.2652 11 13 11Z"
    ></path>
  </symbol>
  <symbol
    id="icon-clear_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.35 3.34999L12.65 2.64999L8.05002 7.24999L3.35002 2.64999L2.65002 3.34999L7.25002 8.04999L2.65002 12.65L3.35002 13.35L8.05002 8.74999L12.65 13.35L13.35 12.65L8.75002 8.04999L13.35 3.34999Z"
    ></path>
  </symbol>
  <symbol
    id="icon-collapsedetail"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 7H3V9H13V7Z"></path>
  </symbol>
  <symbol
    id="icon-expanddetail"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 7H9V3H7V7H3V9H7V13H9V9H13V7Z"></path>
  </symbol>
  <symbol id="icon-no-image" viewBox="0 0 48 48">
    <g opacity="0.5">
      <path
        d="M14 17.01C14 16.4167 14.1759 15.8366 14.5056 15.3433C14.8352 14.8499 15.3038 14.4654 15.8519 14.2384C16.4001 14.0113 17.0033 13.9519 17.5853 14.0676C18.1672 14.1834 18.7018 14.4691 19.1213 14.8887C19.5409 15.3082 19.8266 15.8428 19.9424 16.4247C20.0581 17.0067 19.9987 17.6099 19.7716 18.1581C19.5446 18.7062 19.1601 19.1748 18.6667 19.5044C18.1734 19.8341 17.5933 20.01 17 20.01C16.2044 20.01 15.4413 19.6939 14.8787 19.1313C14.3161 18.5687 14 17.8056 14 17.01ZM27.09 24.14L20 36.01H36L27.09 24.14ZM36.72 8.14L35.57 10.01H36C36.5304 10.01 37.0391 10.2207 37.4142 10.5958C37.7893 10.9709 38 11.4796 38 12.01V36.01C38 36.5404 37.7893 37.0491 37.4142 37.4242C37.0391 37.7993 36.5304 38.01 36 38.01H18.77L17.57 40.01H36C37.0609 40.01 38.0783 39.5886 38.8284 38.8384C39.5786 38.0883 40 37.0709 40 36.01V12.01C39.9966 11.0765 39.6668 10.1737 39.0678 9.45778C38.4688 8.74188 37.6382 8.25802 36.72 8.09V8.14ZM36.86 4.5L12.86 44.5L11.14 43.5L13.23 40.01H12C10.9391 40.01 9.92172 39.5886 9.17157 38.8384C8.42143 38.0883 8 37.0709 8 36.01V12.01C8 10.9491 8.42143 9.93172 9.17157 9.18157C9.92172 8.43143 10.9391 8.01 12 8.01H32.43L35.14 3.5L36.86 4.5ZM14.43 38.01L15.63 36.01H12L19 27.01L20.56 27.8L31.23 10.01H12C11.4696 10.01 10.9609 10.2207 10.5858 10.5958C10.2107 10.9709 10 11.4796 10 12.01V36.01C10 36.5404 10.2107 37.0491 10.5858 37.4242C10.9609 37.7993 11.4696 38.01 12 38.01H14.43Z"
      ></path>
    </g>
  </symbol>
  <symbol
    id="icon-rating-star-2"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M24 39.5057L11.7226 45.9839C10.4095 46.6739 8.87606 45.5622 9.12525 44.096L11.4734 30.373L1.54411 20.6556C0.480254 19.6207 1.06489 17.8095 2.53128 17.5986L16.2559 15.5957L22.3994 3.10891C23.0512 1.77685 24.9488 1.77685 25.6102 3.10891L31.7441 15.5957L45.4687 17.5986C46.9351 17.8095 47.5197 19.6207 46.4559 20.6556L36.5266 30.373L38.8748 44.096C39.1239 45.5622 37.5905 46.6835 36.2774 45.9839L24 39.5057Z"
        fill="none"
        stroke-width="2"
      ></path>
      <path
        d="M24.4663 34.6154L24 34.3695L23.5336 34.6154L14.4788 39.389L16.2156 29.2691L16.3044 28.7517L15.9289 28.3848L8.57358 21.1966L18.7249 19.7094L19.245 19.6332L19.4772 19.1616L24 9.97413L28.5228 19.1616L28.755 19.6332L29.275 19.7094L39.4264 21.1966L32.0711 28.3848L31.6956 28.7517L31.7844 29.2691L33.5211 39.389L24.4663 34.6154Z"
        stroke-width="2"
      ></path>
    </g>
  </symbol>
  <symbol
    id="icon-rating-star-small-2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 19.3373L6.13001 22.4373C5.50001 22.7673 4.77001 22.2373 4.89001 21.5373L6.01001 14.9773L1.26001 10.3273C0.750007 9.83728 1.03001 8.96728 1.73001 8.86728L8.29001 7.90728L11.23 1.93728C11.54 1.29728 12.45 1.29728 12.77 1.93728L15.7 7.90728L22.26 8.86728C22.96 8.96728 23.24 9.83728 22.73 10.3273L17.98 14.9773L19.1 21.5373C19.22 22.2373 18.49 22.7773 17.86 22.4373L11.99 19.3373H12Z"
      fill="none"
      stroke-width="2"
    ></path>
    <path
      d="M12 15.9472L8.58001 17.7572L9.23001 13.9272L6.45001 11.2072L10.29 10.6472L12 7.17725L13.71 10.6472L17.55 11.2072L14.77 13.9272L15.42 17.7572L12 15.9472Z"
    ></path>
  </symbol>
  <symbol
    id="icon-rating-star-small"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M12 19.3373L6.13001 22.4373C5.50001 22.7673 4.77001 22.2373 4.89001 21.5373L6.01001 14.9773L1.26001 10.3273C0.750007 9.83728 1.03001 8.96728 1.73001 8.86728L8.29001 7.90728L11.23 1.93728C11.54 1.29728 12.45 1.29728 12.77 1.93728L15.7 7.90728L22.26 8.86728C22.96 8.96728 23.24 9.83728 22.73 10.3273L17.98 14.9773L19.1 21.5373C19.22 22.2373 18.49 22.7773 17.86 22.4373L11.99 19.3373H12Z"
        stroke-width="2"
      ></path>
    </g>
  </symbol>
  <symbol
    id="icon-rating-star"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M24 39.5057L11.7226 45.9839C10.4095 46.6739 8.87606 45.5622 9.12525 44.096L11.4734 30.373L1.54411 20.6556C0.480254 19.6207 1.06489 17.8095 2.53128 17.5986L16.2559 15.5957L22.3994 3.10891C23.0512 1.77685 24.9488 1.77685 25.6102 3.10891L31.7441 15.5957L45.4687 17.5986C46.9351 17.8095 47.5197 19.6207 46.4559 20.6556L36.5266 30.373L38.8748 44.096C39.1239 45.5622 37.5905 46.6835 36.2774 45.9839L24 39.5057Z"
        stroke-width="2"
      ></path>
    </g>
  </symbol>
  <symbol
    id="icon-search"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 2C9.6 2 6 5.6 6 10C6 11.8 6.6 13.5 7.7 14.9L2.3 20.3C1.9 20.7 1.9 21.3 2.3 21.7C2.5 21.9 2.7 22 3 22C3.3 22 3.5 21.9 3.7 21.7L9.1 16.3C10.5 17.4 12.2 18 14 18C18.4 18 22 14.4 22 10C22 5.6 18.4 2 14 2ZM14 16C10.7 16 8 13.3 8 10C8 6.7 10.7 4 14 4C17.3 4 20 6.7 20 10C20 13.3 17.3 16 14 16Z"
    ></path>
  </symbol>
  <symbol
    id="icon-average"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00572 7C6.45572 7 6.00572 6.55 6.00572 6V2C6.00572 1.45 6.45572 1 7.00572 1C7.55572 1 8.00572 1.45 8.00572 2V6C8.00572 6.55 7.55572 7 7.00572 7ZM18.0057 6V2C18.0057 1.45 17.5557 1 17.0057 1C16.4557 1 16.0057 1.45 16.0057 2V6C16.0057 6.55 16.4557 7 17.0057 7C17.5557 7 18.0057 6.55 18.0057 6ZM19.9457 21.33C20.1257 20.81 19.8557 20.24 19.3357 20.05C14.5457 18.35 9.45572 18.35 4.66572 20.05C4.14572 20.23 3.87572 20.81 4.05572 21.33C4.23572 21.85 4.80572 22.12 5.33572 21.94C9.69572 20.4 14.3057 20.4 18.6657 21.94C18.7757 21.98 18.8857 22 18.9957 22C19.4057 22 19.7957 21.74 19.9357 21.33H19.9457Z"
    ></path>
  </symbol>
  <symbol
    id="icon-excellent"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.01 24C6.85767 24 1.15509 20.96 0.0145752 14.16C-0.0354475 13.87 0.0445888 13.57 0.234675 13.35C0.424761 13.13 0.704888 13 0.995019 13H23.005C23.2951 13 23.5752 13.13 23.7653 13.35C23.9554 13.57 24.0354 13.87 23.9854 14.16C22.8549 20.95 17.1423 24 11.99 24H12.01ZM2.25559 15C3.61621 19.82 8.0182 22 12.01 22C16.0018 22 20.4038 19.82 21.7644 15H2.25559ZM8.00819 6V2C8.00819 1.45 7.55799 1 7.00774 1C6.45749 1 6.00729 1.45 6.00729 2V6C6.00729 6.55 6.45749 7 7.00774 7C7.55799 7 8.00819 6.55 8.00819 6ZM18.0127 6V2C18.0127 1.45 17.5625 1 17.0123 1C16.462 1 16.0118 1.45 16.0118 2V6C16.0118 6.55 16.462 7 17.0123 7C17.5625 7 18.0127 6.55 18.0127 6Z"
    ></path>
  </symbol>
  <symbol id="icon-good" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0022 23.99C11.452 23.99 11.0018 23.5402 11.0018 22.9904C11.0018 22.4407 11.452 21.9909 12.0022 21.9909C16.3137 21.9909 21.0755 19.472 22.0158 13.8344C22.1058 13.2947 22.616 12.9248 23.1662 13.0148C23.7064 13.1047 24.0765 13.6245 23.9865 14.1643C22.8561 20.9513 17.144 24 11.9922 24L12.0022 23.99ZM8.00072 5.99783V1.99957C8.00072 1.4498 7.55056 1 7.00036 1C6.45016 1 6 1.4498 6 1.99957V5.99783C6 6.54759 6.45016 6.99739 7.00036 6.99739C7.55056 6.99739 8.00072 6.54759 8.00072 5.99783ZM18.0043 5.99783V1.99957C18.0043 1.4498 17.5542 1 17.004 1C16.4538 1 16.0036 1.4498 16.0036 1.99957V5.99783C16.0036 6.54759 16.4538 6.99739 17.004 6.99739C17.5542 6.99739 18.0043 6.54759 18.0043 5.99783Z"
    ></path>
  </symbol>
  <symbol
    id="icon-normal"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7C6.45 7 6 6.55 6 6V2C6 1.45 6.45 1 7 1C7.55 1 8 1.45 8 2V6C8 6.55 7.55 7 7 7ZM18 6V2C18 1.45 17.55 1 17 1C16.45 1 16 1.45 16 2V6C16 6.55 16.45 7 17 7C17.55 7 18 6.55 18 6ZM21 21C21 20.45 20.55 20 20 20H4C3.45 20 3 20.45 3 21C3 21.55 3.45 22 4 22H20C20.55 22 21 21.55 21 21Z"
    ></path>
  </symbol>
  <symbol
    id="icon-not-good"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.06927 7C6.51927 7 6.06927 6.55 6.06927 6V2C6.06927 1.45 6.51927 1 7.06927 1C7.61927 1 8.06927 1.45 8.06927 2V6C8.06927 6.55 7.61927 7 7.06927 7ZM18.0693 6V2C18.0693 1.45 17.6193 1 17.0693 1C16.5193 1 16.0693 1.45 16.0693 2V6C16.0693 6.55 16.5193 7 17.0693 7C17.6193 7 18.0693 6.55 18.0693 6ZM22.5693 21.9C23.0693 21.66 23.2793 21.07 23.0393 20.57C21.1093 16.52 16.9093 14 12.0693 14C7.22927 14 3.02927 16.52 1.09927 20.57C0.859273 21.07 1.06927 21.67 1.56927 21.9C2.06927 22.14 2.65927 21.93 2.89927 21.43C4.49927 18.08 8.00927 16 12.0593 16C16.1093 16 19.6293 18.08 21.2193 21.43C21.3893 21.79 21.7493 22 22.1193 22C22.2593 22 22.4093 21.97 22.5493 21.9H22.5693Z"
    ></path>
  </symbol>
  <symbol
    id="icon-perfect"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.01 23C6.85721 23 1.15412 19.9621 0.0134987 13.1669C-0.0765501 12.6272 0.293651 12.1076 0.833944 12.0177C1.38424 11.9277 1.89452 12.2975 1.98457 12.8371C2.92508 18.4732 7.69767 20.9914 12 20.9914C16.3023 20.9914 21.0749 18.4732 22.0154 12.8371C22.1055 12.2975 22.6158 11.9277 23.1661 12.0177C23.7063 12.1076 24.0765 12.6272 23.9865 13.1669C22.8559 19.9521 17.1428 23 11.99 23H12.01ZM21.165 6.15177C22.3056 5.01257 22.3056 3.16386 21.165 2.02465L21.0049 1.85477C19.9143 0.765533 18.1633 0.725561 17.0227 1.71487C15.8821 0.715568 14.1312 0.765533 13.0406 1.85477L12.8705 2.01466C11.7299 3.15386 11.7299 5.00257 12.8705 6.14178L17.0227 10.2889L21.175 6.14178L21.165 6.15177ZM15.742 3.27378L17.0127 4.54289L18.2834 3.27378C18.6436 2.91403 19.2239 2.91403 19.5841 3.27378L19.7442 3.43367C20.1044 3.79342 20.1044 4.37301 19.7442 4.73276L17.0127 7.46086L14.2812 4.73276C13.921 4.37301 13.921 3.79342 14.2812 3.43367L14.4413 3.27378C14.6214 3.09391 14.8515 3.00397 15.0917 3.00397C15.3318 3.00397 15.5619 3.09391 15.742 3.27378ZM11.1595 6.15177C12.3002 5.01257 12.3002 3.16386 11.1595 2.02465L10.9995 1.85477C9.90886 0.765533 8.15792 0.725561 7.0173 1.71487C5.87668 0.715568 4.12573 0.765533 3.03514 1.85477L2.86505 2.01466C1.72443 3.15386 1.72443 5.00257 2.86505 6.14178L7.0173 10.2889L11.1695 6.14178L11.1595 6.15177ZM5.7366 3.27378L7.00729 4.54289L8.27798 3.27378C8.63818 2.91403 9.21849 2.91403 9.57869 3.27378L9.73877 3.43367C10.099 3.79342 10.099 4.37301 9.73877 4.73276L7.00729 7.46086L4.27581 4.73276C3.91562 4.37301 3.91562 3.79342 4.27581 3.43367L4.4359 3.27378C4.61599 3.09391 4.84612 3.00397 5.08625 3.00397C5.32638 3.00397 5.5565 3.09391 5.7366 3.27378Z"
    ></path>
  </symbol>
  <symbol id="icon-poor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.01915 7C6.46961 7 6.01998 6.55 6.01998 6V2C6.01998 1.45 6.46961 1 7.01915 1C7.56869 1 8.01832 1.45 8.01832 2V6C8.01832 6.55 7.56869 7 7.01915 7ZM18.01 6V2C18.01 1.45 17.5604 1 17.0108 1C16.4613 1 16.0117 1.45 16.0117 2V6C16.0117 6.55 16.4613 7 17.0108 7C17.5604 7 18.01 6.55 18.01 6ZM16.4213 21.58L18.01 19.99L19.2989 21.28C19.6886 21.67 20.3181 21.67 20.7077 21.28C21.0974 20.89 21.0974 20.26 20.7077 19.87L19.4188 18.58C18.6395 17.8 17.3705 17.8 16.5912 18.58L15.0025 20.17L13.4138 18.58C12.6345 17.8 11.3655 17.8 10.5862 18.58L8.9975 20.17L7.40883 18.58C6.62948 17.8 5.36053 17.8 4.58118 18.58L3.29226 19.87C2.90258 20.26 2.90258 20.89 3.29226 21.28C3.68193 21.67 4.31141 21.67 4.70108 21.28L5.99001 19.99L7.57868 21.58C8.35803 22.36 9.62698 22.36 10.4063 21.58L11.995 19.99L13.5837 21.58C13.9734 21.97 14.4829 22.16 14.9925 22.16C15.5021 22.16 16.0117 21.97 16.4013 21.58H16.4213Z"
    ></path>
  </symbol>
  <symbol
    id="icon-terrible"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4.9938C4 4.44362 4.45 3.99348 5 3.99348H6.59L5.3 2.70306C4.91 2.31293 4.91 1.68272 5.3 1.2926C5.69 0.902468 6.32 0.902468 6.71 1.2926L9.71 4.29357C9.8 4.3836 9.88 4.49364 9.93 4.62368C10.03 4.86376 10.03 5.14385 9.93 5.38393C9.88 5.50397 9.81 5.614 9.71 5.71404L6.71 8.71501C6.51 8.91508 6.26 9.00511 6 9.00511C5.74 9.00511 5.49 8.90508 5.29 8.71501C4.9 8.32489 4.9 7.69468 5.29 7.30456L6.58 6.01413H4.99C4.44 6.01413 3.99 5.56399 3.99 5.01381L4 4.9938ZM14.08 5.37393C14.13 5.49397 14.2 5.604 14.3 5.70403L17.3 8.70501C17.5 8.90508 17.75 8.99511 18.01 8.99511C18.27 8.99511 18.52 8.89507 18.72 8.70501C19.11 8.31488 19.11 7.68468 18.72 7.29455L17.43 6.00413H19.02C19.57 6.00413 20.02 5.55399 20.02 5.00381C20.02 4.45363 19.57 4.00348 19.02 4.00348H17.43L18.72 2.71306C19.11 2.32293 19.11 1.69273 18.72 1.3026C18.33 0.912471 17.7 0.912471 17.31 1.3026L14.31 4.30358C14.22 4.39361 14.14 4.50364 14.09 4.63368C13.99 4.87376 13.99 5.15385 14.09 5.39393L14.08 5.37393ZM22 14.9971V20.999C22 22.6496 20.65 24 19 24H5C3.35 24 2 22.6496 2 20.999V14.9971C2 13.3465 3.35 11.9961 5 11.9961H19C20.65 11.9961 22 13.3465 22 14.9971ZM19 13.9967H16V16.9977H20V14.9971C20 14.4469 19.55 13.9967 19 13.9967ZM14 16.9977V13.9967H10V16.9977H14ZM10 18.9984V21.9993H14V18.9984H10ZM4 14.9971V16.9977H8V13.9967H5C4.45 13.9967 4 14.4469 4 14.9971ZM5 21.9993H8V18.9984H4V20.999C4 21.5492 4.45 21.9993 5 21.9993ZM20 20.999V18.9984H16V21.9993H19C19.55 21.9993 20 21.5492 20 20.999Z"
    ></path>
  </symbol>
  <symbol
    id="icon-very-good"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.01 24C6.85721 24 1.15412 20.96 0.0134987 14.16C-0.0765501 13.62 0.293651 13.1 0.833944 13.01C1.38424 12.92 1.89452 13.29 1.98457 13.83C2.92508 19.47 7.69767 21.99 12 21.99C16.3023 21.99 21.0749 19.47 22.0154 13.83C22.1055 13.29 22.6158 12.92 23.1661 13.01C23.7063 13.1 24.0765 13.62 23.9865 14.16C22.8559 20.95 17.1428 24 11.99 24H12.01ZM8.00783 6V2C8.00783 1.45 7.55759 1 7.00729 1C6.45699 1 6.00675 1.45 6.00675 2V6C6.00675 6.55 6.45699 7 7.00729 7C7.55759 7 8.00783 6.55 8.00783 6ZM18.0133 6V2C18.0133 1.45 17.563 1 17.0127 1C16.4624 1 16.0122 1.45 16.0122 2V6C16.0122 6.55 16.4624 7 17.0127 7C17.563 7 18.0133 6.55 18.0133 6Z"
    ></path>
  </symbol>
  <symbol
    id="icon-very-poor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_15894_140103)">
      <path
        d="M4.88291 4.50999C4.47291 4.50999 4.08291 4.24999 3.94291 3.83999C3.76291 3.31999 4.03291 2.74999 4.55291 2.56999L8.32291 1.24999C8.84291 1.05999 9.41291 1.33999 9.59291 1.85999C9.77291 2.37999 9.50291 2.94999 8.98291 3.12999L5.20291 4.44999C5.09291 4.48999 4.98291 4.50999 4.87291 4.50999H4.88291ZM19.8129 3.88999C20.0229 3.37999 19.7729 2.78999 19.2629 2.58999L15.5529 1.06999C15.0429 0.859992 14.4529 1.10999 14.2529 1.61999C14.0429 2.12999 14.2929 2.71999 14.8029 2.91999L18.5029 4.42999C18.6229 4.47999 18.7529 4.49999 18.8829 4.49999C19.2729 4.49999 19.6529 4.26999 19.8129 3.87999V3.88999ZM3.50291 5.99999C2.64291 6.36999 1.79291 6.87999 1.00291 7.47999C0.79291 7.63999 0.64291 7.86999 0.59291 8.13999C0.48291 8.72999 0.87291 9.28999 1.45291 9.39999C2.04291 9.50999 2.60291 9.11999 2.71291 8.53999C2.87291 7.68999 3.12291 6.82999 3.50291 5.98999V5.99999ZM21.0429 8.54999C21.6029 10.48 24.2429 8.83999 22.7529 7.47999C21.9629 6.87999 21.1129 6.36999 20.2529 5.99999C20.6329 6.83999 20.8829 7.69999 21.0429 8.54999ZM21.5729 13.2C21.2529 14.2 22.5429 15.09 23.3629 14.39C23.8529 14 23.9229 13.29 23.5429 12.81C21.7429 10.67 22.1329 10.55 21.5829 13.2H21.5729ZM1.75291 11C1.22291 11.79 -0.14709 12.64 0.0129102 13.75C0.15291 14.36 0.75291 14.74 1.35291 14.6C2.98291 14.1 1.80291 12.22 1.75291 11ZM19.8829 17C19.8829 13.14 16.2929 9.99999 11.8829 9.99999C7.47291 9.99999 3.88291 13.14 3.88291 17C3.88291 20.86 7.47291 24 11.8829 24C16.2929 24 19.8829 20.86 19.8829 17ZM17.8829 17C17.8829 19.76 15.1929 22 11.8829 22C8.57291 22 5.88291 19.76 5.88291 17C5.88291 14.24 8.57291 12 11.8829 12C15.1929 12 17.8829 14.24 17.8829 17Z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_15894_140103">
        <rect width="24" height="24" fill="white"></rect>
      </clipPath>
    </defs>
  </symbol>
  <symbol
    id="icon-add-lg"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 11H17V13H13V17H11V13H7V11H11V7H13V11ZM24 12C24 18.6 18.6 24 12 24C5.4 24 0 18.6 0 12C0 5.4 5.4 0 12 0C18.6 0 24 5.4 24 12ZM22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z"
    ></path>
  </symbol>
  <symbol id="icon-add" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 11H17V13H13V17H11V13H7V11H11V7H13V11ZM23 12C23 18.1 18.1 23 12 23C5.9 23 1 18.1 1 12C1 5.9 5.9 1 12 1C18.1 1 23 5.9 23 12ZM21 12C21 7 17 3 12 3C7 3 3 7 3 12C3 17 7 21 12 21C17 21 21 17 21 12Z"
    ></path>
  </symbol>
  <symbol
    id="icon-add_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7H12V9H9V12H7V9H4V7H7V4H9V7ZM16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8ZM14 8C14 4.7 11.3 2 8 2C4.7 2 2 4.7 2 8C2 11.3 4.7 14 8 14C11.3 14 14 11.3 14 8Z"
    ></path>
  </symbol>
  <symbol
    id="icon-apply"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21ZM16.3 8L17.7 9.4L10 17.1L6.3 13.4L7.7 12L10 14.3L16.3 8Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrow-down"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.01 11.59L13.01 15.59V6H11.01V15.59L7.00998 11.59L5.59998 13L12.01 19.41L18.43 13L17.01 11.59Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrow-left"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4 2.60001L11.8 4.00001L4.8 11H22V13H4.8L11.8 20L10.4 21.4L1 12L10.4 2.60001Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrow-left_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8.99999H4.39998L8.69998 13.3L7.29998 14.7L0.599976 7.99999L7.29998 1.29999L8.69998 2.69999L4.39998 6.99999H15V8.99999V8.99999Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrow-right"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6 2.60001L12.2 4.00001L19.2 11H2V13H19.2L12.2 20L13.6 21.4L23 12L13.6 2.60001Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrow-right_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6.99999H11.6L7.3 2.69999L8.7 1.29999L15.4 7.99999L8.7 14.7L7.3 13.3L11.6 8.99999H1V6.99999Z"
    ></path>
  </symbol>
  <symbol
    id="icon-arrow-up"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.43 11.01L12.01 4.60001L5.59998 11.01L7.00998 12.43L11.01 8.43001V18.01H13.01V8.43001L17.01 12.43L18.43 11.01Z"
    ></path>
  </symbol>
  <symbol
    id="icon-boolean"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 11H17C19.8 11 22 8.8 22 6C22 3.2 19.8 1 17 1H7C4.2 1 2 3.2 2 6C2 8.8 4.2 11 7 11ZM7 3H17C18.7 3 20 4.3 20 6C20 7.7 18.7 9 17 9H7C5.3 9 4 7.7 4 6C4 4.3 5.3 3 7 3ZM5 6C5 4.9 5.9 4 7 4C8.1 4 9 4.9 9 6C9 7.1 8.1 8 7 8C5.9 8 5 7.1 5 6ZM19 18C19 19.1 18.1 20 17 20C15.9 20 15 19.1 15 18C15 16.9 15.9 16 17 16C18.1 16 19 16.9 19 18ZM17 13H7C4.2 13 2 15.2 2 18C2 20.8 4.2 23 7 23H17C19.8 23 22 20.8 22 18C22 15.2 19.8 13 17 13ZM17 21H7C5.3 21 4 19.7 4 18C4 16.3 5.3 15 7 15H17C18.7 15 20 16.3 20 18C20 19.7 18.7 21 17 21Z"
    ></path>
  </symbol>
  <symbol
    id="icon-change-question-type_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 2V7H10L12 5C11.1 3.8 9.6 3 8 3C5.2 3 3 5.2 3 8H1C1 4.1 4.1 1 8 1C10.2 1 12.1 2 13.4 3.6L15 2ZM8 13C6.4 13 4.9 12.2 4 11L6 9H1V14L2.6 12.4C3.9 14 5.8 15 8 15C11.9 15 15 11.9 15 8H13C13 10.8 10.8 13 8 13Z"
    ></path>
  </symbol>
  <symbol
    id="icon-checkbox"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 8.90001L21 6.10001V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5.00001C3 3.90001 3.9 3.00001 5 3.00001H19C19 3.00001 19 3.00001 19.1 3.00001L20.8 0.600006L22.4 1.80001L11.1 17.5L6.6 13L8 11.6L10.9 14.5L17.7 5.00001H5V19H19V8.90001Z"
    ></path>
  </symbol>
  <symbol
    id="icon-checked"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 20.1L1 12L3.1 9.9L9 15.9L20.9 4L23 6.1L9 20.1Z"></path>
  </symbol>
  <symbol
    id="icon-collapse-detail-light_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 7H3V9H13V7Z"></path>
  </symbol>
  <symbol
    id="icon-collapse-detail_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 9H4V7H12V9ZM16 0V16H0V0H16ZM14 2H2V14H14V2Z"></path>
  </symbol>
  <symbol
    id="icon-collapse-panel"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 5.6L19.4 12L13 18.4L11.6 17L15.6 13H2V11H15.6L11.6 7L13 5.6ZM20 2V22H22V2H20Z"
    ></path>
  </symbol>
  <symbol
    id="icon-collapse_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 6L3 5L8 10L13 5L14 6L8 12L2 6Z"></path>
  </symbol>
  <symbol
    id="icon-color-picker"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.31 15.0002C2.69 15.0002 2.11 14.7602 1.68 14.3202C0.780005 13.4202 0.780005 11.9502 1.68 11.0502L2.45 10.2802C2.45 10.1802 2.44 10.0802 2.44 9.98023C2.44 9.02023 2.81 8.12023 3.49 7.44023L5.6 5.33023C5.37 5.04023 5.25 4.68023 5.25 4.31023C5.25 3.86023 5.42 3.44023 5.74 3.12023C6.06 2.80023 6.48 2.63023 6.92 2.63023C7.29 2.63023 7.65 2.75023 7.94 2.98023L8.88 2.04023C9.56 1.36023 10.46 0.990234 11.41 0.990234C12.36 0.990234 13.27 1.36023 13.95 2.04023C14.63 2.72023 15 3.62023 15 4.58023C15 5.54023 14.63 6.44023 13.95 7.12023L12.94 8.13023C13.04 8.34023 13.09 8.58023 13.09 8.82023C13.09 9.27023 12.92 9.69023 12.6 10.0002C12.28 10.3102 11.86 10.4902 11.42 10.4902C11.18 10.4902 10.94 10.4402 10.73 10.3402L8.56 12.5102C7.88 13.1902 6.98 13.5602 6.03 13.5602C5.93 13.5602 5.83 13.5602 5.73 13.5402L4.97 14.3002C4.53 14.7402 3.95 14.9802 3.34 14.9802L3.31 15.0002Z"
      fill="black"
      fill-opacity="0.15"
    ></path>
    <path
      d="M14.0001 4.57969C14.0001 3.88969 13.7301 3.23969 13.2401 2.74969C12.2301 1.73969 10.5901 1.73969 9.58006 2.74969L7.94006 4.38969L7.39006 3.83969C7.13006 3.57969 6.70006 3.57969 6.44006 3.83969C6.18006 4.09969 6.18006 4.52969 6.44006 4.78969L6.99006 5.33969L4.18006 8.14969C3.69006 8.63969 3.42006 9.28969 3.42006 9.97969C3.42006 10.1997 3.46006 10.4097 3.51006 10.6197L2.38006 11.7497C1.87006 12.2597 1.87006 13.0897 2.38006 13.6097C2.64006 13.8697 2.97006 13.9897 3.31006 13.9897C3.65006 13.9897 3.98006 13.8597 4.24006 13.6097L5.37006 12.4797C5.58006 12.5297 5.79006 12.5697 6.01006 12.5697C6.70006 12.5697 7.35006 12.2997 7.84006 11.8097L10.6501 8.99969L10.9401 9.28969C11.0701 9.41969 11.2401 9.48969 11.4201 9.48969C11.6001 9.48969 11.7701 9.41969 11.9001 9.28969C12.1601 9.02969 12.1601 8.59969 11.9001 8.33969L11.6101 8.04969L13.2501 6.40969C13.7401 5.91969 14.0101 5.26969 14.0101 4.57969H14.0001ZM6.89006 10.8597C6.42006 11.3297 5.61006 11.3297 5.14006 10.8597C4.91006 10.6297 4.78006 10.3197 4.78006 9.98969C4.78006 9.65969 4.91006 9.34969 5.14006 9.11969L7.95006 6.30969L9.70006 8.05969L6.89006 10.8697V10.8597Z"
    ></path>
  </symbol>
  <symbol
    id="icon-comment"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM20 19H4V5H20V19ZM18 9H6V7H18V9ZM18 13H6V11H18V13ZM12 17H6V15H12V17Z"
    ></path>
  </symbol>
  <symbol
    id="icon-default"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4C16.41 4 20 7.14 20 11C19.9901 11.928 19.7826 12.8433 19.3911 13.6847C18.9997 14.5262 18.4334 15.2746 17.73 15.88C17.5103 16.0801 17.338 16.3266 17.2256 16.6017C17.1132 16.8767 17.0634 17.1733 17.08 17.47C16.7752 17.2972 16.4304 17.2076 16.08 17.21C15.8237 17.212 15.5698 17.2594 15.33 17.35C14.2722 17.7789 13.1415 17.9996 12 18C7.59 18 4 14.86 4 11C4 7.14 7.59 4 12 4ZM12 2C6.48 2 2 6 2 11C2 16 6.48 20 12 20C13.4012 19.9996 14.7894 19.7314 16.09 19.21L20 22L19.07 17.36C19.9796 16.5667 20.711 15.5899 21.216 14.4937C21.721 13.3975 21.9882 12.2069 22 11C22 6 17.52 2 12 2Z"
    ></path>
  </symbol>
  <symbol
    id="icon-delete_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 2H14H11V1C11 0.4 10.6 0 10 0H7C6.4 0 6 0.4 6 1V2H3H2V4H3V14C3 15.1 3.9 16 5 16H12C13.1 16 14 15.1 14 14V4H15V2ZM7 1H10V2H7V1ZM12 14H5V4H12V14ZM7 13H6V5H7V13ZM9 13H8V5H9V13ZM11 13H10V5H11V13Z"
    ></path>
  </symbol>
  <symbol
    id="icon-description-hide"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.3 3.7L3.6 5C2 6.9 1 9.3 1 12C1 18.1 5.9 23 12 23C14.7 23 17.1 22 19 20.4L20.3 21.7L21.7 20.3L3.7 2.3L2.3 3.7ZM12 21C7 21 3 17 3 12C3 9.9 3.7 7.9 5 6.4L10.8 12.2C10.7 12.3 10.6 12.4 10.6 12.5C10.5 12.7 10.4 13 10.4 13.3C10.4 13.6 10.5 13.9 10.6 14.1C10.7 14.3 10.8 14.5 10.9 14.8C11 15 11.2 15.1 11.4 15.1C11.5 15.1 11.5 15.1 11.6 15.1H11.7C11.8 15.1 11.9 15.1 12.1 15C12.4 14.9 12.6 14.7 12.6 14.5V14.4V14.1L17.5 19C16.1 20.3 14.1 21 12 21ZM7.8 4.1L6.3 2.6C8 1.6 9.9 1 12 1C18.1 1 23 5.9 23 12C23 14.1 22.4 16 21.4 17.7L19.9 16.2C20.6 15 21 13.5 21 12C21 7 17 3 12 3C10.5 3 9 3.4 7.8 4.1ZM13.5 9.8C13.5 9.7 13.6 9.6 13.6 9.4C13.6 9 13.5 8.6 13.2 8.4C12.8 8 12.4 7.9 12 7.9C11.9 7.9 11.8 7.9 11.6 7.9L10.1 6.4C10.2 6.4 10.2 6.3 10.3 6.3C11.5 5.9 12.7 5.9 13.6 6.1C14.1 6.2 14.5 6.4 14.9 6.6C15.6 7.3 16 8.1 16 9.3C16 10.1 15.7 10.8 15.2 11.4L13.5 9.8ZM11.8 15.9C12.5 15.9 13 16.2 13.3 16.8C13.4 17 13.4 17.2 13.4 17.4C13.4 17.8 13.2 18.2 13 18.5C12.7 18.8 12.3 18.9 11.9 18.9C11.5 18.9 11.1 18.7 10.8 18.5C10.5 18.2 10.4 17.8 10.4 17.4C10.4 17 10.6 16.6 10.8 16.3C11 16 11.4 15.9 11.8 15.9Z"
    ></path>
  </symbol>
  <symbol
    id="icon-description"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21ZM13.3 16.8C13.4 17 13.4 17.2 13.4 17.4C13.4 17.8 13.2 18.2 13 18.5C12.7 18.8 12.3 18.9 11.9 18.9C11.5 18.9 11.1 18.7 10.8 18.5C10.5 18.2 10.4 17.8 10.4 17.4C10.4 17 10.6 16.6 10.8 16.3C11.1 16 11.5 15.8 11.9 15.8C12.5 15.9 13 16.2 13.3 16.8ZM16 9.3C16 10.4 15.5 11.2 14.6 11.9C14.4 12.1 14.1 12.2 13.9 12.4L13.3 12.8C13 13 12.9 13.1 12.9 13.1C12.8 13.2 12.8 13.3 12.8 13.4C12.8 13.6 12.7 13.8 12.7 14.1V14.4V14.5C12.6 14.7 12.4 14.9 12.2 15C12 15.1 11.9 15.1 11.8 15.1H11.7C11.6 15.1 11.6 15.1 11.5 15.1C11.3 15 11.1 14.9 11 14.8C10.9 14.6 10.7 14.4 10.7 14.1C10.6 13.8 10.5 13.6 10.5 13.3C10.5 13 10.6 12.7 10.7 12.5C10.8 12.3 11 12.1 11.2 11.9C11.4 11.7 11.6 11.6 11.8 11.5L12.4 11.2C13 10.9 13.2 10.7 13.3 10.6C13.6 10.3 13.7 9.9 13.7 9.5C13.7 9.1 13.6 8.7 13.3 8.5C12.8 8 12.4 7.9 12 7.9C10.9 7.9 10.6 8.4 10.6 8.8C10.4 9.3 10.4 9.4 10.4 9.4V9.6C10.4 9.7 10.4 9.7 10.4 9.8L10.5 10.2L10.2 10.3C9.6 10.6 9.1 10.6 8.7 10.4C8.6 10.3 8.4 10.1 8.3 10C8.1 9.7 8 9.4 8 9C8 8.6 8.1 8.3 8.2 8C8.3 7.7 8.5 7.5 8.7 7.3C8.9 7.1 9.1 6.9 9.4 6.7C9.7 6.6 10 6.4 10.3 6.3C11.5 5.9 12.7 5.9 13.6 6.1C14.1 6.2 14.5 6.4 14.9 6.6C15.6 7.3 16 8.1 16 9.3Z"
    ></path>
  </symbol>
  <symbol
    id="icon-device-desktop"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 17V2H2V17H10V19C10 19.7956 9.68393 20.5587 9.12132 21.1213C8.55871 21.6839 7.79565 22 7 22H17C16.2044 22 15.4413 21.6839 14.8787 21.1213C14.3161 20.5587 14 19.7956 14 19V17H22ZM4 4H20V15H4V4Z"
    ></path>
  </symbol>
  <symbol
    id="icon-device-phone"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 18H8V6H16V18ZM16 2H8C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V20C6 20.5304 6.21071 21.0391 6.58579 21.4142C6.96086 21.7893 7.46957 22 8 22H16C16.5304 22 17.0391 21.7893 17.4142 21.4142C17.7893 21.0391 18 20.5304 18 20V4C18 3.46957 17.7893 2.96086 17.4142 2.58579C17.0391 2.21071 16.5304 2 16 2Z"
    ></path>
  </symbol>
  <symbol
    id="icon-device-rotate"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.41 5.99591L18 11.4059L16.59 9.99591L19.59 6.99591H17C13.5522 6.99591 10.2456 8.36555 7.80761 10.8035C5.36964 13.2415 4 16.5481 4 19.9959V21.9959H2V19.9959C2 16.0177 3.58035 12.2024 6.3934 9.38931C9.20644 6.57626 13.0218 4.99591 17 4.99591H19.59L16.59 1.99591L18 0.585907L23.41 5.99591Z"
    ></path>
  </symbol>
  <symbol
    id="icon-device-tablet"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 18H4V6H20V18ZM21 4H3C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V18C1 18.5304 1.21071 19.0391 1.58579 19.4142C1.96086 19.7893 2.46957 20 3 20H21C21.5304 20 22.0391 19.7893 22.4142 19.4142C22.7893 19.0391 23 18.5304 23 18V6C23 5.46957 22.7893 4.96086 22.4142 4.58579C22.0391 4.21071 21.5304 4 21 4Z"
    ></path>
  </symbol>
  <symbol
    id="icon-download"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18.41L4.59 11L6 9.59L11 14.59V2H13V14.59L18 9.59L19.41 11L12 18.41ZM2 20V22H22V20H2Z"
    ></path>
  </symbol>
  <symbol
    id="icon-drag-area-indicator"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16ZM9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16Z"
    ></path>
  </symbol>
  <symbol
    id="icon-drag-area-indicator_24x16"
    viewBox="0 0 24 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9C19.1 9 20 9.9 20 11C20 12.1 19.1 13 18 13C16.9 13 16 12.1 16 11C16 9.9 16.9 9 18 9ZM20 5C20 3.9 19.1 3 18 3C16.9 3 16 3.9 16 5C16 6.1 16.9 7 18 7C19.1 7 20 6.1 20 5ZM14 11C14 9.9 13.1 9 12 9C10.9 9 10 9.9 10 11C10 12.1 10.9 13 12 13C13.1 13 14 12.1 14 11ZM14 5C14 3.9 13.1 3 12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5ZM8 11C8 9.9 7.1 9 6 9C4.9 9 4 9.9 4 11C4 12.1 4.9 13 6 13C7.1 13 8 12.1 8 11ZM8 5C8 3.9 7.1 3 6 3C4.9 3 4 3.9 4 5C4 6.1 4.9 7 6 7C7.1 7 8 6.1 8 5Z"
    ></path>
  </symbol>
  <symbol
    id="icon-drop-down-arrow"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 15L17 10H7L12 15Z"></path>
  </symbol>
  <symbol
    id="icon-drop-down-arrow_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 11L13 6H3L8 11Z"></path>
  </symbol>
  <symbol
    id="icon-dropdown"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2H4C2.9 2 2 2.9 2 4V6C2 7.1 2.9 8 4 8V20C4 21.1 4.9 22 6 22H20C21.1 22 22 21.1 22 20V8V6V4C22 2.9 21.1 2 20 2ZM20 20H6V8H20V20ZM4 6V4H20V6H4ZM18 12H8V10H18V12ZM18 16H8V14H18V16Z"
    ></path>
  </symbol>
  <symbol
    id="icon-duplicate_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9500_71113)">
      <path
        d="M14 0H6C5.46957 0 4.96086 0.210714 4.58579 0.585786C4.21071 0.960859 4 1.46957 4 2V4H2C1.46957 4 0.960859 4.21071 0.585786 4.58579C0.210714 4.96086 0 5.46957 0 6L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H10C10.5304 16 11.0391 15.7893 11.4142 15.4142C11.7893 15.0391 12 14.5304 12 14V12H14C14.5304 12 15.0391 11.7893 15.4142 11.4142C15.7893 11.0391 16 10.5304 16 10V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0V0ZM10 14H2V6H10V14ZM14 10H12V6C12 5.46957 11.7893 4.96086 11.4142 4.58579C11.0391 4.21071 10.5304 4 10 4H6V2H14V10Z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_9500_71113">
        <rect width="16" height="16"></rect>
      </clipPath>
    </defs>
  </symbol>
  <symbol id="icon-edit" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9 4.8L19.1 2C18.5 1.5 17.8 1.2 17 1.2C16.2 1.2 15.5 1.5 14.9 2.1L4.6 12.3C2.9 14 2 16.3 2 18.7V21C2 21.6 2.4 22 3 22H5.3C7.7 22 10 21.1 11.7 19.4L21.9 9.1C23.1 7.9 23.1 6 21.9 4.8ZM10.2 17.9C8.9 19.2 7.1 20 5.3 20H4V18.7C4 16.8 4.7 15.1 6.1 13.8L12.3 7.6L16.5 11.8L10.2 17.9ZM20.5 7.7L17.8 10.4L13.6 6.2L16.3 3.5C16.5 3.2 16.8 3.2 17 3.2C17.2 3.2 17.5 3.2 17.7 3.5L20.5 6.3C20.9 6.6 20.9 7.3 20.5 7.7Z"
    ></path>
  </symbol>
  <symbol
    id="icon-edit_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2693_58208)">
      <path
        d="M15.3 2.8L13.2 0.7C12.7 0.2 12.2 0 11.6 0C11 0 10.4 0.2 10 0.7L2 8.6C0.7 9.9 0 11.7 0 13.5V15.3C0 15.7 0.3 16 0.8 16H2.6C4.4 16 6.2 15.3 7.5 14L15.4 6.1C16.2 5.2 16.2 3.7 15.3 2.8ZM6 12.6C5.1 13.5 3.8 14 2.5 14H2V13.5C2 12.2 2.5 10.9 3.4 10L8 5.4L10.6 8L6 12.6ZM13.9 4.7L12 6.6L9.4 4L11.3 2.1C11.4 2 11.5 2 11.6 2C11.7 2 11.7 2 11.8 2.1L13.9 4.2C14 4.3 14 4.5 13.9 4.7Z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_2693_58208">
        <rect width="16" height="16"></rect>
      </clipPath>
    </defs>
  </symbol>
  <symbol
    id="icon-editing-finish"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.3 3.7L7.8 9.2L4.6 12.4C2.9 14.1 2 16.4 2 18.8V21.1C2 21.7 2.4 22.1 3 22.1H5.3C7.7 22.1 10 21.2 11.7 19.5L14.9 16.3L20.4 21.8L21.8 20.4L3.8 2.4L2.3 3.7ZM10.2 17.9C8.9 19.2 7.1 20 5.3 20H4V18.7C4 16.8 4.7 15.1 6.1 13.8L9.3 10.6L13.5 14.8L10.2 17.9ZM21.9 9.1L17.2 13.8L15.8 12.4L20.5 7.7C20.9 7.3 20.9 6.7 20.5 6.3L17.7 3.5C17.5 3.2 17.2 3.2 17 3.2C16.8 3.2 16.5 3.2 16.3 3.5L11.6 8.2L10.2 6.8L14.9 2.1C15.5 1.5 16.2 1.2 17 1.2C17.8 1.2 18.5 1.5 19.1 2.1L21.9 4.9C23.1 6 23.1 7.9 21.9 9.1Z"
    ></path>
  </symbol>
  <symbol
    id="icon-expand-detail-light_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 7H9V3H7V7H3V9H7V13H9V9H13V7Z"></path>
  </symbol>
  <symbol
    id="icon-expand-detail_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 9H4V7H7V4H9V7H12V9H9V12H7V9ZM16 0V16H0V0H16ZM14 2H2V14H14V2Z"
    ></path>
  </symbol>
  <symbol
    id="icon-expand-panel"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9959 2H14.9959V22H21.9959V2H19.9959ZM19.9959 20H16.9959V4H19.9959V20ZM4.40588 11H12.9959V13H4.40588L8.40588 17L6.99588 18.41L0.585876 12L6.99588 5.59L8.40588 7L4.40588 11Z"
    ></path>
  </symbol>
  <symbol
    id="icon-expand_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 14L5 13L10 8L5 3L6 2L12 8L6 14Z"></path>
  </symbol>
  <symbol
    id="icon-export"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 10.4059L4.59 8.99591L12 1.58591L19.41 8.99591L18 10.4059L13 5.40591V17.9959H11V5.40591L6 10.4059ZM2 19.9959V21.9959H22V19.9959H2Z"
    ></path>
  </symbol>
  <symbol
    id="icon-expression"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 0.5C19.4 0.2 18.7 0 17.8 0C15.9 0 14.3 0.8 13 2.3C11.5 4.1 10.1 7.1 9 11.5H6.2L5.8 13H8.5L8.3 14C8.2 14.4 8.1 14.9 7.9 15.7C7.7 16.4 7.6 17.1 7.5 17.6C6.8 20.6 6.1 21.9 5.6 22.4C5.6 22.1 5.5 21.8 5.2 21.6C4.7 21.3 3.8 21.2 3.3 21.8C3.1 22 3 22.4 3 22.7C3 23.1 3.1 23.4 3.4 23.6C3.7 23.9 4.2 24 4.8 24C5.4 24 6 23.9 6.5 23.6C7.9 23 9 21.7 9.8 19.6C10.2 18.5 10.8 16.7 11.4 14.1L11.7 12.9H15.1L15.5 11.4H12.1C13.1 7.2 14.2 4.2 15.3 2.7C15.6 2.3 15.9 2 16.2 1.8C16.6 1.5 16.8 1.5 17 1.5C17.2 1.5 17.4 1.5 17.7 1.6C17.5 1.9 17.5 2.2 17.5 2.5C17.5 2.9 17.7 3.3 18 3.6C18.7 4.1 19.8 4.1 20.5 3.6C20.8 3.2 21 2.8 21 2.3C21 1.5 20.7 0.9 20 0.5Z"
    ></path>
  </symbol>
  <symbol
    id="icon-fast-entry"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 5H2V3H13V5ZM10 7H2V9H10V7ZM2 17H10V15H2V17ZM13 11H2V13H13V11ZM23.4 14.9L17 21.3C16 22.4 14.5 23 13 23H11.6C11.3 23 11 22.7 11 22.4V21C11 19.5 11.6 18.1 12.7 17L19.1 10.6C19.4 10.2 19.9 10 20.4 10C20.9 10 21.4 10.2 21.7 10.6L23.4 12.3C24.2 13 24.2 14.2 23.4 14.9ZM21.9 13.6L20.3 12L14 18.3C13.4 19.1 13 20 13 21C14 21 14.9 20.6 15.6 19.9L21.9 13.6Z"
    ></path>
  </symbol>
  <symbol id="icon-file" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 9V7C22 5.9 21.1 5 20 5H12L10 3H4C2.9 3 2 3.9 2 5V9V10V21H22L24 9H22ZM4 5H9.2L10.6 6.4L11.2 7H12H20V9H4V5ZM20.3 19H4V11H21.6L20.3 19Z"
    ></path>
  </symbol>
  <symbol id="icon-html" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.85 6.64999L3.35 11.95L7.85 17.35L6.25 18.65L0.75 11.95L6.25 5.34999L7.85 6.64999ZM17.85 5.34999L16.25 6.64999L20.75 11.95L16.25 17.35L17.75 18.65L23.25 12.05L17.85 5.34999ZM8.15 20.65L10.05 21.25L16.05 3.24999L14.15 2.64999L8.15 20.65Z"
    ></path>
  </symbol>
  <symbol
    id="icon-image"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2H4C2.9 2 2 2.9 2 4V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V4C22 2.9 21.1 2 20 2ZM20 20H4V4H20V20ZM7 8C7 6.9 7.9 6 9 6C10.1 6 11 6.9 11 8C11 9.1 10.1 10 9 10C7.9 10 7 9.1 7 8ZM19 19H5L9 14L11 15L14 12L19 19Z"
    ></path>
  </symbol>
  <symbol
    id="icon-imagepicker"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 5H4C2.9 5 2 5.9 2 7V20C2 21.1 2.9 22 4 22H17C18.1 22 19 21.1 19 20V7C19 5.9 18.1 5 17 5ZM17 20H4V7H17V20ZM6 11C6 9.9 6.9 9 8 9C9.1 9 10 9.9 10 11C10 12.1 9.1 13 8 13C6.9 13 6 12.1 6 11ZM16 19H5L16 14V19ZM22 4V17C22 18.1 21.1 19 20 19V17V4H7H5C5 2.9 5.9 2 7 2H20C21.1 2 22 2.9 22 4Z"
    ></path>
  </symbol>
  <symbol
    id="icon-import"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18.41L4.59 11L6 9.59L11 14.59V2H13V14.59L18 9.59L19.41 11L12 18.41ZM2 20V22H22V20H2Z"
    ></path>
  </symbol>
  <symbol
    id="icon-invisible-items"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7.003C12.4641 7.01107 12.9249 7.08171 13.37 7.213L11.54 9.053C10.9155 9.15262 10.3384 9.44704 9.89125 9.89423C9.44406 10.3414 9.14964 10.9185 9.05002 11.543L7.21002 13.373C7.07873 12.9279 7.00809 12.467 7.00002 12.003C7.00002 10.6769 7.5268 9.40515 8.46449 8.46747C9.40217 7.52978 10.6739 7.003 12 7.003ZM5.40002 15.183C4.39516 14.2466 3.52369 13.1766 2.81002 12.003C5.10002 8.223 8.47002 6.003 12 6.003C12.765 6.0082 13.5255 6.11925 14.26 6.333L15.86 4.733C14.6291 4.25486 13.3206 4.0074 12 4.003C7.24002 4.003 3.05002 7.173 0.52002 12.003C1.41557 13.7255 2.59165 15.2868 4.00002 16.623L5.40002 15.183ZM23.48 12.003C21 16.833 16.76 20.003 12 20.003C10.1707 19.9916 8.37315 19.5242 6.77002 18.643L2.71002 22.713L1.29002 21.293L21.29 1.293L22.71 2.713L18.94 6.473C20.8367 7.96509 22.3858 9.8521 23.48 12.003ZM14.7 10.723L10.7 14.723C11.1058 14.9158 11.5508 15.0116 12 15.003C12.7957 15.003 13.5587 14.6869 14.1213 14.1243C14.6839 13.5617 15 12.7986 15 12.003C14.9993 11.559 14.8967 11.1211 14.7 10.723ZM21.19 12.003C20.243 10.4087 18.9887 9.01851 17.5 7.913L16.16 9.263C16.7052 10.073 16.9975 11.0266 17 12.003C17 13.3291 16.4732 14.6009 15.5356 15.5385C14.5979 16.4762 13.3261 17.003 12 17.003C11.0237 17.0005 10.07 16.7081 9.26002 16.163L8.26002 17.163C9.43056 17.7122 10.707 17.9989 12 18.003C15.53 18.003 18.9 15.783 21.19 12.003Z"
    ></path>
  </symbol>
  <symbol
    id="icon-keyboard-dragging"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 5L12 0L7 5H11V9H13V5H17Z"></path>
    <path d="M13 19V15H11V19H7L12 24L17 19H13Z"></path>
  </symbol>
  <symbol
    id="icon-language"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.75 8H15.25L13.35 13.08C12.2029 12.2588 11.2439 11.2027 10.537 9.98187C9.83 8.76107 9.39127 7.40364 9.25 6H14V4H9V2H7V4H2V6H6.91C6.73189 7.75797 6.09115 9.43736 5.05302 10.8672C4.01489 12.2971 2.61643 13.4263 1 14.14C1.32315 14.7296 1.69441 15.2915 2.11 15.82C4.82764 14.5445 6.96846 12.2966 8.11 9.52C9.02641 11.7596 10.5985 13.6698 12.62 15L10 22H12.42L13.79 18.13H19.16L20.56 22H23L17.75 8ZM14.46 16.16L15.73 12.53C16.0406 11.7049 16.2945 10.8596 16.49 10C16.55 10.26 16.67 10.66 16.84 11.21C17.01 11.76 17.14 12.15 17.24 12.41L18.55 16.2L14.46 16.16Z"
    ></path>
  </symbol>
  <symbol id="icon-load" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.93 9H21V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H10L8 3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5L2 21H21L23.89 11.63C23.9916 11.3244 24.0179 10.9988 23.9667 10.6809C23.9155 10.363 23.7882 10.0621 23.5958 9.80392C23.4034 9.54571 23.1514 9.33779 22.8614 9.19782C22.5714 9.05786 22.2519 8.99 21.93 9ZM4 5H7.17L8.59 6.41L9.17 7H19V9H6L4 15V5ZM22 11L19.54 19H4.77L7.44 11H22Z"
    ></path>
  </symbol>
  <symbol
    id="icon-logic-collapse"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_252_57732)">
      <path
        d="M19 29.2996L20.4 30.6996L22.7 28.3996V33.9996H24.7V28.3996L27 30.6996L28.4 29.2996L23.7 24.5996L19 29.2996Z"
      ></path>
      <path
        d="M24.7 19.6V14H22.7V19.6L20.4 17.3L19 18.7L23.7 23.4L28.4 18.7L27 17.3L24.7 19.6Z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_252_57732">
        <rect
          width="9.4"
          height="20"
          fill="white"
          transform="translate(19 14)"
        ></rect>
      </clipPath>
    </defs>
  </symbol>
  <symbol
    id="icon-logic-expand"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_252_57727)">
      <path
        d="M19 17.7L20.4 19.1L22.7 16.8V22.4H24.7V16.8L27 19.1L28.4 17.7L23.7 13L19 17.7Z"
      ></path>
      <path
        d="M24.7 32.0004V26.4004H22.7V32.0004L20.4 29.7004L19 31.1004L23.7 35.8004L28.4 31.1004L27 29.7004L24.7 32.0004Z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_252_57727">
        <rect
          width="9.4"
          height="22.8"
          fill="white"
          transform="translate(19 13)"
        ></rect>
      </clipPath>
    </defs>
  </symbol>
  <symbol id="icon-logo" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 0H4C1.79 0 0 1.79 0 4V28C0 30.21 1.79 32 4 32H28C30.21 32 32 30.21 32 28V4C32 1.79 30.21 0 28 0ZM30 28C30 29.1 29.1 30 28 30H4C2.9 30 2 29.1 2 28V4C2 2.9 2.9 2 4 2H28C29.1 2 30 2.9 30 4V28ZM6 9C6 7.34 7.34 6 9 6C10.66 6 12 7.34 12 9C12 10.66 10.66 12 9 12C7.34 12 6 10.66 6 9ZM19 16L28 28H4L11 19L15 21L19 16Z"
    ></path>
  </symbol>
  <symbol
    id="icon-matrix"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1C3.2 1 1 3.2 1 6C1 8.8 3.2 11 6 11C8.8 11 11 8.8 11 6C11 3.2 8.8 1 6 1ZM6 9C4.3 9 3 7.7 3 6C3 4.3 4.3 3 6 3C7.7 3 9 4.3 9 6C9 7.7 7.7 9 6 9ZM8 6C8 7.1 7.1 8 6 8C4.9 8 4 7.1 4 6C4 4.9 4.9 4 6 4C7.1 4 8 4.9 8 6ZM20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM6 13C3.2 13 1 15.2 1 18C1 20.8 3.2 23 6 23C8.8 23 11 20.8 11 18C11 15.2 8.8 13 6 13ZM6 21C4.3 21 3 19.7 3 18C3 16.3 4.3 15 6 15C7.7 15 9 16.3 9 18C9 19.7 7.7 21 6 21ZM18 11C20.8 11 23 8.8 23 6C23 3.2 20.8 1 18 1C15.2 1 13 3.2 13 6C13 8.8 15.2 11 18 11ZM18 3C19.7 3 21 4.3 21 6C21 7.7 19.7 9 18 9C16.3 9 15 7.7 15 6C15 4.3 16.3 3 18 3ZM18 13C15.2 13 13 15.2 13 18C13 20.8 15.2 23 18 23C20.8 23 23 20.8 23 18C23 15.2 20.8 13 18 13ZM18 21C16.3 21 15 19.7 15 18C15 16.3 16.3 15 18 15C19.7 15 21 16.3 21 18C21 19.7 19.7 21 18 21Z"
    ></path>
  </symbol>
  <symbol
    id="icon-matrixdropdown"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 4C7 5.7 5.7 7 4 7C2.3 7 1 5.7 1 4C1 2.3 2.3 1 4 1C5.7 1 7 2.3 7 4ZM15 4C15 5.7 13.7 7 12 7C10.3 7 9 5.7 9 4C9 2.3 10.3 1 12 1C13.7 1 15 2.3 15 4ZM13 4C13 3.4 12.6 3 12 3C11.4 3 11 3.4 11 4C11 4.6 11.4 5 12 5C12.6 5 13 4.6 13 4ZM17 4C17 2.3 18.3 1 20 1C21.7 1 23 2.3 23 4C23 5.7 21.7 7 20 7C18.3 7 17 5.7 17 4ZM19 4C19 4.6 19.4 5 20 5C20.6 5 21 4.6 21 4C21 3.4 20.6 3 20 3C19.4 3 19 3.4 19 4ZM7 12C7 13.7 5.7 15 4 15C2.3 15 1 13.7 1 12C1 10.3 2.3 9 4 9C5.7 9 7 10.3 7 12ZM5 12C5 11.4 4.6 11 4 11C3.4 11 3 11.4 3 12C3 12.6 3.4 13 4 13C4.6 13 5 12.6 5 12ZM12 9C10.3 9 9 10.3 9 12C9 13.7 10.3 15 12 15C13.7 15 15 13.7 15 12C15 10.3 13.7 9 12 9ZM20 9C18.3 9 17 10.3 17 12C17 13.7 18.3 15 20 15C21.7 15 23 13.7 23 12C23 10.3 21.7 9 20 9ZM4 17C2.3 17 1 18.3 1 20C1 21.7 2.3 23 4 23C5.7 23 7 21.7 7 20C7 18.3 5.7 17 4 17ZM12 17C10.3 17 9 18.3 9 20C9 21.7 10.3 23 12 23C13.7 23 15 21.7 15 20C15 18.3 13.7 17 12 17ZM23 20C23 21.7 21.7 23 20 23C18.3 23 17 21.7 17 20C17 18.3 18.3 17 20 17C21.7 17 23 18.3 23 20ZM21 20C21 19.4 20.6 19 20 19C19.4 19 19 19.4 19 20C19 20.6 19.4 21 20 21C20.6 21 21 20.6 21 20Z"
    ></path>
  </symbol>
  <symbol
    id="icon-matrixdynamic"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 4C7 5.7 5.7 7 4 7C2.3 7 1 5.7 1 4C1 2.3 2.3 1 4 1C5.7 1 7 2.3 7 4ZM15 4C15 5.7 13.7 7 12 7C10.3 7 9 5.7 9 4C9 2.3 10.3 1 12 1C13.7 1 15 2.3 15 4ZM13 4C13 3.4 12.6 3 12 3C11.4 3 11 3.4 11 4C11 4.6 11.4 5 12 5C12.6 5 13 4.6 13 4ZM17 4C17 2.3 18.3 1 20 1C21.7 1 23 2.3 23 4C23 5.7 21.7 7 20 7C18.3 7 17 5.7 17 4ZM19 4C19 4.6 19.4 5 20 5C20.6 5 21 4.6 21 4C21 3.4 20.6 3 20 3C19.4 3 19 3.4 19 4ZM7 12C7 13.7 5.7 15 4 15C2.3 15 1 13.7 1 12C1 10.3 2.3 9 4 9C5.7 9 7 10.3 7 12ZM5 12C5 11.4 4.6 11 4 11C3.4 11 3 11.4 3 12C3 12.6 3.4 13 4 13C4.6 13 5 12.6 5 12ZM12 9C10.3 9 9 10.3 9 12C9 13.7 10.3 15 12 15C13.7 15 15 13.7 15 12C15 10.3 13.7 9 12 9ZM20 9C18.3 9 17 10.3 17 12C17 13.7 18.3 15 20 15C21.7 15 23 13.7 23 12C23 10.3 21.7 9 20 9ZM5 17H3V19H1V21H3V23H5V21H7V19H5V17Z"
    ></path>
  </symbol>
  <symbol
    id="icon-multipletext"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 1H4C2.9 1 2 1.9 2 3V9C2 10.1 2.9 11 4 11H20C21.1 11 22 10.1 22 9V3C22 1.9 21.1 1 20 1ZM20 9H4V3H20V9ZM14 7H6V5H14V7ZM20 13H4C2.9 13 2 13.9 2 15V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V15C22 13.9 21.1 13 20 13ZM20 21H4V15H20V21ZM14 19H6V17H14V19Z"
    ></path>
  </symbol>
  <symbol
    id="icon-next_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.64648 12.6465L6.34648 13.3465L11.7465 8.04648L6.34648 2.64648L5.64648 3.34648L10.2465 8.04648L5.64648 12.6465Z"
    ></path>
  </symbol>
  <symbol
    id="icon-panel"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 2V4H4V9H2V4C2 2.9 2.9 2 4 2H9ZM20 2H15V4H20V9H22V4C22 2.9 21.1 2 20 2ZM4 20V15H2V20C2 21.1 2.9 22 4 22H9V20H4ZM20 20H15V22H20C21.1 22 22 21.1 22 20V15H20V20Z"
    ></path>
  </symbol>
  <symbol
    id="icon-paneldynamic"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 2V4H4V9H2V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H9ZM20 2H15V4H20V9H22V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2ZM4 20V15H2V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H9V20H4ZM20 20H15V22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V15H20V20ZM17 13H15V11H13V13H11V15H13V17H15V15H17V13Z"
    ></path>
  </symbol>
  <symbol
    id="icon-preview"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3C13.78 3 15.5201 3.52784 17.0001 4.51677C18.4802 5.50571 19.6337 6.91131 20.3149 8.55585C20.9961 10.2004 21.1743 12.01 20.8271 13.7558C20.4798 15.5016 19.6226 17.1053 18.364 18.364C17.1053 19.6226 15.5016 20.4798 13.7558 20.8271C12.01 21.1743 10.2004 20.9961 8.55585 20.3149C6.91132 19.6337 5.50571 18.4802 4.51678 17.0001C3.52785 15.5201 3.00001 13.78 3.00001 12C3.00001 9.61305 3.94822 7.32387 5.63604 5.63604C7.32387 3.94821 9.61306 3 12 3ZM12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM16 12L10 8V16L16 12Z"
    ></path>
  </symbol>
  <symbol
    id="icon-previous_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.393 12.6465L5.69297 8.04651L10.393 3.34651L9.69297 2.64651L4.29297 8.04651L9.69297 13.3465L10.393 12.6465Z"
    ></path>
  </symbol>
  <symbol
    id="icon-radiogroup"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14C10.9 14 10 13.1 10 12C10 10.9 10.9 10 12 10ZM12 8C9.8 8 8 9.8 8 12C8 14.2 9.8 16 12 16C14.2 16 16 14.2 16 12C16 9.8 14.2 8 12 8Z"
    ></path>
  </symbol>
  <symbol
    id="icon-ranking"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 4H2V21H14V16H18V11H22V4ZM12 19H4V16H12V19ZM16 14H4V11H16V14ZM20 9H4V6H20V9Z"
    ></path>
  </symbol>
  <symbol
    id="icon-rating"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M12 6.8L12.9 9.7L13.3 11.1H14.8H17.8L15.4 12.9L14.2 13.8L14.6 15.2L15.5 18.1L13.1 16.3L12 15.3L10.8 16.2L8.4 18L9.3 15.1L9.7 13.7L8.5 12.8L6.1 11H9.1H10.6L11 9.6L12 6.8ZM12 0.300003L9.2 9H0L7.4 14.4L4.6 23.1L12 17.7L19.4 23.1L16.6 14.4L24 9H14.8L12 0.300003Z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0"><rect width="24" height="24"></rect></clipPath>
    </defs>
  </symbol>
  <symbol id="icon-redo" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 8.00001H19.6L15.6 4.00001L17 2.60001L23.4 9.00001L17 15.4L15.6 14L19.6 10H7C4.8 10 3 11.8 3 14C3 16.2 4.8 18 7 18H8V20H7C3.7 20 1 17.3 1 14C1 10.7 3.7 8.00001 7 8.00001Z"
    ></path>
  </symbol>
  <symbol
    id="icon-remove_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7V9H4V7H12ZM16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8ZM14 8C14 4.7 11.3 2 8 2C4.7 2 2 4.7 2 8C2 11.3 4.7 14 8 14C11.3 14 14 11.3 14 8Z"
    ></path>
  </symbol>
  <symbol id="icon-save" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 2H4L2 4V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2ZM15 4V6H9V4H15ZM8 20V15H16V20H8ZM20 20H18V13H6V20H4V4.83L4.83 4H7V8H17V4H20V20Z"
    ></path>
  </symbol>
  <symbol
    id="icon-select-page"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 11H19.9C19.4 7.4 16.6 4.5 13 4.1V1H11V4.1C7.4 4.5 4.5 7.4 4.1 11H1V13H4.1C4.6 16.6 7.4 19.5 11 19.9V23H13V19.9C16.6 19.4 19.5 16.6 19.9 13H23V11ZM12 18C8.7 18 6 15.3 6 12C6 8.7 8.7 6 12 6C15.3 6 18 8.7 18 12C18 15.3 15.3 18 12 18Z"
    ></path>
  </symbol>
  <symbol
    id="icon-settings"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9 4C9.4 2.3 7.9 1 6 1C3.8 1 2 2.8 2 5C2 7.2 3.8 9 6 9C7.9 9 9.4 7.7 9.9 6H22V4H9.9ZM6 7C4.9 7 4 6.1 4 5C4 3.9 4.9 3 6 3C7.1 3 8 3.9 8 5C8 6.1 7.1 7 6 7ZM9 15C7.1 15 5.6 16.3 5.1 18H2V20H5.1C5.5 21.7 7.1 23 9 23C10.9 23 12.4 21.7 12.9 20H22V18H12.9C12.4 16.3 10.9 15 9 15ZM9 21C7.9 21 7 20.1 7 19C7 17.9 7.9 17 9 17C10.1 17 11 17.9 11 19C11 20.1 10.1 21 9 21ZM18 8C16.1 8 14.6 9.3 14.1 11H2V13H14.1C14.5 14.7 16.1 16 18 16C20.2 16 22 14.2 22 12C22 9.8 20.2 8 18 8ZM18 14C16.9 14 16 13.1 16 12C16 10.9 16.9 10 18 10C19.1 10 20 10.9 20 12C20 13.1 19.1 14 18 14Z"
    ></path>
  </symbol>
  <symbol
    id="icon-settings_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8 2C6.4 0.8 5.3 0 4 0C2.3 0 1 1.3 1 3C1 4.7 2.3 6 4 6C5.3 6 6.4 5.2 6.8 4H15V2H6.8ZM4 4C3.4 4 3 3.6 3 3C3 2.4 3.4 2 4 2C4.6 2 5 2.4 5 3C5 3.6 4.6 4 4 4ZM12 5C10.7 5 9.6 5.8 9.2 7H1V9H9.2C9.6 10.2 10.7 11 12 11C13.7 11 15 9.7 15 8C15 6.3 13.7 5 12 5ZM12 9C11.4 9 11 8.6 11 8C11 7.4 11.4 7 12 7C12.6 7 13 7.4 13 8C13 8.6 12.6 9 12 9ZM6 10C4.7 10 3.6 10.8 3.2 12H1V14H3.2C3.6 15.2 4.7 16 6 16C7.3 16 8.4 15.2 8.8 14H15V12H8.8C8.4 10.8 7.3 10 6 10ZM6 14C5.4 14 5 13.6 5 13C5 12.4 5.4 12 6 12C6.6 12 7 12.4 7 13C7 13.6 6.6 14 6 14Z"
    ></path>
  </symbol>
  <symbol
    id="icon-signaturepad"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 17V19H9.1C9.5 18.4 9.9 17.7 10.2 17H24ZM11.9 9.00001C11 13.7 8.6 19.6 5 22.6C3.8 23.6 2 22.6 2 21.1C2 20.4 2.1 19.7 2.2 19H0V17H2.8C4.3 12.9 7.6 9.10001 10.2 7.60001C10.4 6.10001 10.4 4.80001 10.2 3.90001C9.9 3.30001 9.7 3.00001 9.6 3.00001C9.4 3.00001 7.8 2.90001 3.8 8.70001L2.2 7.60001C5.5 2.80001 7.9 0.700007 9.9 1.00001C12 1.20001 12.5 4.60001 12.2 6.90001C14.3 7.00001 14.8 8.20001 14.9 10.3C16.2 9.10001 18.1 7.90001 19.2 9.20001C20.8 8.90001 22.1 10.4 22.9 11.5L21.3 12.7C20.4 11.4 19.8 11.2 19.6 11.2C19.5 11.2 19.5 11.3 19.4 11.4C19 12 18.6 12.8 18 13.7C17.7 14.1 17.2 14.3 16.7 14.1C15.5 13.5 16.5 12.1 16.8 11.2C15.8 12 14.4 13.4 13.9 14.8C13.8 15.5 13.3 16.3 12.5 16.1C11.6 15.9 11.7 14.9 12 14.2C13.3 9.80001 12.5 9.00001 12.5 9.00001C12.5 8.90001 12.2 8.80001 11.9 9.00001ZM9.5 10.4C6.9 12.8 4.1 17.1 4 20.8C6.6 18.5 8.5 14.3 9.5 10.4Z"
    ></path>
  </symbol>
  <symbol
    id="icon-switch-active_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5C12.7 5 14 6.3 14 8C14 9.7 12.7 11 11 11H5C3.3 11 2 9.7 2 8C2 6.3 3.3 5 5 5H11ZM11 3H5C2.2 3 0 5.2 0 8C0 10.8 2.2 13 5 13H11C13.8 13 16 10.8 16 8C16 5.2 13.8 3 11 3ZM11 6C9.9 6 9 6.9 9 8C9 9.1 9.9 10 11 10C12.1 10 13 9.1 13 8C13 6.9 12.1 6 11 6Z"
    ></path>
  </symbol>
  <symbol
    id="icon-switch-inactive_16x16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5C12.7 5 14 6.3 14 8C14 9.7 12.7 11 11 11H5C3.3 11 2 9.7 2 8C2 6.3 3.3 5 5 5H11ZM11 3H5C2.2 3 0 5.2 0 8C0 10.8 2.2 13 5 13H11C13.8 13 16 10.8 16 8C16 5.2 13.8 3 11 3ZM5 6C3.9 6 3 6.9 3 8C3 9.1 3.9 10 5 10C6.1 10 7 9.1 7 8C7 6.9 6.1 6 5 6Z"
    ></path>
  </symbol>
  <symbol
    id="icon-tagbox"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 7V6C22 4.9 21.1 4 20 4H16C14.9 4 14 4.9 14 6V7C14 8.1 14.9 9 16 9H20C21.1 9 22 8.1 22 7ZM20 7H16V6H20V7ZM2 6V7C2 8.1 2.9 9 4 9H11C12.1 9 13 8.1 13 7V6C13 4.9 12.1 4 11 4H4C2.9 4 2 4.9 2 6ZM4 6H11V7H4V6ZM9 12V13C9 14.1 9.9 15 11 15H20C21.1 15 22 14.1 22 13V12C22 10.9 21.1 10 20 10H11C9.9 10 9 10.9 9 12ZM11 12H20V13H11V12ZM2 18V19C2 20.1 2.9 21 4 21H15C16.1 21 17 20.1 17 19V18C17 16.9 16.1 16 15 16H4C2.9 16 2 16.9 2 18ZM4 18H15V19H4V18ZM2 12V13C2 14.1 2.9 15 4 15H6C7.1 15 8 14.1 8 13V12C8 10.9 7.1 10 6 10H4C2.9 10 2 10.9 2 12ZM4 12H6V13H4V12Z"
    ></path>
  </symbol>
  <symbol id="icon-text" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 6H4C2.9 6 2 6.9 2 8V16C2 17.1 2.9 18 4 18H20C21.1 18 22 17.1 22 16V8C22 6.9 21.1 6 20 6ZM20 16H4V8H20V16ZM14 13H6V11H14V13Z"
    ></path>
  </symbol>
  <symbol
    id="icon-theme"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 21.4201C23.9387 22.1566 23.5894 22.8394 23.0278 23.3202C22.4662 23.8011 21.7376 24.0413 21 23.9888C20.2624 24.0413 19.5338 23.8011 18.9722 23.3202C18.4106 22.8394 18.0613 22.1566 18 21.4201C18 18.8513 21 16.2826 21 14.9932C21 16.2826 24 18.8513 24 21.4201ZM22 12.9942L11 1.99951L8.71 4.2884L10.12 5.70771L11 4.82814L18.17 11.9946L5.64 15.8028L2.83 12.9942L7.71 8.11653L9.29 9.70576C9.38296 9.79944 9.49356 9.8738 9.61542 9.92455C9.73728 9.97529 9.86799 10.0014 10 10.0014C10.132 10.0014 10.2627 9.97529 10.3846 9.92455C10.5064 9.8738 10.617 9.79944 10.71 9.70576C10.8037 9.61284 10.8781 9.5023 10.9289 9.3805C10.9797 9.2587 11.0058 9.12805 11.0058 8.99611C11.0058 8.86416 10.9797 8.73352 10.9289 8.61172C10.8781 8.48992 10.8037 8.37937 10.71 8.28645L3.71 1.28986C3.5217 1.10165 3.2663 0.995911 3 0.995911C2.7337 0.995911 2.4783 1.10165 2.29 1.28986C2.1017 1.47807 1.99591 1.73334 1.99591 1.99951C1.99591 2.26569 2.1017 2.52096 2.29 2.70917L6.29 6.70722L0 12.9942L10 22.9893L18 14.9932L22 12.9942Z"
    ></path>
  </symbol>
  <symbol
    id="icon-toolbox"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 4H16V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L10 0C9.46957 0 8.96086 0.210714 8.58579 0.585786C8.21071 0.960859 8 1.46957 8 2V4H3C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V12H2V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V12H23V6C23 5.46957 22.7893 4.96086 22.4142 4.58579C22.0391 4.21071 21.5304 4 21 4ZM10 2H14V4H10V2ZM20 20H4V12H9V16H15V12H20V20ZM11 14V10H13V14H11ZM21 10H15V8H9V10H3V6H21V10Z"
    ></path>
  </symbol>
  <symbol id="icon-undo" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 8.00001H4.40001L8.40001 4.00001L7.00001 2.60001L0.600006 9.00001L7.00001 15.4L8.40001 14L4.40001 10H17C19.2 10 21 11.8 21 14C21 16.2 19.2 18 17 18H16V20H17C20.3 20 23 17.3 23 14C23 10.7 20.3 8.00001 17 8.00001Z"
    ></path>
  </symbol>
  <symbol
    id="icon-visible"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4C7.2 4 3 7.2 0.5 12C3 16.8 7.2 20 12 20C16.8 20 20.9 16.8 23.5 12C20.9 7.2 16.7 4 12 4ZM12 18C8.5 18 5.1 15.8 2.8 12C5.1 8.2 8.5 6 12 6C15.5 6 18.9 8.2 21.2 12C18.9 15.8 15.5 18 12 18ZM12 7C9.2 7 7 9.2 7 12C7 14.8 9.2 17 12 17C14.8 17 17 14.8 17 12C17 9.2 14.7 7 12 7ZM12 15C10.3 15 9 13.7 9 12C9 11.7 9 11.5 9.1 11.3L12 12L10.3 9.5C10.8 9.2 11.3 9 12 9C13.7 9 15 10.3 15 12C15 13.7 13.6 15 12 15Z"
    ></path>
  </symbol>
  <symbol
    id="icon-wizard"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.7 16.3C8.1 16.7 8.1 17.3 7.7 17.7L3.7 21.7C3.5 21.9 3.3 22 3 22C2.7 22 2.5 21.9 2.3 21.7C1.9 21.3 1.9 20.7 2.3 20.3L6.3 16.3C6.7 15.9 7.3 15.9 7.7 16.3ZM20.5 10L23 13.7L18.6 14.6L17.7 19L14 16.5L10.3 19L9.4 14.6L5 13.7L7.5 10L5 6.3L9.4 5.4L10.3 1L14 3.5L17.7 1L18.6 5.4L23 6.3L20.5 10ZM18.1 10L18.8 8.9L19.6 7.7L18.1 7.4L16.8 7.1L16.5 5.8L16.2 4.3L15 5.1L14 5.9L12.9 5.2L11.7 4.3L11.4 5.8L11.1 7.1L9.8 7.4L8.3 7.7L9.1 8.9L9.9 10L9.2 11.1L8.4 12.3L9.9 12.6L11.2 12.9L11.5 14.2L11.8 15.7L13 14.9L14.1 14.2L15.2 14.9L16.4 15.7L16.7 14.2L17 12.9L18.3 12.6L19.8 12.3L19 11.1L18.1 10Z"
    ></path>
  </symbol>
  <symbol
    id="icon-barrating"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M12.1,15l-4.6-2.4L2.9,15l0.9-5.1L0,6.3l5.2-0.7L7.5,1l2.3,4.6L15,6.3l-3.8,3.6L12.1,15z"
      ></path>
    </g>
  </symbol>
  <symbol
    id="icon-datepicker"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect x="3" y="7" width="2" height="2"></rect>
      <rect x="7" y="11" width="2" height="2"></rect>
      <rect x="3" y="11" width="2" height="2"></rect>
      <rect x="11" y="11" width="2" height="2"></rect>
      <rect x="11" y="7" width="2" height="2"></rect>
      <path d="M10,6H6v4h4V6z M9,9H7V7h2V9z"></path>
      <rect x="4" width="1" height="3"></rect>
      <rect x="11" width="1" height="3"></rect>
      <path d="M13,2v2h-3V2H6v2H3V2H0v14h16V2H13z M15,15H1V5h14V15z"></path>
    </g>
  </symbol>
  <symbol
    id="icon-nouislider"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="10" width="16" height="2"></rect>
    <polygon points="2,4 2,7 4,9 6,7 6,4 "></polygon>
    <polygon points="10,4 10,7 12,9 14,7 14,4 "></polygon>
  </symbol>
  <symbol
    id="icon-tagbox"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g><path d="M15,11H0V5h15V11z M1,10h13V6H1V10z"></path></g>
    <rect x="2" y="7" width="4" height="2"></rect>
    <rect x="7" y="7" width="4" height="2"></rect>
  </symbol>
  <symbol
    id="icon-sortablejs"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1h9v2H7zM9 6h7v2H9zM11 11h5v2h-5zM5 10l1 1c-2.2 0-4-1.8-4-4 0-1.9 1.3-3.4 3-3.9v-2C2.2 1.5 0 4 0 7c0 3.3 2.7 6 5.9 6H6l-1 1 1 1 3-3-3-3-1 1z"
    ></path>
  </symbol>
  <symbol
    id="icon-editor"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <polygon
        points="1,3 3,3 3,8 2,8 2,9 3,9 5,9 6,9 6,8 5,8 5,3 7,3 7,4 8,4 8,1 7,1 0,1 0,2 0,3 0,4 1,4 "
      ></polygon>
      <rect x="10" y="5" width="6" height="1"></rect>
      <rect x="10" y="8" width="6" height="1"></rect>
      <rect y="11" width="16" height="1"></rect>
      <rect y="14" width="16" height="1"></rect>
    </g>
  </symbol>
</svg>
