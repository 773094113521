<div
  *ngFor="let historyItem of historyItems"
  class="d-flex gap-2 p-2 align-items-center"
>
  <span
    class="w-40 user-inital-avatar circle"
    data-container="body"
    placement="top"
    [ngClass]="historyItem.performedBy.color"
    >{{ historyItem.performedBy.name.charAt(0).toUpperCase() }}
  </span>
  {{ historyItem.performedBy.name || historyItem.performedBy.username }}
  {{ historyItem.description }}
  <small class="text-muted"> - {{ formatDate(historyItem.dateChanged) }}</small>
</div>
