<form
  [formGroup]="newActivityForm"
  (ngSubmit)="onCreateNewActivity()"
  class="animated fadeIn form-wrapper mt4"
>
  <div class="modal-header">
    <h4>Add New Activity</h4>
    <button
      type="button"
      class="btn btn-close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="model-body">
    <div class="card">
      <div class="card-body">
        <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
          {{ errorMsg }}
        </div>

        <!-- #region Actual Form -->

        <!-- #region Activity Definition -->
        <div style="margin-bottom: 20px">
          <div class="mt-5 mb-4">
            <p>Select the kind of activity you want to create.</p>
          </div>
          <div
            class="form-error-msg"
            *ngIf="submitted && !form['activityDefinitionRadioGroup'].valid"
          >
            Must select an activity type.
          </div>
          <div *ngIf="isActivityDefinitionsLoading">
            <fa-icon
              class="empty-data-icon"
              icon="spinner"
              [spin]="true"
            ></fa-icon>
            Loading Activity Types...
          </div>
          <div
            *ngIf="!isActivityDefinitionsLoading"
            class="form-activity-definition-select-container mt-3"
          >
            <label
              *ngFor="let actDef of activityDefinitions"
              class="activity-def-label mb-0"
            >
              <input
                formControlName="activityDefinitionRadioGroup"
                type="radio"
                [value]="actDef.id"
              />
              {{ actDef.name }}
            </label>
          </div>
        </div>
        <!-- endregion -->

        <!-- KEY START -->
        <div class="form-label-group">
          <input
            type="text"
            id="key"
            class="form-control"
            formControlName="key"
            [ngClass]="{'is-invalid': submitted && form['key'].errors }"
            placeholder="ID"
            maxlength="35"
          />
          <label for="key">ID</label>
          <div *ngIf="submitted && form['key'].errors" class="invalid-feedback">
          </div>
        </div>
        <!-- KEY END -->

        <!-- NAME START -->
        <div class="form-label-group">
          <input
            type="text"
            id="name"
            class="form-control"
            formControlName="name"
            [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"
            placeholder="Name"
            maxlength="35"
            required
            autofocus
          />
          <label for="name">Name</label>
          <div
            *ngIf="submitted && form['name'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="form['name'].errors['required']">Name is required</div>
          </div>
        </div>
        <!-- NAME END -->

        <!-- DESCRIPTION START -->
        <div class="form-label-group">
          <input
            type="text"
            id="description"
            class="form-control"
            formControlName="description"
            placeholder="Description"
          />
          <label for="description">Description</label>
          <small class="pl-2 form-text text-muted"> Optional</small>
        </div>
        <!-- DESCRIPTION END -->

        <!-- NOTES START -->
        <div class="form-label-group">
          <textarea
            class="form-control"
            name="note"
            id="note"
            formControlName="note"
            rows="6"
            placeholder="Add initial notes."
          ></textarea>
          <small class="pl-2 form-text text-muted"> Optional</small>
        </div>
        <!-- NOTES END -->

        <!-- endregion -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="activeModal.dismiss('cancel click')"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-primary">Save</button>
  </div>
</form>
