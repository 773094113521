import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Survey, SurveyRef } from "app/_models";

@Component({
  selector: "app-form-list-item",
  templateUrl: "./form-list-item.component.html",
  styleUrls: ["../list-item/list-item.component.scss"],
  host: { class: "list-group-item p-1" },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormListItemComponent {
  @Input() survey: SurveyRef;
}
