<app-header></app-header>
<div class="eps-container container-fluid">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item">
        <a [routerLink]="['/admin/dashboard']">Admin Dashboard</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Roles</li>
    </ol>
  </nav>

  <div class="roles-container overflow-auto">
    <div class="pe-0">
      <div class="row">
        <div class="col-4">
          <h1 class="roles-header">Roles</h1>
        </div>
        <div class="col-8"></div>
      </div>
      <div class="role-list-container">
        <ul class="position-relative" appActiveTab orientation="vertical">
          <div class="active-tab-border"></div>
          <li
            [class.active]="currSelectedRole === selection"
            [ngClass]="{ 'text-primary': role.control == 'new' }"
            (click)="setMode(selection, role)"
            *ngFor="let role of roles; let selection = index"
          >
            <span>{{ role.name }}</span>
            <fa-icon
              *ngIf="showDeleteIcon(role.name)"
              (click)="displayDeleteRolePrompt(role)"
              data-container="body"
              ngbTooltip="Delete Role"
              class="float-end delete-role-icon"
              icon="trash"
            ></fa-icon>
          </li>
        </ul>
      </div>
    </div>
    <div class="ps-0 overflow-auto mb-2">
      <div class="permissions-container px-3">
        <div *ngIf="!permissionsLoaded">
          <div class="no-data-loaded">
            <object
              type="image/svg+xml"
              data="../../../assets/roles_illustration.svg"
            >
              Your browser does not support SVG
            </object>
          </div>
        </div>
        <h3
          class="d-flex sticky-top bg-white py-3 fw-light"
          *ngIf="permissionsLoaded"
        >
          {{ roleTitle }}
        </h3>
        <div class="animated fadeIn overflow-auto" *ngIf="permissionsLoaded">
          <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
            {{ errorMsg }}
          </div>
          <form [formGroup]="addPermissionsForm" (ngSubmit)="submit()">
            <div *ngIf="!userCanEditRole" class="row add-new-role-container">
              <div class="col-6">
                <div class="form-label-group">
                  <input
                    type="text"
                    id="inputCaseName"
                    class="form-control capitalize-input"
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid': submitted && form['name'].errors
                    }"
                    placeholder="Role Name"
                    (change)="
                      addPermissionsForm.patchValue({
                        name: capitalizeInput($event)
                      })
                    "
                    maxlength="35"
                    required
                    autofocus
                  />
                  <label for="inputCaseName">Role Name</label>
                  <div
                    *ngIf="submitted && form['name'].errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form['name'].errors['required']">
                      Role Name is required
                    </div>
                    <div *ngIf="form['name'].errors['minlength']">
                      Role Name must contain 2 characters.
                    </div>
                    <div *ngIf="form['name'].errors['roleNameValidator']">
                      Admin is a reserved name, it is unavailable.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <label class="mt-3">
                  <input
                    type="checkbox"
                    (change)="onCheckboxChange($event)"
                    formControlName="default"
                  />
                  Set to default
                </label>
                <div class="float-end">
                  <button
                    (click)="cancel()"
                    class="mt-2 mr-2 btn btn-secondary"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="mt-2 btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>

            <div class="permissions-list-container">
              <div>
                <h3 class="roles-header pb-0">
                  {{ generatePermissionsTxt() }}
                </h3>
                <div
                  class="form-error-msg"
                  *ngIf="submitted && !form['permissionCheckBoxes'].valid"
                >
                  Select at least one permission.
                </div>
              </div>
              <div class="mt-4">
                <label
                  class="cols permission-checkbox"
                  formArrayName="permissionCheckBoxes"
                  *ngFor="
                    let permission of permissionCheckBoxes.controls;
                    let i = index
                  "
                >
                  <input type="checkbox" [formControlName]="i" />
                  {{ permissions[i].name }}
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
