<div class="form-group">
  <label for="{{ fieldName }}" class="fw-bold form-label">{{
    field.name
  }}</label>
  <textarea
    class="form-control"
    rows="2"
    placeholder="{{ field.name }}"
    title="{{ field.name }}"
    name="{{ fieldName }}"
    [(ngModel)]="field.value"
    (change)="onValueChange()"
    id="{{ fieldName }}"
  ></textarea>
  <small *ngIf="field.help_text" class="form-text text-muted"
    ><i class="fa fa-info-circle" aria-hidden="true"></i>
    {{ field.help_text }}</small
  >
</div>
