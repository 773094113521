import {Component, OnInit} from '@angular/core';
import {ILNADashboard} from "../../_models";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {GeneralService} from "../../_services";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-ilna-dashboard',
    templateUrl: './ilna-dashboard.component.html',
    styleUrls: ['./ilna-dashboard.component.scss']
})
export class IlnaDashboardComponent implements OnInit {
    isLoading = false;
    dashboard: ILNADashboard;
    dashboardURL: SafeResourceUrl;
    dashboardID: number;
    wasDashboardRetrieved = false;
    isDashboardSet = false;

    constructor(
        private generalService: GeneralService,
        private domSanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            this.isDashboardSet = true;
            this.dashboardID = params["id"];
            this.getDashboard(this.dashboardID);
            this.isDashboardSet = false;
        });
    }

    private getDashboard(dashboardID: number): void {
        this.generalService.getDashboard(dashboardID).subscribe((dashboard) => {
            if (dashboard.dashboardID) {
                this.dashboard = dashboard;
                this.dashboardURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
                    this.dashboard.dashboardURL
                );
                this.isDashboardSet = true;
            } else {
                this.isDashboardSet = false;
            }
            this.wasDashboardRetrieved = true;
        });
    }

}
