import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Utils } from "app/_helpers";

@Component({
  selector: "app-eps-avatar",
  template: `<span
    [class]="'user-inital-avatar ' + class"
    container="body"
    [ngClass]="color"
    >{{ firstInitial }}
  </span> `,
  styleUrls: ["./eps-avatar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EpsAvatarComponent implements OnInit {
  @Input("class") class? = "size-15";
  @Input() username: string;
  @Input() color: string;
  firstInitial: string;

  constructor(private utils: Utils) {}

  ngOnInit() {
    this.firstInitial = this.utils.generateFirstInitalFromUserName(
      this.username
    );
  }
}
