<app-header></app-header>
<div class="eps-container container-fluid">
    <app-animated-loader
            *ngIf="isLoading"
            class="animated fadeIn"
    ></app-animated-loader>
    <div></div>
    <div *ngIf="!isLoading" class="card animated fadeIn my-2">
        <div class="card-body">
            <app-animated-loader *ngIf="!wasDashboardRetrieved"></app-animated-loader>
            <div
                    *ngIf="wasDashboardRetrieved && isDashboardSet"
                    class="ratio ratio-16x9 h-100"
            >
                <iframe [src]="dashboardURL" allowfullscreen allowtransparency></iframe>
            </div>
            <div *ngIf="wasDashboardRetrieved && !isDashboardSet">
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-info" role="alert">
                            <h4 class="alert-heading">Default Dashboard Not Set</h4>
                            <p>
                                It appears the default system dashboard is not set. Please notify your
                                project manager or system administrator.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
