<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Create Campaign Success</h4>
  <button
    type="button"
    class="btn btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <p>
    Your campaign "{{ campaign.name }}" was successfully created. The URL for
    the campaign is below.
  </p>
  <div class="form-group">
    <textarea class="form-control" rows="7">{{ campaign.url }}</textarea>
  </div>
</div>

<div class="modal-footer">
  <button
    (click)="activeModal.dismiss('Cross click')"
    class="btn btn-lg btn-primary btn-sm"
    type="button"
  >
    Ok
  </button>
</div>
