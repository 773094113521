<app-header></app-header>
<div class="container-fluid eps-container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item">
        <a (click)="back()" [routerLink]=""
          ><fa-icon class="empty-data-icon mr-1" icon="angle-left"></fa-icon
          >Data Collection Forms</a
        >
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ currentViewTitle }}
      </li>
    </ol>
  </nav>
  <div class="card overflow-auto">
    <div class="card-body eps-container overflow-auto">
      <div class="form-label-group mb-0">
        <span *ngIf="surveyHasError" id="helpBlock" class="help-block error-msg"
          ><strong>Error: </strong>{{ errorMsg }}</span
        >
        <input
          type="text"
          class="form-control"
          autofocus
          type="text"
          name="surveyName"
          [(ngModel)]="surveyName"
          (ngModelChange)="isSurveyNameUpdated($event)"
          placeholder="Name for survey"
        />
        <label for="surveyName">Form Name</label>
      </div>
      <survey-creator
        class="overflow-auto"
        [model]="surveyCreator"
      ></survey-creator>
    </div>
  </div>
</div>
