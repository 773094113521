import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { tap } from "rxjs/operators";
//import 'rxjs/add/operator/switchMap';
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { ActivityService } from "app/_services/activity.service";
import * as _ from "lodash";
import { cloneDeep } from "lodash";
import { Observable, forkJoin } from "rxjs";
import {
  AddDocumentsModalComponent,
  DialogComponent,
} from "../../../_components";
import { Utils } from "../../../_helpers";
import {
  ActivityDefinitionPost,
  ActivityDefinitionResponse,
  CaseDefinitionPost,
  CaseDefinitionResponse,
  CustomField,
  CustomFieldPost,
  CustomFieldPut,
  DialogOptions,
  Document,
  Survey,
} from "../../../_models";
import { CaseService, SurveyService } from "../../../_services";
import { ActivityDefinitionFormMode } from "../../../activities/activity_definitions/activity-definition-form/activity-definition-form.component";

@Component({
  selector: "app-new-case-definition",
  templateUrl: "./new-case-definition.component.html",
  styleUrls: ["./new-case-definition.component.scss"],
})
export class NewCaseDefinitionComponent implements OnInit {
  newCaseDefinitonForm: FormGroup;
  surveyOptions: Survey[];
  closeResult: string;
  submitted = false;
  isSavingCaseDef = false;
  isSurveysLoading = false;
  documents: Document[] = [];
  surveys: Survey[] = [];
  errorMsg: string;
  caseDefinitionData: CaseDefinitionResponse;
  caseDefinitionId: number;
  customFields: CustomField[];
  title: string;
  isLoading = false;
  isEditMode = false;
  saveChanges = false;
  primaryDialogOptions: NgbModalOptions = {
    backdrop: "static",
  };
  activityDefinitionFormMode: ActivityDefinitionFormMode;
  activityDefinitions: Array<ActivityDefinitionResponse> = [];
  isEditingActivityDefinition = false;
  selectedActivityDefinition: ActivityDefinitionResponse;

  constructor(
    private formBuilder: FormBuilder,
    private surveyService: SurveyService,
    private caseService: CaseService,
    private modalService: NgbModal,
    private router: Router,
    private utils: Utils,
    private route: ActivatedRoute,
    private location: Location,
    private activtyService: ActivityService
  ) {
    this.newCaseDefinitonForm = this.formBuilder.group({
      name: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(6),
      ]),
      caseKey: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(2),
      ]),
      description: new FormControl<string>(null, []),
      surveyOptionsCheckBoxes: new FormArray([]),
    });

    this.route.params.subscribe((_params) => {
      this.caseDefinitionId = _params["id"] || null;
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.caseDefinitionId ? this.initEditMode() : this.initCreateNewMode();
    this.activityDefinitionFormMode = this.isEditMode
      ? ActivityDefinitionFormMode.edit
      : ActivityDefinitionFormMode.create;
  }

  get form() {
    return this.newCaseDefinitonForm.controls;
  }

  public back(): void {
    this.location.back();
  }

  private initEditMode() {
    this.title = "Edit Case Definition";
    this.isEditMode = true;
    this.getCaseDefinitionDetails();
  }

  private initCreateNewMode() {
    this.title = "New Case Definition";
    this.isEditMode = false;
    this.getSurveyOptions();
  }
  get surveyOptionsCheckBoxes() {
    return this.newCaseDefinitonForm.get(
      "surveyOptionsCheckBoxes"
    ) as FormArray;
  }

  private getCaseDefinitionDetails(): void {
    this.caseService
      .getCaseDefinition(this.caseDefinitionId)
      .subscribe((data) => {
        this.isLoading = false;
        this.caseDefinitionData = data;
        const {
          name,
          key,
          description,
          documents,
          custom_fields,
          activity_definitions,
          surveys,
        } = this.caseDefinitionData;
        this.newCaseDefinitonForm.patchValue({
          name: name,
          caseKey: key,
          description: description,
        });
        this.newCaseDefinitonForm.controls["caseKey"].disable();
        this.caseDefinitionData.surveys = <[]>surveys.map((obj) => obj.name);

        this.getSurveyOptions();
        this.documents = cloneDeep(documents);
        this.customFields = cloneDeep(custom_fields);
        this.activityDefinitions = activity_definitions
          ? cloneDeep(activity_definitions)
          : [];
      });
  }

  private getSurveyOptions(): void {
    this.isSurveysLoading = true;
    this.surveyService.getAllSurveys().subscribe((surveyOptions) => {
      this.surveyOptions = surveyOptions;
      this.addSurveyOptionCheckboxes();
      this.isSurveysLoading = false;
      this.isLoading = false;
    });
  }

  private addSurveyOptionCheckboxes(): void {
    this.surveyOptions.map((survey: any) => {
      const control = this.isEditMode
        ? new FormControl(this.caseDefinitionData.surveys.includes(survey.name))
        : new FormControl();
      (
        this.newCaseDefinitonForm.controls[
          "surveyOptionsCheckBoxes"
        ] as FormArray
      ).push(control);
    });
  }

  private addDocumentToList(_document: Document): void {
    this.documents.push(_document);
  }

  private getSelectedSurveysOptions(): number[] {
    const selectedSurveyOptions = [];
    const selectedSurveycb = this.form["surveyOptionsCheckBoxes"] as FormArray;
    selectedSurveycb.controls.forEach((checkbox, index) => {
      if (checkbox.value) {
        selectedSurveyOptions.push(this.surveyOptions[index].id);
      }
    });

    return selectedSurveyOptions;
  }

  public removeDocumentFromDocumentsList(_document: Document): void {
    this.documents = this.documents.filter(
      (doc: Document) => doc !== _document
    );
  }

  public generateDocumentRequiredTxt(_option: boolean): string {
    return _option === true ? " -(Required)" : "";
  }

  public generateSaveNewCaseDefinitionBtnTxt(): string {
    if (this.isEditMode) {
      return this.isSavingCaseDef
        ? "Saving Case Definition..."
        : "Save Changes to Case Definition";
    }
    return this.isSavingCaseDef
      ? "Saving New Case Definition..."
      : "Save New Case Definition";
  }

  public documentsAdded(): boolean {
    return _.isEmpty(this.documents);
  }

  public capitalizeInput(event: Event): string {
    const target = event.target as HTMLInputElement;
    return this.utils.generateCapitalizeString(target.value);
  }

  public upperCaseInput(event: Event): string {
    const target = event.target as HTMLInputElement;

    return this.utils.generateUppercaseString(target.value);
  }

  onCustomFieldListChanged(_customFields: CustomField[]): void {
    this.customFields = _customFields;
    console.log(this.customFields);
  }

  submitCustomFields() {
    const oldList = this.caseDefinitionData.custom_fields;
    const newList = this.customFields;
    const added = newList.filter((n) => !n.id) as CustomFieldPost[];
    const removed = oldList.filter((o) => !newList.find((n) => o.id === n.id));
    const updated = newList.filter(
      (n) => n.id && oldList.find((o) => o.id === n.id)
    ) as CustomFieldPut[];
    let requests: Array<
      Observable<CustomField> | Observable<{ message: string }>
    > = [
      ...added.map((cf) =>
        this.caseService.createCaseDefinitionCustomField(
          this.caseDefinitionId,
          cf
        )
      ),
      ...removed.map((cf) =>
        this.caseService.deleteCaseDefinitionCustomField(
          this.caseDefinitionId,
          cf.id
        )
      ),
      ...updated.map((cf) =>
        this.caseService.updateCaseDefinitionCustomField(
          this.caseDefinitionId,
          cf.id,
          cf
        )
      ),
    ];
    forkJoin(requests).subscribe((res) => {
      console.log(res);
    });
    console.log(added, removed, updated);
  }

  submitActivityDefinitions() {
    const oldList = this.caseDefinitionData.activity_definitions;
    const newList = this.stripNewItemIds(cloneDeep(this.activityDefinitions));
    const added = newList.filter((n) => !n.id);
    const removed = oldList.filter((o) => !newList.find((n) => o.id === n.id));
    const updated = newList.filter(
      (n) => n.id && oldList.find((o) => o.id === n.id)
    );
    let requests: Array<
      Observable<ActivityDefinitionResponse> | Observable<{ message: string }>
    > = [
      ...added.map((ad) => this.createActivityDefinition(ad)),
      ...removed.map((ad) => this.deleteActivityDefinition(ad)),
      ...updated.map((ad) => this.updateActivityDefinition(ad)),
    ];
    forkJoin(requests).subscribe({
      next: (res) => {
        console.log(res);
      },
      complete: () => {
        this.onSuccess();
      },
    });
    console.log(added, removed, updated);
  }

  submitActivityDefinitionCustomFields() {
    const oldList = this.activityDefinitions;
    const newList = this.stripNewItemIds(cloneDeep(this.activityDefinitions));
    const added = newList.filter((n) => !n.id);
    const removed = oldList.filter((o) => !newList.find((n) => o.id === n.id));
    const updated = newList.filter(
      (n) => n.id && oldList.find((o) => o.id === n.id)
    );
    let requests: Array<
      Observable<ActivityDefinitionResponse> | Observable<{ message: string }>
    > = [
      ...added.map((ad) => this.createActivityDefinition(ad)),
      ...removed.map((ad) => this.deleteActivityDefinition(ad)),
      ...updated.map((ad) => this.updateActivityDefinition(ad)),
    ];
    forkJoin(requests).subscribe({
      next: (res) => {
        console.log(res);
      },
      complete: () => {
        this.onSuccess();
      },
    });
    console.log(added, removed, updated);
  }

  public onSubmit(): void {
    this.isSavingCaseDef = true;
    this.submitted = true;

    const activity_definitions = this.stripNewItemIds(
      cloneDeep(this.activityDefinitions)
    ).map((ad: any) => {
      ad.case_definition_id = this.caseDefinitionData.id;
      ad.surveys = ad.surveys.map((s) => s.id);
      return ad as ActivityDefinitionPost;
    });

    const documents = this.stripNewItemIds(cloneDeep(this.documents));
    const custom_fields = this.stripNewItemIds(cloneDeep(this.customFields));
    const surveys = this.getSelectedSurveysOptions();
    const caseDefinition: CaseDefinitionPost = {
      name: this.newCaseDefinitonForm.value.name,
      key: this.newCaseDefinitonForm.value.caseKey,
      description: this.newCaseDefinitonForm.value.description,
      surveys,
      custom_fields,
      documents,
      activity_definitions,
    };

    if (this.newCaseDefinitonForm.invalid) {
      this.isSavingCaseDef = false;
    } else {
      if (this.isEditMode) {
        this.submitCustomFields();
        this.caseService
          .updateCaseDefinition(caseDefinition, this.caseDefinitionId)
          .pipe(
            tap((res) => {
              console.log(`case defn save result `, res);
            })
          )
          .subscribe({
            next: (caseDefnResponse) => {
              this.submitActivityDefinitions();

              this.utils.generateSuccessToastrMsg(
                "Case Definition successfully updated!",
                ""
              );
              this.isSavingCaseDef = false;
            },
            error: (error) => {
              this.errorMsg = error.message;
              this.isSavingCaseDef = false;
            },
          });
      } else {
        this.caseService.createNewCaseDefinition(caseDefinition).subscribe(
          () => {
            this.utils.generateSuccessToastrMsg(
              "New Case Definition successfully created!",
              ""
            );
            this.isSavingCaseDef = false;
            this.onSuccess();
          },
          (error) => {
            this.errorMsg = error.message;
            this.isSavingCaseDef = false;
          }
        );
      }
    }
  }

  private updateActivityDefinition(actDefn: ActivityDefinitionResponse) {
    console.log("UPDATING ACT DEFN");
    const request = this.activtyService.updateActivityDefinition(
      actDefn.id,
      actDefn
    );
    request.subscribe(
      () => {
        console.log(
          "[NewCaseDefinitionComponent.onCreateNewCaseDefinition()] activity definition successfully updated"
        );
        actDefn.custom_fields.forEach((customField) => {
          if (customField.id) {
            this.activtyService
              .updateCustomField(actDefn.id, customField.id, customField)
              .subscribe(
                (res) => {
                  console.log(
                    `[NewCaseDefinitionComponent.onCreateNewCaseDefinition()] custom field ${res.id} successfully updated`
                  );
                  //console.table(res);
                },
                (error) => {
                  this.errorMsg = error.message;
                  this.isSavingCaseDef = false;
                }
              );
          } else {
            this.activtyService
              .createCustomField(actDefn.id, customField)
              .subscribe(
                (res) => {
                  console.log(
                    `[NewCaseDefinitionComponent.onCreateNewCaseDefinition()] custom field ${res.id} successfully created`
                  );
                  //console.table(res);
                },
                (error) => {
                  this.errorMsg = error.message;
                  this.isSavingCaseDef = false;
                }
              );
          }
        });
      },
      (error) => {
        this.errorMsg = error.message;
        this.isSavingCaseDef = false;
      }
    );
    return request;
  }

  private deleteActivityDefinition(actDefn: ActivityDefinitionResponse) {
    const request = this.activtyService.deleteActivityDefinition(actDefn.id);
    request.subscribe(
      (res) => {
        this.activityDefinitions.splice(
          this.activityDefinitions.indexOf(actDefn),
          1
        ),
          this.utils.generateSuccessToastrMsg(res.message, "");
      },
      (error) => {
        this.errorMsg = error.message;
        this.isSavingCaseDef = false;
      }
    );
    return request;
  }

  private createActivityDefinition(actDefn: ActivityDefinitionResponse) {
    console.log("CREATE NEW ACT DEFN");
    const postData: ActivityDefinitionPost = {
      id: null,
      name: actDefn.name,
      description: actDefn.description,
      case_definition_id: this.caseDefinitionData.id,
      surveys: actDefn.surveys.map((s) => s.id),
      documents: actDefn.documents,
      custom_fields: actDefn.custom_fields,
    };
    const request = this.activtyService.createActivityDefinition(postData);
    request.subscribe(
      () => {
        console.log(
          "[NewCaseDefinitionComponent.onCreateNewCaseDefinition()] !!!!!activity definition successfully created"
        );
      },
      (error) => {
        console.log(`saving new activity definition error: ${error.message}`);
        this.errorMsg = error.message;
        this.isSavingCaseDef = false;
      }
    );
    return request;
  }

  private deleteCaseDefinition(_caseDefId: number): void {
    this.caseService.deleteCaseDefinition(_caseDefId).subscribe((data) => {
      if (data) {
        this.utils.generateSuccessToastrMsg(
          "Case Definition was Successfully Deleted",
          ""
        );
        this.router.navigate(["/cases-definitions"]);
      }
    });
  }

  private onSuccess(): void {
    this.onCancel();

    // wait a few sec before redirect user to a new view
    setTimeout(() => {
      this.router.navigate(["/cases-definitions"]);
    }, 2500);
  }

  protected onCancel(): void {
    if (this.isSavingCaseDef) {
      this.isSavingCaseDef = false;
    }
    this.documents = [];
    this.newCaseDefinitonForm.reset();
    this.submitted = false;
    this.errorMsg = null;
    this.back();
  }

  public openAddDocumentsModal(): void {
    const modalRef = this.modalService.open(
      AddDocumentsModalComponent,
      this.primaryDialogOptions
    );
    modalRef.result.then(
      (result) => {
        if (result) {
          _.isEmpty(result)
            ? console.log("No new obj")
            : this.addDocumentToList(result);
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  public openDeleteDialogPrompt(): void {
    const dialogOptions: DialogOptions = {
      headerText: "Delete Case Definition",
      bodyText: "Are you sure you want to delete this Case Definition?",
      primaryActionText: "Yes, Delete",
      cancelBtnText: "Cancel",
      btnClass: "danger",
      saveChanges: false,
    };

    const dialog = this.modalService.open(
      DialogComponent,
      this.primaryDialogOptions
    );
    dialog.componentInstance.dialogOptions = dialogOptions;
    dialog.componentInstance.passEntry.subscribe((choice: boolean) => {
      if (choice) {
        this.deleteCaseDefinition(this.caseDefinitionId);
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  public onNewActivityDefinition() {
    if (this.isEditingActivityDefinition) {
      this.isEditingActivityDefinition = false;
    }
    this.isEditingActivityDefinition = true;
    this.activityDefinitionFormMode = ActivityDefinitionFormMode.create;
    this.selectedActivityDefinition = {
      id: this.getNewItemId(this.activityDefinitions),
      name: "",
      description: "",
      custom_fields: [],
      documents: [],
      surveys: [],
    };
  }

  public onActivityDefinitionSelected(
    activityDefinition: ActivityDefinitionResponse
  ) {
    if (this.isEditingActivityDefinition) {
      this.isEditingActivityDefinition = false;
    }
    this.isEditingActivityDefinition = true;
    this.activityDefinitionFormMode = ActivityDefinitionFormMode.edit;
    this.selectedActivityDefinition = activityDefinition;
  }

  public onCancelActivityDefinitionChange(didCancel: boolean) {
    console.log(
      "[NewCaseDefinitionComponent] chancel activity definition change received"
    );
    this.isEditingActivityDefinition = false;
    this.selectedActivityDefinition = null;
  }
  public onRemoveActivityDefinition(
    activityDefinition: ActivityDefinitionResponse
  ) {
    console.log(
      "[NewCaseDefinitionComponent] activity definition remove received",
      activityDefinition
    );
    this.isEditingActivityDefinition = false;
    const idx = this.activityDefinitions.findIndex(
      (x) => x.id === activityDefinition.id
    );
    if (idx !== -1) {
      this.activityDefinitions.splice(idx, 1);
    }
  }

  public onActivityDefinitionChanged(
    activityDefinition: ActivityDefinitionResponse
  ) {
    console.log(
      "[NewCaseDefinitionComponent] activity definition changed received"
    );
    console.log(JSON.stringify(activityDefinition));
    this.isEditingActivityDefinition = false;
    const idx = this.activityDefinitions.findIndex(
      (x) => x.id === activityDefinition.id
    );
    console.log(`idx = ${idx}`);
    if (idx !== -1) {
      this.activityDefinitions[idx] = activityDefinition;
    } else {
      this.activityDefinitions.push(activityDefinition);
    }
  }

  private getNewItemId(list: { id: number | null }[]): number {
    const ids = list.map(({ id }) => id);
    if (ids.includes(-1)) {
      return Math.min(...ids) - 1;
    } else {
      return -1;
    }
  }

  private stripNewItemIds<T = { id: number }>(list: T[]): T[] {
    function hasId<T>(object: T): object is T & { id: number } {
      return (
        typeof object === "object" &&
        "id" in object &&
        typeof (object as any).id === "number"
      );
    }
    list.forEach((item) => {
      if (hasId(item) && item.id < 0) {
        item.id = null;
      }
    });
    return list;
  }
}
