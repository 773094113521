<app-header></app-header>
<div class="container-fluid animated fadeIn eps-container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb my-2">
      <li class="breadcrumb-item">
        <a (click)="back()" [routerLink]=""
          ><fa-icon class="empty-data-icon mr-1" icon="angle-left"></fa-icon
          >Case</a
        >
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        Create New Case
      </li>
    </ol>
  </nav>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div>
            <!-- <h4>New Case</h4> -->
            <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
              {{ errorMsg }}
            </div>
            <form
              [formGroup]="newCaseForm"
              (ngSubmit)="onCreateNewCase()"
              class="animated fadeIn form-wrapper mt4"
            >
              <!-- CASE DEFINITION START -->
              <div style="margin-bottom: 20px">
                <div class="mt-5 mb-4">
                  <h5>Select a Case Type</h5>
                </div>
                <p>
                  <fa-icon icon="info-circle"></fa-icon> Select a pre-defined
                  Case Type from the list below.
                </p>
                <div
                  class="form-error-msg"
                  *ngIf="submitted && !form['caseDefinitionRadioGroup'].valid"
                >
                  Must Select a Case Type.
                </div>
                <div *ngIf="isCaseDefinitionsLoading">
                  <fa-icon
                    class="empty-data-icon"
                    icon="spinner"
                    [spin]="true"
                  ></fa-icon>
                  Loading Case Types...
                </div>
                <div
                  *ngIf="!isCaseDefinitionsLoading"
                  class="form-case-definition-select-container mt-3"
                >
                  <label
                    *ngFor="let caseDef of caseDefinitions"
                    class="case-def-label mb-0"
                  >
                    <input
                      formControlName="caseDefinitionRadioGroup"
                      type="radio"
                      [value]="caseDef.id"
                      (change)="caseDefinitionSelected(caseDef.id, $event)"
                    />
                    {{ caseDef.name }}
                  </label>
                </div>
              </div>
              <!-- CASE DEFINITION END -->

              <!-- KEY START -->
              <div class="form-label-group">
                <input
                  type="text"
                  id="key"
                  class="form-control"
                  formControlName="key"
                  [ngClass]="{ 'is-invalid': submitted && form['key'].errors }"
                  placeholder="ID"
                  maxlength="35"
                  autofocus
                />
                <label for="key">ID</label>
                <div
                  *ngIf="submitted && form['key'].errors"
                  class="invalid-feedback"
                ></div>
                <small class="pl-2 form-text text-muted"> Optional - will be generated if not provided.</small>
              </div>
              <!-- KEY END -->

              <!-- CASE NAME START -->
              <div class="form-label-group">
                <input
                  type="text"
                  id="inputCaseName"
                  class="form-control capitalize-input"
                  formControlName="name"
                  [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"
                  placeholder="Case Name"
                  maxlength="35"
                  required
                />
                <label for="inputCaseName">Name</label>
                <div
                  *ngIf="submitted && form['name'].errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="form['name'].errors['required']">
                    Case Name is required
                  </div>
                </div>
              </div>
              <!-- CASE NAME START -->

              <!-- CASE DESCRIPTION START -->
              <div class="form-label-group">
                <input
                  type="text"
                  id="inputCaseDescription"
                  class="form-control"
                  formControlName="description"
                  placeholder="Case Description"
                />
                <label for="inputCaseDescription">Description</label>
                <small class="pl-2 form-text text-muted"> Optional</small>
              </div>
              <!-- CASE DESCRIPTION END -->

              <!-- ASSIGNED TO START -->
              <div class="form-group mb-3">
                <label for="assignedTo" class="form-label">Assign To</label>
                <select
                  class="form-control form-select"
                  id="assignedTo"
                  formControlName="assignedTo"
                >
                  <option value="" disabled>Choose a user</option>
                  <option *ngFor="let u of assignableUsers" [ngValue]="u">
                    {{ u.name || u.username }}
                  </option>
                </select>
                <small class="pl-2 form-text text-muted"> Optional</small>
              </div>
              <!-- ASSIGNED TO END -->

              <!-- #region CustomFields -->
              <div *ngIf="customFields.length > 0">
                <div *ngIf="areCustomFieldsLoading">
                  <fa-icon
                    class="empty-data-icon"
                    icon="spinner"
                    [spin]="true"
                  ></fa-icon>
                  Loading Case Type Properties...
                </div>
                <div *ngIf="!areCustomFieldsLoading">
                  <render-custom-fields
                    [customFieldsData]="customFields"
                    [containerId]="0"
                    [containerType]="customFieldContainerType"
                    (fieldsChanged)="onCustomFieldsChanged($event)"
                  ></render-custom-fields>
                </div>
              </div>
              <!-- endregion -->

              <!-- CASE NOTE START -->
              <div class="form-label-group">
                <!-- <label for="inputCaseDescription">Description</label> -->
                <textarea
                  class="form-control"
                  name="note"
                  id="note"
                  formControlName="note"
                  rows="6"
                  placeholder="Add initial case notes."
                ></textarea>
                <small class="pl-2 form-text text-muted"> Optional</small>
              </div>
              <!-- CASE NOTE END -->

              <!-- FORM BUTTONS START -->
              <div class="button-wrapper float-end mt-5">
                <button (click)="onCancel()" class="btn btn-link" type="button">
                  Cancel
                </button>
                <!--  [disabled]="isSavingNewCaseDef || this.newCaseForm.invalid" -->
                <button
                  class="btn btn-primary"
                  [ngClass]="{ disabled: isSavingNewCase }"
                >
                  <fa-icon
                    *ngIf="isSavingNewCase"
                    class="empty-data-icon"
                    icon="spinner"
                    [spin]="true"
                  ></fa-icon>
                  {{ generateSaveNewCaseBtnTxt() }}
                </button>
              </div>
              <!-- FORM BUTTONS END -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
