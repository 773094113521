<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
  <button
    type="button"
    class="btn btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<form [formGroup]="userChangePasswordForm" (ngSubmit)="submit()">
  <div class="modal-body">
    <div class="change-password-content">
      <p>In order to protect your account, make sure your password:</p>
      <ul>
        <li>is longer than <b>10 characters</b></li>
        <li>is less than <b>64 characters</b></li>
      </ul>

      <div class="form-label-group">
        <input
          [type]="fieldTextType ? 'text' : 'password'"
          class="form-control"
          formControlName="newPassword"
          placeholder="New Password"
          [ngClass]="{ 'is-invalid': submitted && form['newPassword'].errors }"
          required
          autofocus
        />

        <label for="newPassword">New Password</label>
        <div
          *ngIf="submitted && form['newPassword'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="form['newPassword'].errors['required']">
            New Password is required
          </div>
          <div *ngIf="form['newPassword'].errors['minlength']">
            Password must be more than 10 characters.
          </div>
          <div *ngIf="form['newPassword'].errors['maxlength']">
            Password must be less than 64 characters.
          </div>
        </div>
        <div (click)="toggleFieldTextType()" class="view-password-btn">
          <i
            class="fa"
            [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }"
          ></i>
        </div>
      </div>

      <div class="form-label-group">
        <input
          [type]="repeatFieldTextType ? 'text' : 'password'"
          class="form-control"
          formControlName="confirmPassword"
          placeholder="Confirm Password"
          [ngClass]="{ 'is-invalid': submitted && form['confirmPassword'].errors }"
          required
        />
        <label for="confirmPassword">Confirm Password</label>
        <div
          *ngIf="submitted && form['confirmPassword'].errors"
          class="invalid-feedback"
        >
          <div *ngIf="form['confirmPassword'].errors['required']">
            Confirm Password is required
          </div>
          <div *ngIf="form['confirmPassword'].errors['pwdMatchValidator']">
            Passwords must match
          </div>
        </div>
        <div (click)="toggleRepeatFieldTextType()" class="view-password-btn">
          <i
            class="fa"
            [ngClass]="{
                    'fa-eye-slash': !repeatFieldTextType,
                    'fa-eye': repeatFieldTextType
                  }"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="activeModal.dismiss('Cross click')"
      class="btn btn-link btn-sm"
      type="button"
    >
      Cancel
    </button>
    <button
      class="btn btn-lg btn-primary btn-sm"
      [ngClass]="{ disabled: isSavingPasswordData }"
    >
      <fa-icon
        *ngIf="isSavingPasswordData"
        class="empty-data-icon"
        icon="spinner"
        [spin]="true"
      ></fa-icon>
      {{ generateChangePasswordBtnTxt() }}
    </button>
  </div>
</form>
