<div class="animated fadeIn container-fluid eps-container mb-2">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb my-2" *ngIf="showBreadcrumb">
            <li
                    class="breadcrumb-item"
                    *ngIf="sourceType === 'Case' || sourceType === 'Activity'"
            >
                <a
                        [routerLink]="['/cases']"
                        [queryParams]="{
            case_defn_id:
              sourceType === 'Activity'
                ? activity.case.definition.id
                : case.case_definition.id
          }"
                >
                    {{
                        sourceType === "Activity"
                            ? activity.case.definition.name
                            : case.case_definition.name
                    }}
                </a>
            </li>
            <li
                    class="breadcrumb-item"
                    *ngIf="sourceType === 'Case' || sourceType === 'Activity'"
            >
                <a
                        [routerLink]="[
            '/cases',
            sourceType === 'Activity' ? activity.case.id : case.id
          ]"
                >
                    {{ sourceType === "Activity" ? activity.case.name : case.name }}
                </a>
            </li>

            <li class="breadcrumb-item" *ngIf="sourceType === 'Activity'">
                <a [routerLink]="['/activities', activity.id]">
                    {{ activity.name }}
                </a>
            </li>

            <li
                    class="breadcrumb-item"
                    aria-current="page"
                    *ngIf="sourceType === 'Standalone'"
            >
                <a [routerLink]="['/forms']"> Data Collection Forms </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{ title }}
            </li>
        </ol>
    </nav>

    <div class="card overflow-auto" *ngIf="isSurveyLoaded; else skeleton">
        <div class="card-body overflow-auto m-1">
            <survey-component
                    class="overflow-auto"
                    [json]="json"
                    (submitSurvey)="sendData($event)"
            ></survey-component>
        </div>
    </div>
</div>
<ng-template #skeleton>
    <app-animated-loader></app-animated-loader>
</ng-template>
