import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
} from "@angular/forms";
import {
  Field,
  ModalOptions,
  Selection,
  CustomField,
  CustomFieldPost,
} from "../../../_models";
import * as _ from "lodash";

@Component({
  selector: "app-add-custom-field-modal",
  templateUrl: "./add-custom-field-modal.component.html",
  styleUrls: ["./add-custom-field-modal.component.scss"],
})
export class AddCustomFieldModalComponent implements OnInit {
  @Input() public modalOptions: ModalOptions;

  submitted = false;
  editingField = false;
  customFieldForm: FormGroup;

  errorMsg: string;

  customFieldTypes: Field[] = [
    { value: "text", displayName: "Short Text" },
    { value: "textarea", displayName: "Long Text" },
    { value: "check_box", displayName: "Checkbox" },
    { value: "radio_button", displayName: "Radio Button" },
    { value: "select", displayName: "Selection Field" },
    { value: "number", displayName: "Numeric Field" },
    { value: "date", displayName: "Date Field" },
    { value: "rank_list", displayName: "Rank List" },
  ];

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.initNewCustomFieldForm(this.modalOptions.customField);
  }

  get form() {
    return this.customFieldForm.controls;
  }

  getDisplayName(fieldType: string): string {
    return this.customFieldTypes.find((field) => field.value === fieldType)
      .displayName;
  }

  listSorted(selections: FormGroup[]): void {
    let formArray = this.customFieldForm.get("selections") as FormArray;
    formArray.setValue(selections.map((selection) => selection.value));
    console.log(selections);
  }

  private initNewCustomFieldForm(customField: CustomField): void {
    this.customFieldForm = this.formBuilder.group({
      id: new FormControl(customField.id ? customField.id : null),
      name: new FormControl(customField.name, [Validators.required]),
      help_text: new FormControl(customField.help_text),
      field_type: new FormControl(customField.field_type, [
        Validators.required,
      ]),
      selections: new FormArray(
        customField.selections.map(
          (selection) =>
            new FormGroup({
              id: new FormControl(selection.id),
              value: new FormControl(selection.value, [Validators.required]),
            })
        )
      ),
      placeholder: new FormControl(customField.placeholder),
      sort_order: new FormControl(customField.sort_order),
    });
  }

  onSelectFieldType(fieldType: string): void {
    this.form["field_type"].setValue(fieldType);
  }

  isSelectType(fieldType: string): boolean {
    return ["check_box", "radio_button", "select", "rank_list"].includes(
      fieldType
    );
  }

  get selections(): Array<FormGroup> {
    return (this.customFieldForm.get("selections") as FormArray)
      .controls as FormGroup[];
  }

  public addOptionToCustomField(): void {
    const selectionsFormArray = this.customFieldForm.controls[
      "selections"
    ] as FormArray;

    const selectionsFormGroup = new FormGroup({
      value: new FormControl(null, [Validators.required]),
    });
    selectionsFormArray.push(selectionsFormGroup);
  }

  public removeOption(formGroup: FormGroup): void {
    const selectionsFormArray = <FormArray>(
      this.customFieldForm.controls["selections"]
    );
    const index = selectionsFormArray.controls.indexOf(formGroup);
    selectionsFormArray.removeAt(index);
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.customFieldForm.invalid) {
    } else {
      this.activeModal.close(this.customFieldForm.value);
    }
  }
}
